import { gql, useLazyQuery } from '@apollo/client';
import ExcelJS from 'exceljs';
import { useUser } from 'src/utils/hooks/useUser';
import dayjs from 'dayjs';
import { convertLexicalHTMLToText } from 'src/utils/notificationTemplates';
import { humanizeHoursTrackingTime } from 'src/components/v2/FilecaseDetail/HoursTrackingSection/utils';

const TimeTrackingToExportQuery = gql`
  query TimeTrackingToExportQuery(
    $studio: ID!
    $filecases: [ID!]
    $assigned: [ID!]
    $categories: [ID!]
    $ids: [ID!]
  ) {
    paginatedHours(
      studio: $studio
      filters: {
        ids: $ids
        filecases: $filecases
        assigned: $assigned
        categories: $categories
      }
    ) {
      data {
        id
        amount
        concept
        date
        createdAt
        assignedTo {
          id
          name
        }
        filecase {
          id
          title
        }
        category {
          studioId
          name
        }
        createdBy {
          id
          name
          picture
        }
      }
    }
  }
`;

const excelHeaders = [
  'Usuario',
  'Fecha',
  'Tiempo',
  'Concepto',
  'Categoría',
  'Expediente',
  'Fecha de creación',
];

const timeTrackingKeyPerCell = [
  ['assignedTo', 'name'],
  'date',
  'amount',
  'concept',
  ['category', 'name'],
  ['filecase', 'title'],
  'createdAt',
];

const getValueFromKey = (tracking, key) => {
  if (Array.isArray(key)) {
    return tracking[key[0]]?.[key[1]];
  }

  if (key === 'date' || key === 'createdAt') {
    return dayjs(tracking[key]).format('LLL');
  }

  if (key === 'amount') {
    return humanizeHoursTrackingTime(tracking[key]);
  }

  if (key === 'concept') {
    return tracking[key] ? convertLexicalHTMLToText(tracking[key]) : '';
  }

  return tracking[key];
};

export const useTimeTrackingToExport = () => {
  const [getTimeTrackingToExport, { loading }] = useLazyQuery(
    TimeTrackingToExportQuery,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { user } = useUser();

  return {
    loading,
    getTimeTrackingToExport: (variables) =>
      getTimeTrackingToExport({ variables }).then((res) => {
        const { data = [] } = res.data?.paginatedHours ?? {};

        const workbook = new ExcelJS.Workbook();
        workbook.creator = user.name;
        workbook.created = new Date();

        const worksheet = workbook.addWorksheet('Time Tracking');

        // Set column headers
        excelHeaders.forEach((header, index) => {
          worksheet.getRow(1).getCell(index + 1).value = header;
        });

        // Add data rows
        data.forEach((tracking, index) => {
          const trackingRow = worksheet.getRow(2 + index);

          timeTrackingKeyPerCell.forEach((key, cellIndex) => {
            const value = getValueFromKey(tracking, key);
            if (value !== undefined) {
              trackingRow.getCell(cellIndex + 1).value = value;
            }
          });
        });

        // Download the xlsx file
        worksheet.workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          const url = window.URL.createObjectURL(blob);
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.download = `Time_Tracking_${dayjs().format('YYYY-MM-DD')}.xlsx`;
          anchor.click();
          window.URL.revokeObjectURL(url);
        });
      }),
  };
};

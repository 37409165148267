import React from 'react';

import { useSnackbar } from 'src/components/v3/Snackbar';

import { useMutation } from '@apollo/client';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { PopoverBase } from '../PopoverBase';
import { TagPopoverBody } from './TagPopoverBody';
import {
  CreateTagMutation,
  UpdateTagMutation,
} from 'src/pages/Dashboard/Settings/Organizers/tabs/TagsTab';
import { GET_TAGS } from 'src/graphql/queries/tags';

export const TagPopover = ({
  onSubmit,
  open,
  anchorEl,
  tag = null,
  transformOrigin = {},
  anchorOrigin = {},
  onClose,
  ...props
}) => {
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();

  const [createTag] = useMutation(CreateTagMutation);
  const [updateTag] = useMutation(UpdateTagMutation);

  const handleSubmit = async (values) => {
    const mutation = tag ? updateTag : createTag;

    mutation({
      variables: {
        studioId,
        name: values.name,
        id: tag?.id,
      },
      update: (cache, { data }) => {
        const cachedData = cache.readQuery({
          query: GET_TAGS,
          variables: {
            studio: studioId,
          },
        });

        cache.writeQuery({
          query: GET_TAGS,
          data: {
            tags: [
              data.createTag || data.updateTag,
              ...(cachedData?.tags || []),
            ],
          },
          variables: {
            studio: studioId,
          },
        });
      },
    });

    openSnackbar(tag ? 'Etiqueta modificada' : 'Etiqueta creada', {
      severity: 'success',
      autoDismiss: true,
    });

    onSubmit(values);
  };

  return (
    <PopoverBase
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      open={open}
      transformOrigin={transformOrigin}
      onClose={onClose}
    >
      <TagPopoverBody
        tag={tag}
        sx={{ maxWidth: 400 }}
        onSubmit={handleSubmit}
        {...props}
      />
    </PopoverBase>
  );
};

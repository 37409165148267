import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Grid,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import { Formik } from 'formik';
import { ArrowBack } from '@mui/icons-material';
import * as Yup from 'yup';
import dayjs from 'dayjs';

import Form, { TextField } from 'src/components/ui-components/Form';
import { TagsAutocomplete } from 'src/autocompletes';

export const defaultInitialValues = {
  name: '',
  description: '',
  tags: [],
  start: dayjs(),
};

const WorkflowFormDialogValidationSchema = Yup.object({
  name: Yup.string()
    .min(4, 'Minimo 4 caracteres.')
    .max(144, 'Maximo 144 caracteres.')
    .required('Este campo es requerido.'),
});

export const WorkflowFormDialog = ({
  onClose: handleClose,
  onSubmit,
  initialValues = defaultInitialValues,
  title = 'Nuevo flujo de trabajo',
}) => (
  <>
    <DialogTitle>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <Stack alignItems="center" direction="row" gap={0.5}>
          <IconButton size="small" onClick={handleClose}>
            <ArrowBack />
          </IconButton>

          <Box>{title}</Box>
        </Stack>
      </Stack>
    </DialogTitle>

    <DialogContent sx={{ overflowY: 'hidden' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={WorkflowFormDialogValidationSchema}
        onSubmit={(values) => {
          onSubmit(values);
          handleClose();
        }}
      >
        <Form>
          <TextField
            label="Nombre del flujo de trabajo"
            name="name"
            placeholder="Proceso migratorio, documentación inicial, tareas judiciales"
          />

          <TextField
            multiline
            label="Descripción"
            name="description"
            placeholder="Ej. recolectar documentacion inicial."
            rows={3}
          />

          <TagsAutocomplete
            creatable
            multiple
            name="tags"
            placeholder="Ej. Comercial, Cliente VIP, etc..."
          />

          {/* <DateField label="Fecha de inicio" name="start" /> */}

          <Grid item width="100%">
            <Button type="submit" variant="contained">
              Guardar información
            </Button>
          </Grid>
        </Form>
      </Formik>
    </DialogContent>
  </>
);

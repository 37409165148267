import React, { useEffect, useRef, useState } from 'react';
import { Box, Card, CardContent, LinearProgress, Stack } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { isDeepEqual } from '@mui/x-data-grid/internals';
import { useDeepCompareEffect } from 'react-use';
// import useEffect from 'use-deep-compare-effect';

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const FormikListener = ({ callback }) => {
  const { values, initialValues } = useFormikContext();
  const prev = usePrevious(values);
  const [initialized, setInitialized] = useState(false);

  useDeepCompareEffect(() => {
    if (initialized || !isDeepEqual(values, initialValues)) {
      callback(values, prev);
      setInitialized(true);
    }
  }, [initialized, values, initialValues]);

  return null;
};

// TODO: Para no perder la costumbre dejo un comment aqui <3
// Decidi hacer un approach mas composable para que podamos mover a donde queramos los componentes
// Otro approach podria ser de `<slots />` como en el caso de el DataGrid pero como a nico le encanta mover
// Los componentes de lugar, decidi hacerlo asi.
const SectionHeader = ({ filters, children, loading, onChange, ...props }) => (
  <Formik initialValues={filters} {...props}>
    {(renderProps) => (
      <>
        {onChange && (
          <FormikListener callback={onChange} values={renderProps.values} />
        )}

        <Card sx={{ position: 'relative' }}>
          <CardContent>
            <Stack direction="column" gap={1}>
              {children(renderProps)}
            </Stack>
          </CardContent>

          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              minHeight: 4,
            }}
          >
            {loading && <LinearProgress variant="indeterminate" />}
          </Box>
        </Card>
      </>
    )}
  </Formik>
);

export default SectionHeader;
export * from './SectionHeaderHeading';
export * from './SectionHeaderActiveFilters';

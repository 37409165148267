import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent, DialogTitle, Divider } from '@mui/material';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import dayjs from 'dayjs';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';
import {
  GetExpenseDetails,
  UpdatePaymentMutation,
  CreatePaymentMutation,
} from 'src/pages/Dashboard/Expenses/ExpensesQueries';

const validationSchema = Yup.lazy((values) => {
  const maxAmount =
    values.expenseTotal - (values.paid - (values?.payment?.amount ?? 0));

  return Yup.object({
    date: YupDateValidations,
    amount: Yup.number()
      .positive('Debe ser un monto positivo')
      .max(maxAmount, `La cantidad no puede exceder: ${maxAmount}`)
      .required('Debes agregar un monto a tu gasto'),
    paymentMethod: Yup.object()
      .nullable()
      .required('Debes agregar un metodo de pago'),
  });
});

const PaymentForm = ({
  expense = {},
  payment,
  title,
  onClose,
  ...rest
}) => {
  const { openSnackbar } = useSnackbar();
  const [createPayment] = useMutation(CreatePaymentMutation, {
    refetchQueries: [GetExpenseDetails],
  });
  const [updatePayment] = useMutation(UpdatePaymentMutation, {
    refetchQueries: [GetExpenseDetails],
  });

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = payment ? updatePayment : createPayment;
    try {
      await changeMutation({
        variables: {
          amount: Number(values.amount),
          paymentId: payment ? payment.id : null,
          date: new Date(values.date).toISOString(),
          concept: values.concept,
          method: values.paymentMethod.value,
          expense: expense.id,
          currency: values.currency.value,
        },
      });

      openSnackbar('Pago creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  return (
    <Formik
      {...rest}
      enableReinitialize
      initialValues={{
        ...expense,
        date: dayjs(payment?.date),
        expenseTotal: expense.amount,
        expensePaid: expense.paid,
        currency: expense.currency
          ? {
              value: expense.currency.name,
              label: expense.currency.name,
            }
          : null,
        amount: payment?.amount ?? 0,
        concept: payment?.concept ?? '',
        paymentMethod:
          DEFAULT_PAYMENT_METHODS.find(
            (method) => method.value === payment?.method,
          ) || DEFAULT_PAYMENT_METHODS[2],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <DialogTitle>{title}</DialogTitle>
          <Divider />
          <DialogContent>
            <Form id="paymentForm">
              <DateField label="Fecha" name="date" />

              <MoneyField
                disabledCurrency
                amountName="amount"
                currencyName="currency"
                label="Monto"
                type="number"
              />

              <TextField multiline label="Concepto" minRows={3} name="concept" />

              <SelectField
                label="Medio de pago"
                name="paymentMethod"
                options={DEFAULT_PAYMENT_METHODS}
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button fullWidth disabled={!!isSubmitting} onClick={onClose}>
              Cancelar
            </Button>

            <SubmitButton fullWidth form="paymentForm">
              {payment ? 'Editar' : 'Guardar'}
            </SubmitButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default PaymentForm;

import React from 'react';

import {
  Dialog,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { customFieldTypes } from '@legalsurf/common';
import Form, {
  CheckField,
  SelectField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { CustomFieldsQuery, useCustomFields } from './utils';

const CreateCustomFieldMutation = gql`
  mutation CreateCustomField(
    $name: String!
    $type: CustomFieldType!
    $studioId: ID!
    $onEntities: Boolean
    $onFilecases: Boolean
    $options: [ID!]
  ) {
    createCustomField(
      name: $name
      studioId: $studioId
      type: $type
      options: $options
      onEntities: $onEntities
      onFilecases: $onFilecases
    ) {
      id
      name
      studioId
      description
      type
      onEntities
      onFilecases
      options
    }
  }
`;

export const UpdateCustomFieldMutation = gql`
  mutation UpdateCustomField(
    $id: ID!
    $name: String
    $type: CustomFieldType
    $studioId: ID!
    $onEntities: Boolean
    $onFilecases: Boolean
    $options: [ID!]
  ) {
    updateCustomField(
      id: $id
      studioId: $studioId
      name: $name
      type: $type
      onEntities: $onEntities
      onFilecases: $onFilecases
      options: $options
    ) {
      id
      name
      studioId
      description
      onEntities
      onFilecases
      type
      options
    }
  }
`;

const useCustomFieldValidationSchema = () => {
  const { customFields } = useCustomFields({
    fetchPolicy: 'cache-first',
  });

  return Yup.object({
    name: Yup.string()
      .required('El nombre es requerido')
      .test(
        'unique-custom-field-name',
        'Este nombre ya esta siendo utilizado',
        (value) => !customFields.some(({ name }) => name === value),
      ),
    type: Yup.object().required('El tipo es requerido').nullable(),
  });
};

export const CustomFieldFormDialog = ({
  customField,
  onClose,
  title = 'Campo personalizado',
  ...rest
}) => {
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const validationSchema = useCustomFieldValidationSchema();
  const [createCustomField] = useMutation(CreateCustomFieldMutation);
  const [updateCustomField] = useMutation(UpdateCustomFieldMutation);

  const defaultInitialValues = {
    name: '',
    description: '',
    options: [],
    type: [],
    onFilecases: rest.onFilecases ?? false,
    onEntities: rest.onEntities ?? false,
  };

  const handleSubmit = async (values, formikBag) => {
    const customFieldMutation = customField
      ? updateCustomField
      : createCustomField;

    const variables = {
      name: values.name,
      description: values.description,
      type: values.type.value,
      onEntities: values.onEntities,
      onFilecases: values.onFilecases,
      options: values.options?.map((option) => option.value),
      studioId,
    };

    if (customField) {
      variables.id = customField.id;
    }

    try {
      await customFieldMutation({
        variables,
        refetchQueries: () => {
          const promises = [];

          if (!customField) {
            promises.push({
              query: CustomFieldsQuery,
              variables: {
                studioId,
              },
            });

            if (values.onEntities) {
              promises.push({
                query: CustomFieldsQuery,
                variables: {
                  studioId,
                  onEntities: true,
                },
              });
            }

            if (values.onFilecases) {
              promises.push({
                query: CustomFieldsQuery,
                variables: {
                  studioId,
                  onFilecases: true,
                },
              });
            }
          }

          return promises;
        },
      });

      openSnackbar('Pago creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  const initialValues = {
    ...defaultInitialValues,
    ...customField,
    options: customField?.options?.length
      ? customField?.options?.map((option) => ({
          value: option,
          label: option,
        }))
      : [],
    type: customField?.type
      ? {
          value: customField.type,
          label: customFieldTypes.find(
            (type) => type.value === customField.type,
          )?.label,
        }
      : defaultInitialValues.type,
  };

  return (
    <Stack>
      <DialogTitle>{title}</DialogTitle>

      <Divider />

      <Formik
        {...rest}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => (
          <>
            <DialogContent>
              <Form id="customFieldForm">
                <TextField label="Nombre" name="name" />

                <SelectField
                  helperText={
                    values.type?.value === 'Checkbox'
                      ? 'Por defecto, el valor será "No"'
                      : ''
                  }
                  label="Tipo"
                  name="type"
                  options={customFieldTypes}
                />

                {values.type?.value === 'Select' && (
                  <SelectField
                    creatable
                    multiple
                    helperText="Agregar opciones"
                    label="Opciones"
                    name="options"
                    options={[]}
                  />
                )}

                <CheckField
                  gridProps={{ xs: 6 }}
                  label="Habilitar en expedientes"
                  name="onFilecases"
                />

                <CheckField
                  gridProps={{ xs: 6 }}
                  label="Habilitar en directorio"
                  name="onEntities"
                />
              </Form>
            </DialogContent>

            <DialogActions>
              <Button
                disabled={!!isSubmitting}
                sx={{ mr: 'auto' }}
                onClick={onClose}
              >
                Cancelar
              </Button>

              <SubmitButton fullWidth form="customFieldForm">
                Crear campo personalizado
              </SubmitButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Stack>
  );
};

import React from 'react';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import { FormikListener } from 'src/components/v3/SectionHeader';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import {
  FilecasesAutocomplete,
  StudioMembersAutocomplete,
  CategoryHoursAutocomplete,
} from 'src/autocompletes';
import { PeriodField } from 'src/components/ui-components/Form';
import dayjs from 'dayjs';

const today = dayjs();

const initialValues = {
  period: {
    option: '3',
    start: today.subtract(3, 'month').toDate(),
    end: today.toDate(),
  },
  filecases: [],
  responsibles: [],
  categories: [],
};

export const TimeTrackingReportsFilters = ({ onSubmit }) => {
  const handleFormChange = (current, prev) => {
    if (current.period.option === 'custom' && prev.period.option !== 'custom') {
      return;
    }

    if (
      current.period.option === 'custom' &&
      (!current.period.start || !current.period.end)
    ) {
      return;
    }

    onSubmit({
      period: current.period,
      filecases: current.filecases,
      responsibles: current.responsibles,
      categories: current.categories,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <Box display="flex" gap={2} alignItems="center">
          <FormikListener callback={handleFormChange} />

          <PeriodField name="period" />

          {values.period.option === 'custom' && (
            <Button variant="contained" size="small" onClick={handleSubmit}>
              Aplicar rango
            </Button>
          )}

          <SectionHeaderAdvanceFilters
            onReset={(formikBag) => {
              formikBag.resetForm();
              onSubmit(initialValues);
            }}
            buttonProps={{ variant: 'contained', size: 'small' }}
          >
            <FilecasesAutocomplete
              multiple
              label="Expedientes"
              name="filecases"
              size="small"
            />

            <StudioMembersAutocomplete
              multiple
              label="Responsables"
              name="responsibles"
              size="small"
            />

            <CategoryHoursAutocomplete
              multiple
              label="Categorías"
              name="categories"
              size="small"
            />
          </SectionHeaderAdvanceFilters>
        </Box>
      )}
    </Formik>
  );
};

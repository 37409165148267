import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
  Avatar,
  Chip,
  Divider,
  Grid,
  useTheme,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useQuery } from '@apollo/client';

import Page from 'src/components/v2/Page';

import { useReactToPrint } from 'react-to-print';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { useNavigate, useParams } from 'react-router-dom';
import FilecaseDetailsSkeleton from '../FilecaseDetailsSkeleton';
import { FilecaseCustomFields } from '../SingleFilecaseHeader';
import {
  filecaseRawSections,
  getFilecaseSections,
  useFilecaseSections,
} from '..';
import { useDraggableSections } from '@legalsurf/hooks';
import PermissionGuard from '../../PermissionGuard';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import dayjs from 'dayjs';
import { Linkify } from 'src/components/Linkify';
import { Badge } from 'src/components/Badge';
import { FilecaseStateButton } from 'src/components/v3/StatusPopover/FilecaseStateButton';
import { LSFilecase } from 'src/components/icons';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';

export const FilecasePDF = () => {
  const { filecaseId } = useParams();
  const theme = useTheme();
  const { data: { filecase } = {}, loading } = useQuery(
    GET_FILECASE_SINGLE_DATA,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        filecase: filecaseId,
      },
      skip: !filecaseId,
    },
  );

  const [hiddenSections, setHiddenSections] = useState(new Set());

  const handleSectionVisibilityChange = (sectionId) => {
    setHiddenSections((prev) => {
      const newHidden = new Set(prev);
      if (newHidden.has(sectionId)) {
        newHidden.delete(sectionId);
      } else {
        newHidden.add(sectionId);
      }
      return newHidden;
    });
  };

  const sectionData = useFilecaseSections(filecase);
  const [sections] = useDraggableSections(
    filecaseRawSections,
    'v1-filecase-detail',
  );

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (loading) {
    return <FilecaseDetailsSkeleton />;
  }

  return (
    <Page sx={{ backgroundColor: '#f4faff', minHeight: '100vh' }}>
      <Stack maxWidth={1000} gap={1} justifyContent="center" margin="auto">
        <Box>
          <IconButton sx={{ mb: 0.5 }} onClick={handleGoBack}>
            <ArrowBack />
          </IconButton>
        </Box>

        <Card sx={{ overflow: 'inherit' }}>
          <CardContent
            ref={componentRef}
            sx={{
              backgroundColor: '#fff',
            }}
          >
            <Stack
              sx={{
                '@media print': {
                  '#commentForm': {
                    display: 'none',
                  },
                  '.FormCard': {
                    display: 'none',
                  },
                  '.CreationInfoBannerCard': {
                    display: 'none',
                  },
                  '.MuiIconButton-root': {
                    display: 'none',
                  },
                  '.hidden-section': {
                    display: 'none',
                  },
                },
              }}
            >
              <Stack gap={2}>
                <Grid container item alignItems="center">
                  <Grid item xs={5}>
                    <Stack alignItems="center" direction="row" gap={1}>
                      <LSFilecase color="primary" />
                      <Typography variant="h5">EXPEDIENTE</Typography>
                    </Stack>
                  </Grid>
                </Grid>

                <Divider />

                <Stack gap={2}>
                  <Stack direction="row" gap={2}>
                    {filecase?.externalId && (
                      <Stack alignItems="flex-end" direction="row">
                        <Typography flex={4} fontWeight={600} variant="h5">
                          Nro. {filecase.externalId}
                        </Typography>
                      </Stack>
                    )}
                  </Stack>

                  <Stack gap={1} direction="row" justifyContent="space-between">
                    <Stack direction="column" gap={1}>
                      <Typography
                        sx={{ fontWeight: 600, fontSize: 26 }}
                        variant="h3"
                      >
                        {filecase.title}
                      </Typography>

                      <Stack gap={2}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <FilecaseStateButton filecase={filecase} />
                          {filecase.state && (
                            <Badge
                              label={filecase.state.name}
                              backgroundColor={filecase.state.color}
                            />
                          )}

                          {filecase.category && (
                            <Badge label={filecase.category.name} />
                          )}

                          {filecase.side && (
                            <Badge label={filecase.side.name} />
                          )}
                        </Box>

                        {filecase.description && (
                          <Linkify text={filecase.description} />
                        )}

                        <FilecaseCustomFields filecase={filecase} />

                        {!!(
                          Array.isArray(filecase.tags) && filecase.tags.length
                        ) && (
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            {filecase.tags.map((tag) => (
                              <Chip
                                key={tag.name}
                                label={tag.name}
                                size="small"
                                sx={{
                                  color: theme.palette.primary.dark,
                                  backgroundColor: theme.palette.primary[100],
                                  fontWeight: '500',
                                }}
                              />
                            ))}
                          </Box>
                        )}

                        <Stack gap={0.5}>
                          <Stack direction="row" alignItems="center" gap={1}>
                            <Typography
                              sx={{ opacity: 0.8 }}
                              fontWeight="500"
                              fontSize={12}
                            >
                              Fecha de creación:
                            </Typography>

                            <Typography fontWeight="500" fontSize={12}>
                              {dayjs(filecase.createdAt).format('LLL[hs]')}
                            </Typography>
                          </Stack>

                          <Stack direction="row" alignItems="center" gap={1}>
                            <Stack direction="row" alignItems="center" gap={1}>
                              <Typography
                                sx={{ opacity: 0.8 }}
                                fontWeight="500"
                                fontSize={12}
                              >
                                Creado por:
                              </Typography>

                              <Avatar
                                alt={filecase.createdBy.name}
                                src={filecase.createdBy.picture}
                                sx={{ width: 25, height: 25, fontSize: 12 }}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              alignItems="center"
                              gap={filecase.assigned?.length ? 0 : 0.5}
                              justifyContent="flex-start"
                            >
                              <Typography
                                sx={{ opacity: 0.8 }}
                                fontWeight="500"
                                whiteSpace="nowrap"
                                fontSize={12}
                              >
                                Asignado a:
                              </Typography>

                              <AssigneesPopover
                                selectedAssignees={filecase.assigned}
                                touchable={false}
                                sx={{
                                  justifyContent: 'flex-start',
                                  '.MuiAvatar-root': {
                                    width: 25,
                                    height: 25,
                                  },
                                }}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>

              <Stack p={2} gap={2}>
                {sections.map((sectionId) => {
                  const Section = getFilecaseSections(sectionId);
                  const { title, icon, permissions } = sectionData[sectionId];
                  const isHidden = hiddenSections.has(sectionId);

                  const content = (
                    <Stack
                      className={isHidden ? 'hidden-section' : ''}
                      direction="column"
                      sx={{ opacity: isHidden ? 0.4 : 1 }}
                    >
                      <Stack alignItems="center" direction="row" gap={1}>
                        <Tooltip title="Mostrar/Ocultar sección">
                          <IconButton
                            sx={{ position: 'relative', left: -10 }}
                            onClick={() =>
                              handleSectionVisibilityChange(sectionId)
                            }
                          >
                            {isHidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Tooltip>

                        {icon}
                        <Typography sx={{ fontWeight: '500' }} variant="h6">
                          {title}
                        </Typography>
                      </Stack>

                      <Box px={4} py={2}>
                        <Section filecase={filecase} type={sectionId} />
                      </Box>
                    </Stack>
                  );

                  if (!permissions) {
                    return content;
                  }

                  return (
                    <PermissionGuard key={sectionId} permissions={permissions}>
                      {content}
                    </PermissionGuard>
                  );
                })}
              </Stack>
            </Stack>
          </CardContent>
        </Card>

        <Box sx={{ textAlign: 'right' }}>
          <Button variant="filled" onClick={() => handlePrint()}>
            ⬇ Descargar Expediente
          </Button>
        </Box>
      </Stack>
    </Page>
  );
};

import React from 'react';
import { Box, Divider, Typography, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Form, { TextField } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { Save } from '@mui/icons-material';
import { useTags } from 'src/autocompletes/TagsAutocomplete';
import * as Yup from 'yup';

export const TagPopoverBody = ({ onSubmit, tag = null, ...rest }) => {
  const { tags } = useTags();

  const TagSchema = Yup.object().shape({
    name: Yup.string()
      .required('El nombre de la etiqueta es obligatorio')
      .test('unique-tag', 'Esta etiqueta ya existe', function (value) {
        // Skip validation if we're editing an existing tag and the name hasn't changed
        if (tag && tag.name === value) {
          return true;
        }

        // Check if the tag name already exists in the tags array
        return !tags.some(
          (existingTag) =>
            existingTag?.name?.toLowerCase?.() === value?.toLowerCase?.(),
        );
      }),
  });

  return (
    <Box {...rest}>
      <Box sx={{ p: 2 }}>
        <Typography variant="h6">Etiquetas</Typography>
      </Box>

      <Divider />

      <Formik
        initialValues={{
          name: tag ? tag.name : '',
        }}
        onSubmit={onSubmit}
        validationSchema={TagSchema}
      >
        {({ isSubmitting }) => (
          <Form sx={{ p: 2 }}>
            <TextField label="Etiqueta" name="name" placeholder="Nombre..." />

            <Grid item xs={12}>
              <Box sx={{ textAlign: 'right' }}>
                <LoadingButton
                  color="primary"
                  endIcon={<Save />}
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {tag ? 'Guardar' : 'Crear'}
                </LoadingButton>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

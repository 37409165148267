import React from 'react';
import { Formik } from 'formik';
import {
  DialogActions,
  Button,
  DialogContent,
  Box,
  Grid,
  Divider,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';

import Form, {
  CommentField,
  DateField,
  SubmitButton,
  TextField,
} from 'src/components/ui-components/Form';
import {
  CategoryHoursAutocomplete,
  FilecasesAutocomplete,
  StudioMembersAutocomplete,
} from 'src/autocompletes';
import dayjs from 'dayjs';
import { useUser } from 'src/utils/hooks/useUser';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { gql, useMutation } from '@apollo/client';
import { LSClock } from 'src/components/icons';
import { TimeTrackingQuery } from 'src/tables/TimeTrackingTable/queries';
import { hoursTrackingByCategoryQuery } from 'src/components/v2/FilecaseDetail/HoursTrackingSection';

const HoursTrackingFormSchema = Yup.object({
  hours: Yup.number()
    .min(1, 'Debes agregar al menos una hora')
    .notRequired()
    .test(
      'either-hours-or-minutes',
      'Debes agregar horas o minutos',
      (value, context) => {
        const { minutes } = context.parent;

        return !!(value || minutes);
      },
    ),
  minutes: Yup.number().min(1, 'Debes agregar minimo un minuto').notRequired(),
  assigned: Yup.object().nullable().required('El usuario es requerido'),
  filecase: Yup.object().nullable().required('El expediente es requerido'),
  date: Yup.date().required('La fecha es requerida'),
  category: Yup.object().nullable().required('La categoría es requerida'),
});

export const createHoursTrackingMutation = gql`
  mutation createHoursTrackingMutation(
    $filecaseId: ID!
    $assignedTo: ID!
    $studioId: ID!
    $concept: String
    $hours: Int
    $minutes: Int
    $category: ID
    $date: DateTime
  ) {
    createHoursTracking(
      filecaseId: $filecaseId
      assignedTo: $assignedTo
      studioId: $studioId
      concept: $concept
      hours: $hours
      minutes: $minutes
      category: $category
      date: $date
    ) {
      id
      amount
      concept
      date
      hours
      minutes
      createdAt
      updatedAt
      assignedTo {
        id
        name
        picture
      }
      filecase {
        id
        title
      }
      category {
        name
        studioId
      }
    }
  }
`;

export const updateHoursTrackingMutation = gql`
  mutation updateHoursTrackingMutation(
    $id: ID!
    $assignedTo: ID
    $concept: String
    $hours: Int
    $minutes: Int
    $category: ID
    $date: DateTime
  ) {
    updateHoursTracking(
      id: $id
      assignedTo: $assignedTo
      concept: $concept
      hours: $hours
      minutes: $minutes
      category: $category
      date: $date
    ) {
      id
      amount
      concept
      date
      hours
      minutes
      createdAt
      updatedAt
      assignedTo {
        id
        name
        picture
      }
      filecase {
        id
        title
      }
      category {
        name
        studioId
      }
      createdBy {
        id
        name
        picture
      }
    }
  }
`;

const HoursTrackingForm = ({ onClose, hourTracking, disabledFields = {} }) => {
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();

  const [createHourTracking] = useMutation(createHoursTrackingMutation);
  const [updateHourTracking] = useMutation(updateHoursTrackingMutation);

  const isEditMode = !!hourTracking?.id;
  const dialogTitle = isEditMode ? 'Editar horas' : 'Cargar horas';
  const buttonLabel = isEditMode ? 'Actualizar' : 'Cargar';

  const handleSubmit = async (values, formikBag) => {
    try {
      if (isEditMode) {
        // Update existing hour tracking
        await updateHourTracking({
          variables: {
            id: hourTracking.id,
            hours: values.hours || 0,
            minutes: values.minutes || 0,
            concept: values.concept,
            assignedTo: values.assigned.value,
            category: values.category?.value,
            date: values.date,
          },
          refetchQueries: [
            TimeTrackingQuery,
            {
              query: hoursTrackingByCategoryQuery,
              variables: {
                filters: {
                  filecases: [values.filecase.value],
                },
              },
            },
          ],
        });

        openSnackbar('Horas actualizadas con éxito.', {
          severity: 'success',
        });
      } else {
        // Create new hour tracking
        await createHourTracking({
          variables: {
            studioId,
            hours: values.hours || 0,
            minutes: values.minutes || 0,
            concept: values.concept,
            assignedTo: values.assigned.value,
            filecaseId: values.filecase.value,
            category: values.category?.value,
            date: values.date,
          },
          refetchQueries: [
            TimeTrackingQuery,
            {
              query: hoursTrackingByCategoryQuery,
              variables: {
                filters: {
                  filecases: [values.filecase.value],
                },
              },
            },
          ],
          update: (cache, { data }) => {
            if (values.filecase?.value) {
              cache.modify({
                id: cache.identify(data.createHoursTracking.filecase),
                fields: {
                  totalHours(hours) {
                    return hours + data.createHoursTracking.amount;
                  },
                  chargedHours(existingData, { toReference }) {
                    return [
                      ...(existingData || []),
                      toReference(data.createHoursTracking, true),
                    ];
                  },
                },
              });
            }
          },
        });

        openSnackbar('Horas registradas con éxito.', {
          severity: 'success',
        });
      }

      onClose();
      formikBag.resetForm();
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar(
        `Hubo un error al intentar ${isEditMode ? 'actualizar' : 'cargar'} las horas`,
        {
          severity: 'error',
        },
      );
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const { user } = useUser();

  const defaultValues = {
    hours: '',
    minutes: '',
    date: dayjs(),
    concept: '',
    filecase: null,
    assigned: {
      value: user.id,
      label: user.name,
      picture: user.picture,
    },
    category: null,
  };

  // Format initialValues for edit mode
  const formattedInitialValues = React.useMemo(() => {
    if (!hourTracking) return defaultValues;

    // If we're in edit mode and have an ID, format the values properly
    if (isEditMode && hourTracking.id) {
      return {
        id: hourTracking.id,
        hours: hourTracking.hours || '',
        minutes: hourTracking.minutes || '',
        date: hourTracking.date ? dayjs(hourTracking.date) : dayjs(),
        concept: hourTracking.concept || '',
        filecase: hourTracking.filecase
          ? {
              value: hourTracking.filecase.id,
              label: hourTracking.filecase.title,
            }
          : null,
        assigned: hourTracking.assignedTo
          ? {
              value: hourTracking.assignedTo.id,
              label: hourTracking.assignedTo.name,
              picture: hourTracking.assignedTo.picture,
            }
          : null,
        category: hourTracking.category
          ? {
              value: hourTracking.category.name,
              label: hourTracking.category.name,
            }
          : null,
      };
    }

    // Otherwise, use the provided hourTracking or defaults
    return { ...defaultValues, ...hourTracking };
  }, [hourTracking, user?.id, isEditMode]);

  return (
    <Formik
      initialValues={formattedInitialValues}
      validationSchema={HoursTrackingFormSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting }) => (
        <>
          <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <LSClock color="primary" />
            {dialogTitle}
          </DialogTitle>

          <Divider />

          <DialogContent>
            <Form id="hoursTrackingForm">
              <TextField
                gridProps={{ xs: 6, md: 3 }}
                label="Horas trabajadas"
                name="hours"
                placeholder="00"
                type="number"
              />

              <TextField
                gridProps={{ xs: 6, md: 3 }}
                label="Minutos"
                name="minutes"
                placeholder="00"
                type="number"
              />

              <Grid
                item
                xs={6}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'block',
                  },
                }}
              />

              <DateField
                label="Fecha"
                name="date"
                gridProps={{ xs: 12, md: 6 }}
              />

              <CategoryHoursAutocomplete
                gridProps={{ xs: 12, md: 6 }}
                label="Categoría"
                name="category"
              />

              <StudioMembersAutocomplete
                withSelectAll={false}
                label="Usuario"
                multiple={false}
                name="assigned"
              />

              <FilecasesAutocomplete
                disabled={disabledFields?.filecase}
                label="Expediente"
                name="filecase"
              />

              <CommentField
                sx={{
                  bgcolor: 'background.input',
                  p: 2,
                  borderRadius: 1,
                }}
                disableUpload
                name="concept"
                placeholder="Concepto"
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button fullWidth disabled={!!isSubmitting} onClick={onClose}>
                Cancelar
              </Button>

              <SubmitButton
                fullWidth
                form="hoursTrackingForm"
                gridProps={{ sx: { width: '100%' } }}
              >
                {buttonLabel}
              </SubmitButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default HoursTrackingForm;

import React, { useMemo } from 'react';
import { Box, Stack, Typography, Tooltip } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useDialog } from 'src/dialogs/Dialogs';
import { humanizeHoursTrackingTime } from './utils';
import Table from 'src/components/v3/Table';
import { CreationInfoBanner } from 'src/components/v3/CreationInfoBanner';
import { generateColorFromText } from 'src/utils/colors';
import { useTimeTrackingColumns } from 'src/tables/TimeTrackingTable/columns';

export const hoursTrackingByCategoryQuery = gql`
  query HoursTrackingByCategoryQuery($filters: HoursTrackingReportsFilters) {
    hoursTrackingStats(filters: $filters) {
      totalHours
    }
    hoursTrackingByCategory(filters: $filters) {
      id
      name
      hours
      percentage
    }
  }
`;

const HoursTrackingSection = ({ filecase }) => {
  const dispatchHoursTrackingDialog = useDialog('timeTrackingForm');

  const { data, loading } = useQuery(hoursTrackingByCategoryQuery, {
    variables: {
      filters: {
        filecases: [filecase.id],
      },
    },
    skip: !filecase?.id,
  });

  const totalHours = useMemo(() => {
    if (!data?.hoursTrackingStats) return 0;
    return data.hoursTrackingStats.totalHours;
  }, [data]);

  const columns = useTimeTrackingColumns({ compact: true });

  const handleOpenHoursTrackingDialog = () => {
    dispatchHoursTrackingDialog('filecaseHoursTracking', {
      disabledFields: { filecase: true },
      hourTracking: {
        filecase: { value: filecase.id, label: filecase.title },
      },
    });
  };

  const categoryColors = data?.hoursTrackingByCategory?.reduce(
    (acc, category) => {
      acc[category.name] = generateColorFromText(category.name);
      return acc;
    },
    {},
  );

  const categoriesData = useMemo(() => {
    if (!data?.hoursTrackingByCategory || !data.hoursTrackingStats?.totalHours)
      return [];

    // First, sort by hours (descending)
    const sortedCategories = [...data.hoursTrackingByCategory].sort((a, b) => b.hours - a.hours);
    
    // Calculate total hours from the data to ensure percentages add up to 100%
    const totalHoursFromData = sortedCategories.reduce((sum, cat) => sum + cat.hours, 0);
    
    // Map categories with accurate percentages
    return sortedCategories.map((category) => {
      // Calculate percentage based on the actual total from data
      const exactPercentage = (category.hours / totalHoursFromData) * 100;
      
      return {
        ...category,
        color: categoryColors[category.name],
        // Store both the exact percentage (for width calculation) and the display percentage
        exactPercentage,
        percentage: exactPercentage.toFixed(1),
        displayName: category.name || 'Sin categoría',
      };
    });
  }, [data, categoryColors]);

  return (
    <Stack gap={2}>
      <Box
        sx={{
          border: '1px solid #e0e0e0',
          borderRadius: 1,
          p: 2,
        }}
      >
        <Stack>
          <Typography fontSize={14} fontWeight={400}>
            Total de hs trabajadas
          </Typography>

          <Stack gap={4} direction="row" alignItems="center">
            <Typography fontSize={24} fontWeight={700}>
              {humanizeHoursTrackingTime(totalHours)}
            </Typography>

            <Box
              sx={{
                width: '100%',
                height: 12,
                borderRadius: 0.5,
                bgcolor: '#e0e0e0',
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              {categoriesData.map((category, index) => {
                // Use exactPercentage for calculations to avoid rounding errors
                const previousWidth = categoriesData
                  .slice(0, index)
                  .reduce((sum, cat) => sum + cat.exactPercentage, 0);

                return (
                  <Tooltip
                    key={category.id || index}
                    title={`${category.displayName}: ${humanizeHoursTrackingTime(category.hours)} (${category.percentage}%)`}
                    arrow
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        left: `${previousWidth}%`,
                        top: 0,
                        height: '100%',
                        width: `${category.exactPercentage}%`,
                        bgcolor: category.color,
                        // Apply appropriate border radius based on position
                        borderRadius: (() => {
                          if (previousWidth === 0 && previousWidth + category.exactPercentage >= 99.9)
                            return 1;
                          if (previousWidth === 0) 
                            return '4px 0 0 4px';
                          if (previousWidth + category.exactPercentage >= 99.9)
                            return '0 4px 4px 0';
                          return 0;
                        })(),
                      }}
                    />
                  </Tooltip>
                );
              })}
            </Box>
          </Stack>
        </Stack>
      </Box>

      {filecase.chargedHours?.length > 0 && (
        <Table
          hideFooter
          checkboxSelection={false}
          columns={columns}
          loading={loading}
          pageSize={filecase.chargedHours?.length}
          paginationMode="client"
          rows={
            filecase.chargedHours?.map((hour) => ({ ...hour, filecase })) || []
          }
          sxCard={{
            boxShadow: 'none',
          }}
        />
      )}

      <CreationInfoBanner
        buttonStr="Cargar horas"
        description="Lleva un detalle de las horas trabajas de tu equipo."
        title="Cargar horas de trabajo"
        onClick={handleOpenHoursTrackingDialog}
      />
    </Stack>
  );
};

export default HoursTrackingSection;

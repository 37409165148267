import React, { useMemo } from 'react';
import { Formik } from 'formik';
import {
  DialogActions,
  Button,
  DialogContent,
  Box,
  Grid,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  Divider,
  IconButton,
  Tooltip,
  DialogTitle,
} from '@mui/material';
import * as Yup from 'yup';
import * as Sentry from '@sentry/browser';

import dayjs from 'dayjs';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  CheckField,
  SwitchField,
  CommentField,
  SliderField,
} from 'src/components/ui-components/Form';
import {
  AssigneesAutocomplete,
  EntitiesAutocomplete,
  TagsAutocomplete,
  FilecasesAutocomplete,
  CurrencyAutocomplete,
} from 'src/autocompletes';
import { YupDateValidations } from 'src/utils/dates';
import { ExpandLess, IndeterminateCheckBox } from '@mui/icons-material';
import { formatMoney } from 'src/utils/formatters';
import { LSAdd } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDefaultCurrency } from 'src/autocompletes/CurrencyAutocomplete';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useMutation } from '@apollo/client';
import { CREATE_SALE, UPDATE_SALE } from 'src/graphql/mutations/studios';
import { SalesQuery } from '@legalsurf/queries';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';

const defaultValues = {
  date: dayjs(),
  client: null,
  amount: 0,
  filecase: null,
  responsible: null,
  paid: false,
  charged: 0,
  paidPercentage: 100,
  canBeSeenByCreatorOnly: false,
  hours: 0,
  items: [{ description: '', amount: 0 }],
  tags: [],
  currency: 'usd',
};

export const SalesForm = ({
  onSubmit,
  buttonLabel,
  filecase,
  isEdit,
  // sale,

  transaction: sale,
  // open,
  // isEdit,
  onClose,
  // type,
  entity,
  // filecase,
  // onSubmit,
}) => {
  const validationSchema = Yup.lazy((values) => {
    return Yup.object({
      currency: Yup.object()
        .required('Debes seleccionar o crear una moneda')
        .nullable(),
      date: YupDateValidations,
      items: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number()
              .positive('Debe ser un monto positivo')
              .required('Debes agregar un monto a tu honorario'),
          }),
        )
        .min(1, 'Debes agregar al menos un item a tu honorario'),
      charged: Yup.number().when('paid', {
        is: true,
        then: (schema) =>
          schema.max(
            values.amount,
            `Debes cobrar un maximo de ${values?.currency?.name}${formatMoney(values.amount)}`,
          ),
        otherwise: (schema) => schema,
      }),
      amount: Yup.number().when('items', {
        is: (items) => {
          if (!isEdit) {
            return false;
          }

          const total = items.reduce((acc, item) => acc + item.amount, 0);
          return total < sale.charged;
        },
        then: (schema) =>
          schema.min(
            sale?.charged,
            `Este honorario ya fue cobrado, el monto no puede ser menor a ${sale?.currency?.name}${formatMoney(sale?.charged)}`,
          ),
        otherwise: (schema) => schema,
      }),
    });
  });

  const studioId = useStudioId();
  const { currency: defaultCurrency } = useDefaultCurrency();
  const { openSnackbar } = useSnackbar();
  const [createSale] = useMutation(CREATE_SALE, {
    refetchQueries: [SalesQuery, 'TransactionsTableQuery'],
    update: (cache, response) => {
      if (filecase || response?.data?.createSale?.filecase) {
        cache.modify({
          id: cache.identify(filecase || response?.data?.createSale?.filecase),
          fields: {
            sales(sales, { toReference }) {
              return [...sales, toReference(response.data.createSale, true)];
            },
          },
        });
      }
    },
  });
  const [updateSale] = useMutation(UPDATE_SALE);

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = sale ? updateSale : createSale;

    const total = values.items.reduce((acc, item) => acc + item.amount, 0);

    try {
      const createdSale = await changeMutation({
        variables: {
          studio: studioId,
          amount: total,
          date: new Date(values.date),
          items: values.items,
          entity: values?.entity?.value,
          currency:
            typeof values?.currency === 'string'
              ? values?.currency
              : values?.currency?.value,
          filecase: values?.filecase?.value,
          hours: Number(values.hours),
          responsible: values?.responsible?.value,
          canBeSeenByCreatorOnly: values.canBeSeenByCreatorOnly,
          saleId: sale ? sale.id : undefined,
          tags: values?.tags?.map((tag) => tag?.value ?? tag.label),
          comments:
            values.comment && values.comment !== '<p><br></p>'
              ? [values.comment]
              : undefined,
          charges:
            values.paid || values.charged === 0
              ? [
                  {
                    amount: values.charged,
                    concept: 'Pagado',
                  },
                ]
              : undefined,
        },
      });

      openSnackbar('Honorario creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });

      if (onSubmit) {
        onSubmit(createdSale?.data?.createSale);
      }
    } catch (error) {
      Sentry.captureException(error);

      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  const initialValues = useMemo(
    () =>
      sale
        ? {
            ...sale,
            date: dayjs(sale.date),
            activeHours: !!sale.hours,
            entity: sale?.entity
              ? {
                  label: sale.entity.displayName,
                  value: sale.entity.id,
                }
              : null,
            filecase: sale?.filecase
              ? {
                  label: sale.filecase.title,
                  value: sale.filecase.id,
                }
              : null,
            responsible: sale?.responsible
              ? {
                  label: sale.responsible.name,
                  value: sale.responsible.id,
                }
              : null,
            tags: sale.tags
              ? sale.tags.map((tag) => ({
                  label: tag.name,
                  value: tag.name,
                }))
              : [],

            ...(sale.items?.length
              ? {
                  amount: sale.items.reduce(
                    (acc, item) => acc + item.amount,
                    0,
                  ),
                  items: sale.items.map((tag) => ({
                    description: tag.description,
                    amount: tag.amount,
                  })),
                }
              : {}),
            currency: sale.currency
              ? {
                  label: sale.currency.name,
                  value: sale.currency.name,
                }
              : null,
            paymentMethod: DEFAULT_PAYMENT_METHODS.find(
              (transac) => transac.value === sale.paymentMethod,
            ),
          }
        : {
            ...defaultValues,
            currency: defaultCurrency
              ? {
                  label: defaultCurrency?.name,
                  value: defaultCurrency?.name,
                }
              : null,
            entity: entity
              ? {
                  label: entity.displayName,
                  value: entity.id,
                }
              : null,
            filecase: filecase?.id
              ? {
                  label: filecase?.title,
                  value: filecase?.id,
                }
              : null,
          },
    [sale?.id, defaultCurrency?.name],
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <>
          <DialogTitle>
            {sale ? 'Editar honorario' : 'Nuevo honorario'}
          </DialogTitle>

          <Divider />

          <DialogContent>
            <Form id="salesForm">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <DateField gridProps={{ xs: 6 }} label="Fecha" name="date" />

                  <CurrencyAutocomplete
                    creatable
                    gridProps={{ xs: 6 }}
                    name="currency"
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack gap={1}>
                  <Stack direction="row">
                    <Typography flex={8} variant="label">
                      Detalle
                    </Typography>

                    <Typography flex={4} pl={4} variant="label">
                      Monto
                    </Typography>
                  </Stack>

                  {values.items.map((item, index) => (
                    <Grid container key={index} spacing={1} alignItems="center">
                      <TextField
                        gridProps={{ xs: 8 }}
                        flex={3}
                        name={`items[${index}].description`}
                        rows={1}
                      />

                      <TextField
                        flex={1}
                        gridProps={{ xs: 3 }}
                        name={`items[${index}].amount`}
                        type="number"
                        onChange={(event) => {
                          const newAmount = values.items.reduce(
                            (acc, val, i) =>
                              index === i
                                ? acc + Number(event.target.value)
                                : acc + val.amount,
                            0,
                          );

                          setFieldValue('charged', newAmount);
                          setFieldValue('paidPercentage', 100);
                          setFieldValue('amount', newAmount);
                        }}
                      />

                      {index !== 0 && (
                        <Grid item xs={1}>
                          <Tooltip title="Descartar linea">
                            <IconButton
                              onClick={() => {
                                const newItems = values.items.filter(
                                  (item, i) => i !== index,
                                );
                                const newAmount = newItems.reduce(
                                  (a, b) => a + b.amount,
                                  0,
                                );

                                setFieldValue('items', newItems);
                                setFieldValue('charged', newAmount);
                                setFieldValue('paidPercentage', 100);
                                setFieldValue('amount', newAmount);
                              }}
                            >
                              <IndeterminateCheckBox />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  ))}

                  <Box>
                    <Tooltip title="Agregar línea" placement="right">
                      <IconButton
                        color="primary"
                        onClick={() =>
                          setFieldValue('items', [
                            ...values.items,
                            { description: '', amount: 0 },
                          ])
                        }
                      >
                        <LSAdd color="white" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              {!isEdit && (
                <SwitchField
                  gridProps={{ xs: 6 }}
                  label="Marcar como pagado"
                  name="paid"
                />
              )}

              <Grid item xs={6} ml="auto">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography>Total:</Typography>

                  <Typography
                    fontWeight={600}
                    fontSize={24}
                    color="success.main"
                  >
                    {values?.currency?.value} {formatMoney(values?.amount)}
                  </Typography>
                </Stack>
              </Grid>

              {values.paid && (
                <>
                  <SliderField
                    gridProps={{ xs: 4 }}
                    label={values.paidPercentage + '%'}
                    name="paidPercentage"
                    step={10}
                    onChange={(event) => {
                      setFieldValue(
                        'charged',
                        event.target.value * (values.amount / 100),
                      );
                    }}
                  />

                  <Grid item xs={2} />

                  <Grid item xs={6}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography>Total cobrado: </Typography>

                      <Grid container>
                        <TextField
                          type="number"
                          name="charged"
                          onChange={(event) => {
                            const percentage =
                              values.amount && event.target.value
                                ? Math.round(
                                    (event.target.value / values.amount) * 100,
                                  )
                                : 100;

                            setFieldValue('paidPercentage', percentage);
                          }}
                        />
                      </Grid>

                      {/* <Typography
                          bgcolor="background.input"
                          p={1}
                          pr={1.5}
                          borderRadius={1}
                          fontSize={16}
                          fontWeight={600}
                        >
                          {values?.currency?.label}{' '}
                          {formatMoney(paidPercentageStr)}
                        </Typography> */}
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Accordion
                  disableGutters
                  elevation={0}
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                    borderStyle: 'dashed',
                    borderRadius: 1,
                    '&::before': { display: 'none' },
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandLess color="primary" />}>
                    <Stack>
                      <Typography
                        fontWeight="bold"
                        fontSize={18}
                        color="primary"
                      >
                        + información adicional
                      </Typography>

                      <Typography
                        fontWeight="400"
                        fontSize={15}
                        color="primary"
                      >
                        Detalles extras del honorario
                      </Typography>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {!isEdit && (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              bgcolor: 'background.input',
                              p: 2,
                              borderRadius: 2,
                            }}
                          >
                            <Grid container>
                              <CommentField
                                name="comment"
                                placeholder="Detalles adicionales (documentación, detalle de factura...)"
                              />
                            </Grid>
                          </Box>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <CheckField
                            gridProps={{ xs: 5 }}
                            label="Contabilizar horas"
                            name="activeHours"
                            onChange={() => setFieldValue('hours', 0)}
                          />

                          {values.activeHours && (
                            <TextField
                              noFormControlLabel
                              label="Horas"
                              name="hours"
                              gridProps={{ xs: 6 }}
                              type="number"
                            />
                          )}
                        </Grid>
                      </Grid>

                      <EntitiesAutocomplete label="Cliente" name="entity" />

                      <FilecasesAutocomplete
                        disabled={!!filecase?.id}
                        label="Expediente"
                        name="filecase"
                      />

                      <TagsAutocomplete
                        creatable
                        multiple
                        name="tags"
                        placeholder="Ej. Zona sur"
                      />

                      <AssigneesAutocomplete
                        label="Responsable"
                        name="responsible"
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button fullWidth disabled={!!isSubmitting} onClick={onClose}>
                Cancelar
              </Button>

              <SubmitButton
                fullWidth
                form="salesForm"
                gridProps={{ sx: { width: '100%' } }}
              >
                {buttonLabel}
              </SubmitButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

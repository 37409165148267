import { useCallback } from 'react';
import { useSearchFilterValues } from './utils';
import { debounce } from '@mui/material';

export const useFilters = (
  useFiltersStore,
  paramConfigMap,
  defaultInitialValues,
) => {
  const [params, setParams] = useSearchFilterValues(
    useFiltersStore,
    paramConfigMap,
  );

  const debouncedSetParams = useCallback(debounce(setParams, 300), [setParams]);

  const handleFiltersChange = useCallback(
    (values, debounce = false) => {
      const newParams = Object.entries(values).reduce((acc, [key, val]) => {
        if (Array.isArray(val)) {
          acc[key] = val
            .map((item) => item?.value ?? item)
            .filter((item) => item !== undefined);
        } else if (val && typeof val === 'object' && val !== null) {
          const processedVal = Object.keys(val).reduce((acc, key) => {
            if (val[key] !== undefined && val[key] !== 'undefined') {
              acc[key] = val[key];
            }
            return acc;
          }, {});

          if (Object.keys(processedVal).length > 0) {
            acc[key] = processedVal;
          }
        } else if (val !== undefined) {
          acc[key] = val;
        }
        return acc;
      }, {});

      if (debounce) {
        debouncedSetParams(newParams);
      } else {
        setParams(newParams);
      }
    },
    [debouncedSetParams, setParams],
  );

  const handleReset = useCallback(() => setParams({}), [setParams]);

  const initialValues = Object.keys(defaultInitialValues).reduce((acc, key) => {
    if (params[key]) {
      acc[key] = params[key];
    } else {
      acc[key] = defaultInitialValues[key];
    }
    return acc;
  }, {});

  return {
    filters: params,
    setFilters: handleFiltersChange,
    resetFilters: handleReset,
    initialValues,
  };
};

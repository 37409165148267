import React from 'react';
import {
  Chip,
  IconButton,
  Typography,
  Box,
  Avatar,
  useTheme,
  Tooltip,
  Divider,
  Stack,
  CircularProgress,
  Button,
} from '@mui/material';
import { useMutation } from '@apollo/client';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import { palette } from 'src/config/mui-theme/foundations';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  LSEdit as LSEditIcon,
  LSX as LSXIcon,
  LSTrash,
  LSSale,
  LSFilecase,
  LSCalendar,
  LSDocument,
} from 'src/components/icons';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useDialog } from 'src/dialogs/Dialogs';
import { Linkify } from 'src/components/Linkify';
import dayjs from 'dayjs';
import { WhatsAppButton } from 'src/popovers/NotificationTemplatesPopover/WhatsappButton';
import { formatMoney, stringAvatar } from 'src/utils/formatters';
import LSClock from 'src/components/icons/LSClock';
import { toDateFormat } from 'src/utils/dates';
import { ResponsibleCell } from 'src/tables/SalesTable/ResponsibleCell';
import { DELETE_SALE } from 'src/pages/Dashboard/Sales/SalesQueries';

export const SaleConcept = ({ sale }) => {
  if (!sale.items?.length) {
    if (sale.concept) {
      return <Linkify text={sale.concept} />;
    }

    return null;
  }

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        {sale.items.map((item, index) => (
          <Box key={index} display="flex" justifyContent="space-between">
            <Typography fontWeight={500}>• {item.description}</Typography>

            <Typography sx={{ textWrap: 'nowrap' }}>
              {sale.currency.name} {formatMoney(item.amount)}
            </Typography>
          </Box>
        ))}
      </Stack>

      <Divider />

      <Box display="flex" justifyContent="space-between">
        <Typography>Total</Typography>
        <Typography>
          {sale.currency.name} {formatMoney(sale.amount)}
        </Typography>
      </Box>
    </Stack>
  );
};

export const SingleSaleHeader = ({ sale, onClose }) => {
  const theme = useTheme();
  const studioId = useStudioId();

  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const dispatchSalesFormDialog = useDialog('salesForm');
  const dispatchEntityDialog = useDialog('entity');
  const dispatchFilecaseDialog = useDialog('filecase');

  const [deleteSale] = useMutation(DELETE_SALE, {
    update: (cache, data, options) => {
      const normalizedId = cache.identify({
        id: options.variables.id,
        __typename: 'Sale',
      });

      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleDeleteEntity = () =>
    deleteSale({
      variables: {
        id: sale.id,
        studioId,
      },
      onCompleted: onClose,
    });

  return (
    <Stack gap={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack alignItems="center" direction="row" gap={1}>
          <LSSale color="primary" />

          <Typography variant="h4" fontWeight={600}>
            Honorario
          </Typography>
        </Stack>

        <Stack direction="row" sx={{ textAlign: 'right' }}>
          <WhatsAppButton
            phone={sale?.entity?.phone}
            entity={sale?.entity}
            data={{ sale, entity: sale?.entity, filecase: sale?.filecase }}
          />
          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.UPDATE]}>
            <Tooltip color="primary" title="Editar directorio">
              <IconButton
                onClick={() =>
                  dispatchSalesFormDialog('SaleDetailsEditDialog', {
                    isEdit: true,
                    id: sale?.id,
                    transaction: sale,
                  })
                }
              >
                <LSEditIcon color="primary" fontSize="small" />
              </IconButton>
            </Tooltip>
          </PermissionGuard>

          <IconButton
            color="primary"
            onClick={() =>
              window.open(
                `/dashboard/${studioId}/sale/${sale.id}/voucher`,
                '_blank',
              )
            }
          >
            <LSDocument />
          </IconButton>

          <PermissionGuard permissions={[LS_PERMISSIONS_MAP.SALES.DELETE]}>
            <Tooltip color="primary" title="Editar directorio">
              <IconButton
                sx={{
                  p: 1,
                  '&:hover': {
                    backgroundColor: palette.error[200],

                    '& svg': {
                      color: 'error.main',
                    },
                  },
                }}
                onClick={() =>
                  dispatchWarningPromptDialog('DeleteEntitySingleSaleHeader', {
                    onAccept: handleDeleteEntity,
                    title: 'Eliminar Directorio',
                    content: `¿Estas seguro que deseas eliminar este gasto?`,
                  })
                }
              >
                <LSTrash fontSize="small" />
              </IconButton>
            </Tooltip>
          </PermissionGuard>

          <IconButton onClick={onClose}>
            <LSXIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Divider />

      <Stack
        direction={['column', 'row', 'row']}
        justifyContent={['initial', 'initial', 'space-between']}
        gap={[2, 4]}
      >
        <Stack gap={2} flex={4}>
          <Stack gap={1}>
            <Tooltip
              title={`Fecha del honorario: ${toDateFormat(sale.date)}`}
              placement="left"
            >
              <Stack direction="row" gap={0.5} alignItems="center">
                <LSCalendar color="primary" />

                <Typography fontWeight="600" fontSize={14}>
                  {toDateFormat(sale.date, 'DD/MM/YYYY')}
                </Typography>
              </Stack>
            </Tooltip>

            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography
                variant="h3"
                color="success.main"
                fontWeight="700"
                fontSize={36}
              >
                {sale.currency.name}
              </Typography>

              <Typography
                variant="h3"
                color="success.main"
                fontWeight="700"
                fontSize={36}
              >
                {formatMoney(sale.amount)}
              </Typography>
            </Stack>
          </Stack>

          <SaleConcept sale={sale} />

          {!!(Array.isArray(sale.tags) && sale.tags.length) && (
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {sale.tags.map((tag) => (
                <Chip
                  key={tag.name}
                  label={tag.name}
                  size="small"
                  sx={{
                    color: theme.palette.primary.dark,
                    backgroundColor: theme.palette.primary[100],
                    fontWeight: '500',
                  }}
                />
              ))}
            </Box>
          )}

          <Stack gap={0.5}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography fontWeight="600" fontSize={12}>
                Fecha de creación:
              </Typography>

              <Typography fontWeight="400" fontSize={12}>
                {dayjs(sale.createdAt).format('LLL[hs]')}
              </Typography>
            </Stack>

            <Stack direction="row" alignItems="center" gap={1}>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography fontWeight={600} fontSize={12}>
                  Creado por:
                </Typography>

                <Tooltip title={sale.issuedBy.name}>
                  <Avatar
                    alt={sale.issuedBy.name}
                    src={sale.issuedBy.picture}
                    sx={{ width: 25, height: 25, fontSize: 12 }}
                  />
                </Tooltip>
              </Stack>

              {sale.responsible && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography
                    whiteSpace="nowrap"
                    fontWeight={600}
                    fontSize={12}
                  >
                    Responsable:
                  </Typography>

                  <ResponsibleCell
                    row={sale}
                    sx={{
                      p: 0,
                      '.MuiAvatar-root': { width: 25, height: 25 },
                      '&:hover': {
                        opacity: 0.8,
                      },
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={2} flex={3}>
          <Stack direction="row" gap={2} alignItems="center" pl={1}>
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                size={50}
                sx={{
                  color: 'grey.100',
                }}
                thickness={7}
                value={100}
                variant="determinate"
              />

              <CircularProgress
                color="primary"
                size={50}
                sx={{
                  color: 'primary',
                  position: 'absolute',
                  left: 0,
                }}
                thickness={7}
                value={(sale.charged * 100) / sale.amount}
                variant="determinate"
              />
            </Box>

            <Stack my="auto">
              <Typography whiteSpace="nowrap">Total cobrado</Typography>

              <Typography color="primary" fontWeight="700" fontSize={17}>
                {sale.currency?.name} {formatMoney(sale.charged)}
              </Typography>
            </Stack>
          </Stack>

          {sale.entity && (
            <Tooltip title={sale.entity.displayName} placement="left">
              <Button
                variant="text"
                sx={{
                  gap: 1,
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                }}
                onClick={() =>
                  dispatchEntityDialog(sale.entity.id, { id: sale.entity.id })
                }
              >
                <Avatar
                  variant="squared"
                  {...stringAvatar(sale.entity.displayName, {
                    fontSize: 12,
                    width: 24,
                    height: 24,
                  })}
                />

                <Typography>Cliente: {sale.entity.displayName}</Typography>
              </Button>
            </Tooltip>
          )}

          {sale.filecase && (
            <Button
              variant="text"
              sx={{
                gap: 1,
                justifyContent: 'flex-start',
                textTransform: 'none',
              }}
              onClick={() =>
                dispatchFilecaseDialog(sale.filecase.id, {
                  id: sale.filecase.id,
                })
              }
            >
              <Stack direction="row" gap={1}>
                <LSFilecase color="primary" />

                <Typography textAlign="left" fontWeight={500}>
                  {sale.filecase.title}
                </Typography>
              </Stack>
            </Button>
          )}

          {sale?.hours > 0 && (
            <Stack direction="row" gap={1} alignItems="center" pl={1}>
              <LSClock color="primary" />

              <Typography fontWeight="500">
                {sale.hours} Horas contabilizadas
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

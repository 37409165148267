import React, { useCallback } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { Download } from '@mui/icons-material';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeader, {
  SectionHeaderActiveFilters,
  SectionHeaderHeading,
} from 'src/components/v3/SectionHeader';
import {
  AssigneesAutocomplete,
  CategoryHoursAutocomplete,
  FilecasesAutocomplete,
} from 'src/autocompletes';
import { ArrayParam, ObjectParam } from 'use-query-params';
import { createHeaderFiltersStore } from 'src/components/v3/SectionHeader/utils';
import { LSAdd, LSTrash } from 'src/components/icons';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { useFilters } from 'src/components/v3/SectionHeader/useFilters';
import { PeriodField } from 'src/components/ui-components/Form';
import MoreButton from 'src/components/v2/MoreButton';
import { gql, useMutation } from '@apollo/client';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import dayjs from 'dayjs';

const DeleteHoursTrackingMutation = gql`
  mutation deleteHoursTracking($ids: [ID!]!) {
    deleteHoursTracking(ids: $ids) {
      id
      studioId
    }
  }
`;

export const TimeTrackingTableSearchFiltersId =
  'TimeTrackingTableSearchFilters-v2';

const paramConfigMap = {
  filecases: ArrayParam,
  categories: ArrayParam,
  assigned: ArrayParam,
  period: ObjectParam,
};

const today = dayjs();

export const timeTrackingTableHeaderDefaultInitialValues = {
  filecases: [],
  categories: [],
  assigned: [],
  period: {
    option: '3',
    start: today.subtract(3, 'month').startOf('month').toDate(),
    end: today.endOf('date').toDate(),
  },
};

const useTimeTrackingFiltersStore = createHeaderFiltersStore(
  TimeTrackingTableSearchFiltersId,
  timeTrackingTableHeaderDefaultInitialValues,
);

export const useTimeTrackingFilters = () =>
  useFilters(
    useTimeTrackingFiltersStore,
    paramConfigMap,
    timeTrackingTableHeaderDefaultInitialValues,
  );

const Header = ({
  title,
  subtitle,
  loading,
  totalItems,
  selectedItems,
  setSelectedItems,
  onDownloadAsExcel: handleDownloadAsExcel,
}) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const { filters, setFilters, resetFilters, initialValues } =
    useTimeTrackingFilters();
  const dispatchTimeTrackingFormDialog = useDialog('timeTrackingForm');

  const [deleteHoursTracking, { loading: isDeleting }] = useMutation(
    DeleteHoursTrackingMutation,
    {
      update: (cache, { data }) => {
        setSelectedItems([]);

        data.deleteHoursTracking.forEach((hour) => {
          const normalizedId = cache.identify({
            id: hour.id,
            __typename: 'Hour',
          });

          cache.evict({ id: normalizedId });
        });

        cache.gc();
      },
    },
  );

  const handleFiltersChange = useCallback(
    (values) => Promise.resolve(setFilters(values)),
    [setFilters],
  );

  const handleReset = useCallback(
    (formikBag) => {
      resetFilters();
      formikBag.setValues({
        ...timeTrackingTableHeaderDefaultInitialValues,
        period: {
          option: '3',
          start: today.subtract(3, 'month').startOf('month').format('L'),
          end: today.endOf('date').format('L'),
        },
      });
    },
    [resetFilters],
  );

  return (
    <SectionHeader
      filters={initialValues}
      loading={loading || isDeleting}
      onChange={handleFiltersChange}
      onSubmit={handleFiltersChange}
    >
      {() => (
        <>
          <Stack alignItems="center" direction="row" gap={2}>
            <Stack>
              <SectionHeaderHeading variant="h3">{title}</SectionHeaderHeading>
              {subtitle && (
                <SectionHeaderHeading variant="subtitle1">
                  {subtitle}
                </SectionHeaderHeading>
              )}
            </Stack>

            <Box sx={{ ml: 'auto', display: 'flex', gap: 2 }}>
              <PeriodField size="small" name="period" />

              <SectionHeaderAdvanceFilters
                onReset={handleReset}
                buttonProps={{ variant: 'contained' }}
              >
                <CategoryHoursAutocomplete
                  multiple
                  label="Categorías"
                  name="categories"
                  size="small"
                />

                <FilecasesAutocomplete
                  multiple
                  label="Expedientes"
                  name="filecases"
                  size="small"
                />

                <AssigneesAutocomplete
                  multiple
                  label="Asignados"
                  name="assigned"
                  placeholder="Ej: Nicolas Salazar"
                  size="small"
                />
              </SectionHeaderAdvanceFilters>

              <PermissionGuard
                permissions={[LS_PERMISSIONS_MAP.TIME_TRACKING.CREATE]}
              >
                <Button
                  color="primary"
                  startIcon={<LSAdd color="white" />}
                  variant="contained"
                  onClick={() =>
                    dispatchTimeTrackingFormDialog('TimeTrackingForm')
                  }
                >
                  Cargar horas
                </Button>
              </PermissionGuard>

              <MoreButton popperHeader="Acciones">
                <Stack gap={0.5} p={1}>
                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.FILECASES.DELETE]}
                  >
                    <Button
                      disabled={!selectedItems.length}
                      size="large"
                      startIcon={<LSTrash />}
                      sx={{ justifyContent: 'flex-start' }}
                      variant="text"
                      onClick={() =>
                        dispatchWarningPromptDialog('TimeTrackingTableHeader', {
                          onAccept: () =>
                            deleteHoursTracking({
                              variables: {
                                ids: selectedItems,
                              },
                            }),
                          title: 'Eliminar time tracking',
                        })
                      }
                    >
                      Eliminar
                    </Button>
                  </PermissionGuard>

                  <PermissionGuard
                    permissions={[LS_PERMISSIONS_MAP.TIME_TRACKING.ACCESS]}
                  >
                    <Button
                      // disabled={isDeleting || loadingFilecasesToExport}
                      size="large"
                      startIcon={<Download />}
                      variant="text"
                      onClick={handleDownloadAsExcel}
                    >
                      Descargar Excel ({selectedItems?.length || totalItems})
                    </Button>
                  </PermissionGuard>
                </Stack>
              </MoreButton>
              {/* <Button
                startIcon={<Download />}
                variant="outlined"
                onClick={handleDownloadAsExcel}
              >
                Descargar Excel ({selectedItems?.length || totalItems})
              </Button> */}
            </Box>
          </Stack>

          <SectionHeaderActiveFilters
            filterLabels={{
              filecases: 'Expedientes',
              assigned: 'Usuarios',
              categories: 'Categorías',
            }}
            filters={filters}
          />
        </>
      )}
    </SectionHeader>
  );
};

export default Header;

import React, { useRef } from 'react';
import {
  Avatar,
  Typography,
  Button,
  CardContent,
  CircularProgress,
  Card,
  CardActions,
  Chip,
  Tooltip,
  Stack,
} from '@mui/material';

import Page from 'src/components/v2/Page';
import { useReactToPrint } from 'react-to-print';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toDateFormat } from 'src/utils/dates';
import { formatMoney, stringAvatar } from 'src/utils/formatters';
import { GET_CHARGE } from '../SalesQueries';
import { LSCalendar, LSFilecase } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import LSClock from 'src/components/icons/LSClock';
import { useStudio } from 'src/utils/hooks/useStudio';

export const VoucherCharge = () => {
  const { chargeId } = useParams();
  const { studio } = useStudio();
  const dispatchEntityDialog = useDialog('entity');
  const dispatchFilecaseDialog = useDialog('filecase');

  const { data: { charge } = {}, loading } = useQuery(GET_CHARGE, {
    variables: {
      id: chargeId,
    },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  if (loading) return <CircularProgress />;

  return (
    <Page sx={{ backgroundColor: '#E6E8F2', minHeight: '100vh' }}>
      <Card
        sx={{
          margin: 'auto',
          maxWidth: 500,
          border: '1px solid #C7C7C7',
          boxShadow: 'none',
          borderRadius: 2,
        }}
      >
        <CardContent ref={componentRef}>
          <Stack gap={2} p={6}>
            <Avatar
              alt={null}
              src={studio.picture}
              style={{
                width: 70,
                height: 70,
                borderRadius: 80,
              }}
            />

            <Typography sx={{ fontWeight: 500, fontSize: 24 }} variant="span">
              {studio.name}
            </Typography>

            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography
                sx={{
                  backgroundColor: 'success.light',
                  padding: 0.9,
                  borderRadius: '0.3rem',
                  width: 'fit-content',
                  // color: '#358489',
                  fontWeight: 500,
                  fontSize: 12,
                }}
                variant="span"
              >
                Comprobante de cobro
              </Typography>

              <Chip
                color="primary"
                label={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === charge.method,
                  ).label
                }
                sx={{ mr: 2, maxWidth: 120 }}
                value={
                  DEFAULT_PAYMENT_METHODS.find(
                    (method) => method.value === charge.method,
                  ).label
                }
                variant="light"
              />
            </Stack>

            <Tooltip
              title={`Fecha del cobro: ${toDateFormat(charge.date)}`}
              placement="left"
            >
              <Stack direction="row" gap={0.5} alignItems="center">
                <LSCalendar color="primary" />

                <Typography fontWeight="600" fontSize={14}>
                  {toDateFormat(charge.date, 'DD/MM/YYYY')}
                </Typography>
              </Stack>
            </Tooltip>

            <Typography sx={{ fontWeight: 500 }} variant="h5">
              {charge.concept}
            </Typography>

            {charge.sale.entity && (
              <Tooltip title={charge.sale.entity.displayName} placement="left">
                <Button
                  variant="text"
                  sx={{
                    gap: 1,
                    justifyContent: 'flex-start',
                    textTransform: 'none',
                  }}
                  onClick={() =>
                    dispatchEntityDialog(charge.sale.entity.id, {
                      id: charge.sale.entity.id,
                    })
                  }
                >
                  <Avatar
                    variant="squared"
                    {...stringAvatar(charge.sale.entity.displayName, {
                      fontSize: 10,
                      width: 26,
                      height: 26,
                    })}
                  />

                  <Typography>
                    Cliente: {charge.sale.entity.displayName}
                  </Typography>
                </Button>
              </Tooltip>
            )}

            {charge.sale.filecase && (
              <Button
                variant="text"
                sx={{
                  gap: 1,
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                }}
                onClick={() =>
                  dispatchFilecaseDialog(charge.sale.filecase.id, {
                    id: charge.sale.filecase.id,
                  })
                }
              >
                <Stack direction="row" gap={1}>
                  <LSFilecase color="primary" />

                  <Typography textAlign="left" fontWeight={500}>
                    {charge.sale.filecase.title}
                  </Typography>
                </Stack>
              </Button>
            )}

            {charge.sale.hours > 0 && (
              <Stack direction="row" gap={1} alignItems="center" pl={1}>
                <LSClock color="primary" />

                <Typography fontWeight="500">
                  {charge.sale.hours} Horas contabilizadas
                </Typography>
              </Stack>
            )}

            <Typography
              sx={{
                fontSize: 36,
                fontWeight: 600,
                color: '#1A1A1A',
              }}
              variant="span"
            >
              {charge.currency?.name} {formatMoney(charge.amount)}
            </Typography>
          </Stack>
        </CardContent>

        <CardActions>
          <Button
            sx={{
              marginLeft: 'auto',
            }}
            variant="filled"
            onClick={() => handlePrint()}
          >
            ⬇ Descargar Comprobante
          </Button>
        </CardActions>
      </Card>
    </Page>
  );
};

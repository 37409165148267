import { gql } from '@apollo/client';

export const TimeTrackingQuery = gql`
  query PaginatedHours(
    $studio: ID!
    $filecases: [ID!]
    $assigned: [ID!]
    $categories: [ID!]
    $orderBy: String
    $sortOrder: String
    $limit: Int
    $offset: Int
    $date: DateRange
  ) {
    paginatedHours(
      studio: $studio
      filters: {
        filecases: $filecases
        assigned: $assigned
        categories: $categories
        date: $date
      }
      orderBy: $orderBy
      sortOrder: $sortOrder
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        amount
        concept
        date
        hours
        minutes
        createdAt
        updatedAt
        assignedTo {
          id
          name
          picture
        }
        filecase {
          id
          title
        }
        category {
          name
          studioId
        }
        createdBy {
          id
          name
          picture
        }
      }
      count
    }
  }
`;

// For Excel export
export const TimeTrackingToExportQuery = gql`
  query TimeTrackingToExport(
    $studio: ID!
    $filecases: [ID!]
    $assigned: [ID!]
    $categories: [ID!]
    $orderBy: String
    $sortOrder: String
    $limit: Int
    $offset: Int
    $date: DateRange
  ) {
    paginatedHours(
      studio: $studio
      filters: {
        filecases: $filecases
        assigned: $assigned
        categories: $categories
        date: $date
      }
      orderBy: $orderBy
      sortOrder: $sortOrder
      limit: $limit
      offset: $offset
    ) {
      data {
        id
        amount
        concept
        date
        hours
        minutes
        createdAt
        updatedAt
        assignedTo {
          id
          name
          picture
        }
        filecase {
          id
          title
        }
        category {
          name
          studioId
        }
        createdBy {
          id
          name
          picture
        }
      }
      count
    }
  }
`;

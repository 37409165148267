import React from 'react';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import {
  Button,
  DialogContent,
  DialogActions,
  Grid,
  Fade,
} from '@mui/material';

import Form, { TextField } from 'src/components/ui-components/Form';
import CheckField from 'src/components/ui-components/Form/CheckField';
import {
  AssigneesAutocomplete,
  FilecasesAutocomplete,
  EntitiesAutocomplete,
  TaskStatusAutocomplete,
  CalendarsAutocomplete,
} from 'src/autocompletes';
import {
  LSPin as LSPinIcon,
  LSDangerSign as LSDangerSignIcon,
} from 'src/components/icons';

import { LoadingButton } from '@mui/lab';
import { PRIMARY_DASHED } from 'src/config/mui-theme/components/MuiButton/variants';

import CalendarEventFormSchema from './validation-schema';
import DateBlockField from './DateBlockField';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useStudioMembers } from '@legalsurf/hooks';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useMutation, useQuery } from '@apollo/client';
import { CREATE_EVENT, UPDATE_EVENT } from 'src/graphql/mutations/calendars';
import {
  CalendarEventsQuery,
  SingleCalendarEventQuery,
} from '@legalsurf/queries';
import { GET_FILECASE_SINGLE_DATA } from 'src/graphql/queries/filecases';
import { getTableTasksQuery } from 'src/tables/TaskBoard/gql';
import { GET_ENTITY_SINGLE_PAGE_METADATA } from 'src/graphql/queries/entities';
import { removeNullKeys } from 'src/utils/formatters';
import { DEFAULT_TASK_STATES, tasksStatesObj } from '@legalsurf/common';

const getRefetchQueries = ({ data }) => {
  let refetchQueries = [
    CalendarEventsQuery,
    GET_FILECASE_SINGLE_DATA,
    getTableTasksQuery,
  ];

  const event = data.updateEvent || data.event;

  if (event?.entities?.length || event?.entities?.length) {
    refetchQueries = [
      ...refetchQueries,
      ...event.entities.map((entity) => ({
        query: GET_ENTITY_SINGLE_PAGE_METADATA,
        variables: {
          studio: event.studioId,
          entity: entity.id,
        },
      })),
    ];
  }

  return refetchQueries;
};

export const getDefaultEventFormInitialValues = (data) => {
  const now = dayjs(data?.start);

  return {
    title: data?.title ?? '',
    description: data?.description ?? '',
    calendar: data?.calendar ?? null,
    type: data?.type ?? 'task',
    taskState: data?.taskState ?? DEFAULT_TASK_STATES[0],
    start: now.set('minutes', 0).set('seconds', 0).set('milliseconds', 0),
    end: data?.end
      ? dayjs(data.end)
      : now
          .add(1, 'hour')
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0),
    allDay: data?.allDay ?? false,
    assigned: data?.assigned ?? [],
    filecase: data?.filecase ?? null,
    entities: data?.entities ?? [],
  };
};

const parseEventToInitialValues = (
  event,
  { type, filecase, entities, members, clone, start } = {},
) => {
  const now = dayjs(start);

  if (!event) {
    const obj = getDefaultEventFormInitialValues({ type, entities });

    obj.start = now.set('minutes', 0).set('seconds', 0).set('milliseconds', 0);
    obj.end = now
      .add(1, 'hour')
      .set('minutes', 0)
      .set('seconds', 0)
      .set('milliseconds', 0);

    obj.filecase = filecase
      ? { label: filecase.title, value: filecase.id }
      : null;

    if (members?.length === 1) {
      obj.assigned = [
        {
          label: members[0].name,
          value: members[0].id,
        },
      ];
    }

    return obj;
  }

  return {
    ...event,
    start: clone
      ? now.set('minutes', 0).set('seconds', 0).set('milliseconds', 0)
      : dayjs(event.start),
    end: clone
      ? now
          .add(1, 'hour')
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0)
      : dayjs(event.end),

    type: event.type || 'alert',
    calendar: event.calendar
      ? {
          label: event.calendar.name,
          value: event.calendar.id,
        }
      : null,

    entities: Array.isArray(event.entities)
      ? event.entities.map((entity) => ({
          label: entity.displayName,
          value: entity.id,
        }))
      : [],
    assigned: Array.isArray(event.assigned)
      ? event.assigned.map((assigned) => ({
          label: assigned.name,
          value: assigned.id,
        }))
      : [],

    filecase: event.filecase
      ? {
          label: event.filecase.title,
          value: event.filecase.id,
        }
      : null,

    taskState:
      event.type === 'task'
        ? { label: tasksStatesObj[event.state]?.label, value: event.state }
        : null,
  };
};

export const getEventFormSubmitText = ({ eventId, clone }) => {
  if (eventId && clone) {
    return 'Duplicar evento';
  }

  if (eventId) {
    return 'Editar evento';
  }

  return 'Agregar nuevo evento';
};

export const EventForm = ({
  onClose,
  filecase,
  id: eventId,
  calendarId,
  clone,
  initialValues,
  replaceSubmit,
  ...rest
}) => {
  const studioId = useStudioId();
  const { data: members } = useStudioMembers({
    variables: {
      studio: studioId,
      status: ['ACTIVE'],
    },
  });
  const { openSnackbar } = useSnackbar();
  const [createCalendarEvent] = useMutation(CREATE_EVENT, {
    refetchQueries: getRefetchQueries,
  });

  const [updateCalendarEvent] = useMutation(UPDATE_EVENT, {
    refetchQueries: getRefetchQueries,
  });

  const { data: { event } = {} } = useQuery(SingleCalendarEventQuery, {
    fetchPolicy: 'cache-only',
    variables: {
      id: eventId,
      calendarId,
    },
    skip: !eventId || !calendarId,
  });

  const formInitialValues =
    initialValues ??
    parseEventToInitialValues(event, {
      ...rest,
      members,
      filecase,
      clone,
    });

  const handleSubmit = async (values, formikBag) => {
    const isEdit = eventId && calendarId;

    try {
      if (isEdit && !clone) {
        await updateCalendarEvent({
          variables: {
            ...values,
            id: values.id,
            calendar: values.calendar.value,
            start: dayjs(values.start).toISOString(),
            end: dayjs(values.end).toISOString(),
            ...removeNullKeys({
              entities:
                Array.isArray(values.entities) &&
                values.entities.map((entity) => entity.value),
              assigned:
                Array.isArray(values.assigned) &&
                values.assigned.map((assigned) => assigned.value),
              filecase: values.filecase && values.filecase.value,
              taskState: values.taskState && values.taskState.value,
            }),
          },
        });
      } else {
        const variables = {
          ...values,
          timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
          studio: studioId,
          calendar: values.calendar.value,
          ...removeNullKeys({
            entities:
              Array.isArray(values.entities) &&
              values.entities.map((entity) => entity.value ?? entity),
            assigned:
              Array.isArray(values.assigned) &&
              values.assigned.map((asignee) => asignee.value),
            filecase: values.filecase && values.filecase.value,
            taskState: values.taskState && values.taskState.value,
          }),
          start: dayjs(values.start).toISOString(),
          end: dayjs(values.end).toISOString(),
        };

        if (replaceSubmit) {
          replaceSubmit(values);
          onClose();
          formikBag.setSubmitting(false);
          formikBag.resetForm();
          return;
        }

        await createCalendarEvent({
          variables,
        });
      }

      onClose();
      formikBag.resetForm();
      openSnackbar(
        isEdit
          ? 'El evento se ha actualizado con éxito'
          : 'El evento se ha creado con éxito',
        {
          severity: 'success',
        },
      );
    } catch ({ message }) {
      openSnackbar(message ?? 'Ha ocurrido un error.', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handleChangeEventType = (newType, formikBag) => {
    if (newType !== null) {
      formikBag.setFieldValue('type', newType);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: clone ? formInitialValues?.id : undefined,
        ...formInitialValues,
      }}
      validationSchema={CalendarEventFormSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <>
          <DialogContent>
            <Form id="calendarEventForm">
              <Grid container item gap={3} xs={12}>
                <Grid item md sx={{ mt: 1 }} xs={12}>
                  <Button
                    startIcon={<LSDangerSignIcon />}
                    sx={{
                      ...(values.type === 'alert' && {
                        ...PRIMARY_DASHED.style['&:hover'],
                      }),
                    }}
                    value="alert"
                    variant="dashed"
                    onClick={() =>
                      handleChangeEventType('alert', { setFieldValue })
                    }
                  >
                    Alerta
                  </Button>
                </Grid>

                <Grid item md sx={{ mt: 1 }} xs={12}>
                  <Button
                    startIcon={<LSPinIcon />}
                    sx={{
                      ...(values.type === 'task' && {
                        ...PRIMARY_DASHED.style['&:hover'],
                      }),
                    }}
                    value="task"
                    variant="dashed"
                    onClick={() =>
                      handleChangeEventType('task', {
                        setFieldValue,
                      })
                    }
                  >
                    Tarea
                  </Button>
                </Grid>

                <Fade in={values.type === 'task'}>
                  <Grid item md={6} xs={12}>
                    <TaskStatusAutocomplete
                      label="Estado"
                      name="taskState"
                      placeholder="Ej. Cerrado"
                    />
                  </Grid>
                </Fade>
              </Grid>

              <CalendarsAutocomplete
                disabled={!!initialValues?.googleCalendarData && !clone}
                getOptionDisabled={
                  initialValues?.id
                    ? (option) => !!option.googleCalendarId && !clone
                    : undefined
                }
                name="calendar"
              />

              <TextField
                label="Titulo"
                name="title"
                placeholder="Ej. Junta con cliente"
              />

              <TextField
                multiline
                label="Description"
                name="description"
                rows={3}
              />

              <DateBlockField />

              <CheckField
                label="Todo el dia"
                name="allDay"
                onChange={() => {
                  setFieldValue('start', dayjs(values.start).startOf('day'));
                  setFieldValue('end', dayjs(values.start).endOf('day'));
                }}
              />

              <FilecasesAutocomplete
                disabled={Boolean(filecase)}
                name="filecase"
              />

              <AssigneesAutocomplete multiple name="assigned" />

              <EntitiesAutocomplete multiple name="entities" />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!!isSubmitting}
              sx={{ mr: 'auto' }}
              onClick={onClose}
            >
              Cancelar
            </Button>

            <LoadingButton
              disabled={isSubmitting}
              form="calendarEventForm"
              loading={isSubmitting}
              sx={{ px: 4 }}
              type="submit"
              variant="contained"
            >
              {getEventFormSubmitText({ eventId: values.id, clone })}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

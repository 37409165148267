import React, { useState } from 'react';

import {
  Box,
  MenuItem,
  IconButton,
  Menu,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';

import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';

import { getFilesTableDocumentsQuery } from '../gql';
import { useDialog } from 'src/dialogs/Dialogs';

const ActionsCell = ({ row: document }) => {
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const { isDeleting, handleDeleteDocument } = useDigitalOceanSpace({
    refetchQueries: [getFilesTableDocumentsQuery],
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          disabled={isDeleting}
          onClick={() =>
            dispatchWarningPromptDialog(document.id, {
              onAccept: () => handleDeleteDocument([document.id]),
            })
          }
        >
          <ListItemIcon>
            <DeleteIcon color="primary" fontSize="small" />
          </ListItemIcon>

          <ListItemText>Eliminar</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ActionsCell;

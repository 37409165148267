import React from 'react';
import { Formik } from 'formik';
import { DialogActions, Button, DialogContent, DialogTitle, Divider } from '@mui/material';
import * as Yup from 'yup';
import * as Sentry from '@sentry/react';
import { useMutation, gql } from '@apollo/client';
import { useSnackbar } from 'src/components/v3/Snackbar';
import dayjs from 'dayjs';

import Form, {
  SubmitButton,
  DateField,
  TextField,
  SelectField,
} from 'src/components/ui-components/Form';
import { DEFAULT_PAYMENT_METHODS } from '@legalsurf/common';
import MoneyField from 'src/components/ui-components/Form/MoneyField';
import { YupDateValidations } from 'src/utils/dates';
import { useStudioId } from 'src/utils/hooks/useStudioId';

const CREATE_CHARGE = gql`
  mutation createCharge(
    $studioId: ID!
    $sale: ID!
    $amount: Float!
    $concept: String
    $date: DateTime!
    $method: CharginMethod!
  ) {
    createCharge(
      studioId: $studioId
      sale: $sale
      amount: $amount
      concept: $concept
      date: $date
      method: $method
    ) {
      id
      date
      amount
      method
      concept

      sale {
        id
        charged
      }
    }
  }
`;

const UPDATE_CHARGE = gql`
  mutation updateCharge(
    $chargeId: ID!
    $studioId: ID!
    $concept: String
    $date: DateTime!
    $amount: Float!
    $method: CharginMethod!
  ) {
    updateCharge(
      chargeId: $chargeId
      concept: $concept
      date: $date
      amount: $amount
      method: $method
      studioId: $studioId
    ) {
      id
      date
      amount
      method
      concept

      sale {
        id
        charged
      }
    }
  }
`;

const GET_SALE = gql`
  query getSale($id: ID!) {
    sale(id: $id) {
      id
      date
      items {
        amount
        description
      }
      amount
      hours
      charged
      charges {
        id
        date
        method
        concept
        amount
      }
      entity {
        id
        type
        displayName
      }
      filecase {
        id
        title
      }
      responsible {
        id
        picture
        name
      }
      tags {
        id
        name
        studioId
      }
    }
  }
`;

const validationSchema = Yup.lazy((values) => {
  const maxAmount =
    values.saleTotal - (values.charged - (values?.charge?.amount ?? 0));

  return Yup.object({
    date: YupDateValidations,
    amount: Yup.number()
      .positive('Debe ser un monto positivo')
      .max(maxAmount, `La cantidad no puede exceder: ${maxAmount}`)
      .required('Debes agregar un monto a tu honorario'),
    paymentMethod: Yup.object()
      .nullable()
      .required('Debes agregar un metodo de pago'),
  });
});

const ChargeForm = ({
  sale = {},
  charge,
  title,
  onClose,
  ...rest
}) => {
  const { openSnackbar } = useSnackbar();
  const studioId = useStudioId();
  const [createCharge] = useMutation(CREATE_CHARGE, {
    refetchQueries: [
      {
        query: GET_SALE,
        variables: {
          id: sale?.id,
        },
      },
    ],
  });
  const [updateCharge] = useMutation(UPDATE_CHARGE);

  const handleSubmit = async (values, formikBag) => {
    const changeMutation = charge ? updateCharge : createCharge;
    try {
      await changeMutation({
        variables: {
          studioId,
          amount: Number(values.amount),
          chargeId: charge ? charge.id : null,
          date: new Date(values.date).toISOString(),
          concept: values.concept,
          method: values.paymentMethod.value,
          sale: sale.id,
        },
      });

      openSnackbar('Cobro creado exitosamente.', {
        severity: 'success',
      });

      onClose();

      formikBag.resetForm({
        values: undefined,
      });
    } catch (error) {
      Sentry.captureException(error);
      openSnackbar('Hubo un error. Intente mas tarde.', {
        severity: 'error',
      });
    }
  };

  return (
    <Formik
      {...rest}
      enableReinitialize
      initialValues={{
        ...sale,
        currency: sale.currency
          ? { label: sale.currency.name, value: sale.currency.name }
          : null,
        date: dayjs(charge?.date),
        saleTotal: sale?.amount,
        charge: charge,
        amount: charge?.amount ? charge.amount : 0,
        concept: charge?.concept,
        paymentMethod:
          DEFAULT_PAYMENT_METHODS.find(
            (method) => method.value === charge?.method,
          ) || DEFAULT_PAYMENT_METHODS[2],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <>
          <DialogTitle>{title}</DialogTitle>
          <Divider />
          <DialogContent>
            <Form id="salesForm">
              <DateField label="Fecha" name="date" />

              <MoneyField
                disabledCurrency
                amountName="amount"
                currencyName="currency"
                label="Monto"
                type="number"
              />

              <TextField multiline label="Concepto" minRows={3} name="concept" />

              <SelectField
                label="Medio de pago"
                name="paymentMethod"
                options={DEFAULT_PAYMENT_METHODS}
              />
            </Form>
          </DialogContent>

          <DialogActions>
            <Button fullWidth disabled={!!isSubmitting} onClick={onClose}>
              Cancelar
            </Button>

            <SubmitButton fullWidth form="salesForm">
              {charge ? 'Editar' : 'Guardar'}
            </SubmitButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default ChargeForm;

import React, { useMemo, useRef, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Button,
  Stack,
  Avatar,
  CircularProgress,
  useTheme,
  Divider,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Page from 'src/components/v2/Page';
import {
  LSActivity,
  LSDocument,
  LSFilecase,
  LSStats,
} from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import LSClock from 'src/components/icons/LSClock';
import { useReactToPrint } from 'react-to-print';
import { FilecaseReportsFilters } from './FilecaseReportsFilters';
import dayjs from 'dayjs';

const AllFilecasesDataQuery = gql`
  query AllFilecasesDataQuery(
    $studioId: ID!
    $filters: FilecaseReportsFilters
  ) {
    filecasesStats(filters: $filters) {
      filecaseCount
      documentCount
      workedHours
      workedMinutes
    }

    filecasesByAssigned(filters: $filters) {
      id
      name
      picture
      filecaseCount
    }

    filecasesReport(filters: $filters) {
      year
      month
      day
      count
    }

    filecasesByCategory(studioId: $studioId, filters: $filters) {
      studioId
      filecasesCount
      filecasesTotalPercentage
      category
    }

    filecasesByState(studioId: $studioId, filters: $filters) {
      studioId
      filecasesCount
      filecasesTotalPercentage
      state
      color
    }

    tagsStats(filters: $filters) {
      name
      count
    }
  }
`;

const ReportsFilecasesCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: 'black', p: 1, borderRadius: 1 }}>
        <Typography variant="body1" color="white">
          {payload[0].payload.name}: {payload[0].value}
        </Typography>
      </Box>
    );
  }

  return null;
};

const CardTitle = ({ children, ...props }) => (
  <Typography fontSize={14} fontWeight={600} mb={2} {...props}>
    {children}
  </Typography>
);

// Dashboard Metrics Component - Card-based layout
export const FilecasesStatsSummary = ({ data }) => {
  if (!data) return <p>No data available for Filecases Stats.</p>;

  const { filecaseCount, documentCount, workedHours, workedMinutes } =
    data.filecasesStats;

  const maxCount = Math.max(
    ...data.filecasesByAssigned.map((user) => user.filecaseCount),
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack gap={3}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <LSActivity color="primary" sx={{ fontSize: 22 }} />

                <CardTitle mb={0} fontWeight={500}>
                  Estadísticas Generales
                </CardTitle>
              </Stack>

              <Divider />
            </Stack>

            <Box>
              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <LSFilecase color="primary" sx={{ fontSize: 20 }} />
                </Box>

                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={14} fontWeight="bold">
                    {filecaseCount}
                  </Typography>

                  <Typography fontSize={14} fontWeight={400}>
                    expedientes
                  </Typography>
                </Stack>
              </Box>

              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <LSDocument color="primary" sx={{ fontSize: 20 }} />
                </Box>

                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={14} fontWeight="bold">
                    {documentCount}
                  </Typography>

                  <Typography fontSize={14} fontWeight={400}>
                    documentos
                  </Typography>
                </Stack>
              </Box>

              <Box display="flex" alignItems="center">
                <Box mr={1}>
                  <LSClock color="primary" sx={{ fontSize: 20 }} />
                </Box>

                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={14} fontWeight="bold">
                    {workedHours} hrs {workedMinutes} min
                  </Typography>

                  <Typography fontSize={14} fontWeight={400}>
                    Time tracking
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Stack>

          <Stack gap={2}>
            <Stack gap={1}>
              <CardTitle mb={0} fontWeight={500}>
                Expedientes asignados
              </CardTitle>

              <Divider />
            </Stack>

            <Box display="flex" flexDirection="column" gap={2}>
              {data.filecasesByAssigned.map((user) => (
                <Box
                  key={user.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  gap={1}
                >
                  <Avatar
                    alt="Avatar"
                    size="small"
                    src={user.picture}
                    sx={{ width: 32, height: 32, mt: 1, bgcolor: 'grey.200' }}
                  >
                    {user.name === 'Unassigned' ? ' ' : null}
                  </Avatar>

                  <Box width="100%">
                    <Box display="flex" justifyContent="space-between" mb={0.5}>
                      <Typography fontWeight={600} fontSize={13}>
                        {user.name === 'Unassigned'
                          ? '(Sin asignar)'
                          : user.name}
                      </Typography>
                      <Typography variant="body2" fontWeight="medium">
                        {user.filecaseCount}
                      </Typography>
                    </Box>

                    <LinearProgress
                      variant="determinate"
                      value={(user.filecaseCount / maxCount) * 100}
                      sx={{ height: 8, borderRadius: 4 }}
                      color="primary"
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

// Monthly Filecases Chart Component with recharts
const MonthlyFilecasesChart = ({ data, start, end }) => {
  if (!data) return <p>No data available for Monthly Filecases Chart.</p>;

  // Determine if we should group by day or month
  let groupByDay = false;
  let showYear = false; // Flag to indicate whether to show the year

  if (start && end) {
    // Calculate date range in days only if start and end are provided
    const startDate = dayjs(start);
    const endDate = dayjs(end);
    const dateRange = endDate.diff(startDate, 'day');
    groupByDay = dateRange <= 31; // Adjust threshold as needed
    showYear = dateRange > 365; // Show year if the range is greater than 1 year
  } else {
    // If start and end are null, always show the year
    showYear = true;
  }

  // Transform data for chart
  let chartData = [];

  if (data && data.filecasesReport) {
    if (groupByDay) {
      chartData = data.filecasesReport.map((item) => ({
        date: dayjs(`${item.year}-${item.month}-${item.day}`).format(
          'DD/MM/YY',
        ),
        count: item.count,
      }));
    } else {
      const months = [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ];

      chartData = data.filecasesReport.map((item) => ({
        month: `${months[parseInt(item.month) - 1] || item.month}${
          showYear ? `/${item.year}` : ''
        }`, // Add year if showYear is true
        count: item.count,
      }));
    }
  }

  const dataKey = groupByDay ? 'date' : 'month';

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Expedientes creados</CardTitle>

        <Box height={256}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey={dataKey} axisLine={false} tickLine={false} />

              <Line
                type="monotone"
                dataKey="count"
                name="Expedientes"
                stroke="#6366f1"
                strokeWidth={2}
                dot={{ r: 0 }}
                activeDot={{ r: 6, fill: '#6366f1' }}
              />

              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
// Categories Stats Component
export const CategoriesStatsComponent = ({ data }) => {
  if (!data) return <p>No data available for Categories Stats.</p>;

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Categorias por expediente</CardTitle>

        <Box display="flex" flexDirection="column" gap={2}>
          {data.filecasesByCategory.map((category) => (
            <Box key={category.category}>
              <Box display="flex" justifyContent="space-between">
                <Typography variant="body1">
                  {category.category || '(Sin expediente)'}
                </Typography>
                <Typography variant="body2" fontWeight="medium">
                  {category.filecasesCount}
                </Typography>
              </Box>

              <LinearProgress
                variant="determinate"
                value={category.filecasesTotalPercentage}
                sx={{
                  height: 20,
                  borderRadius: 0.5,
                  backgroundColor: '#F5F8FA',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'primary.600',
                  },
                }}
              />
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export const StatusByFilecases = ({ data }) => {
  if (!data) return <p>No data available for Status by Filecases.</p>;

  const pieData = data.filecasesByState.map((status) => ({
    name: status.state || '(Sin estado)',
    value: status.filecasesTotalPercentage,
    color: status.color || 'gray',
  }));

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Estados por expediente</CardTitle>

        <Stack alignItems="center" justifyContent="center" mb={3}>
          <Box width={128} height={128} position="relative">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={60}
                  dataKey="value"
                  startAngle={90}
                  endAngle={-270}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}

                  {/* <Tooltip /> */}
                </Pie>
              </PieChart>
            </ResponsiveContainer>

            <Box
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box textAlign="center">
                <Typography variant="h6" fontWeight="bold">
                  {data.filecasesByState?.length}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" gap={1.5}>
            {pieData.map((status) => (
              <Box key={status.name} display="flex" alignItems="center">
                <Box
                  width={12}
                  height={12}
                  borderRadius="50%"
                  mr={1}
                  bgcolor={status.color}
                />
                <Typography variant="body2">{status.name}</Typography>
                <Typography variant="body2" ml="auto">
                  {status.value}%
                </Typography>
              </Box>
            ))}
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

// Tags Stats Component with recharts - Horizontal Bar Chart
export const TagsStatsComponent = ({ data }) => {
  const theme = useTheme();

  if (!data) return <p>No data available for Tags Stats.</p>;

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Etiquetas por expediente</CardTitle>

        <Box height={256}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={data.tagsStats}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={<ReportsFilecasesCustomTooltip />} />
              <Bar
                fill={theme.palette.primary.dark}
                dataKey="count"
                name="Expedientes"
              />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};
const layoutGap = [1, 1, 1, 2];

export const ReportsFilecases = () => {
  const componentRef = useRef();
  const studioId = useStudioId();
  const [filters, setFilters] = useState({
    period: { option: 'all', start: null, end: null },
  });

  const queryFilters = useMemo(
    () =>
      filters
        ? {
            start: filters.period.start,
            end: filters.period.end,
            tags: filters.tags?.map((tag) => tag?.value ?? tag),
            assigned: filters.assigned?.map(
              (assigned) => assigned?.value ?? assigned,
            ),
            entities: filters.entities?.map(
              (entity) => entity?.value ?? entity,
            ),
            category: filters.category?.value,
            state: filters.state?.value,
            side: filters.side?.value,
          }
        : null,
    [filters],
  );

  const { loading, error, data } = useQuery(AllFilecasesDataQuery, {
    variables: {
      studioId: studioId,
      filters: queryFilters,
    },
  });

  const handleFiltersSubmit = (values) => Promise.resolve(setFilters(values));

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  if (error) return <p>Error loading all data: {error.message}</p>;

  return (
    <Page>
      <Stack maxWidth={1200} mx="auto" gap={2}>
        <Stack
          direction={['column', 'row']}
          justifyContent="space-between"
          gap={2}
          alignItems={['initial', 'center']}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <LSStats color="primary" sx={{ fontSize: 30 }} />

            <Typography fontWeight={500} fontSize={18}>
              Estadísticas - Expedientes
            </Typography>
          </Stack>

          <Box display="flex" alignItems="center" gap={2}>
            <FilecaseReportsFilters onSubmit={handleFiltersSubmit} />

            <Button
              variant="outlined"
              size="small"
              startIcon={<CloudDownloadIcon />}
              onClick={() => handlePrint()}
            >
              Descargar
            </Button>
          </Box>
        </Stack>

        {loading ? (
          <CircularProgress />
        ) : (
          <Stack gap={4} ref={componentRef}>
            <Stack
              direction={['column', 'row']}
              gap={layoutGap}
              sx={{
                '@media print': {
                  flexDirection: 'column',
                  padding: 4,
                },
              }}
            >
              <FilecasesStatsSummary data={data} />

              <Stack flex={3} gap={layoutGap}>
                <MonthlyFilecasesChart
                  data={data}
                  start={filters?.period?.start}
                  end={filters?.period?.end}
                />

                <Stack direction={['column', 'row']} gap={layoutGap}>
                  <Box flex={1}>
                    <CategoriesStatsComponent data={data} />
                  </Box>

                  <Box flex={1}>
                    <StatusByFilecases data={data} />
                  </Box>
                </Stack>

                <TagsStatsComponent data={data} />
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Page>
  );
};

export default ReportsFilecases;

import React from 'react';
import { Grid, Select, MenuItem, FormControl, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { DateField } from '..';

export const getPeriodDates = (option, format = null) => {
  const today = dayjs();
  let start, end;

  switch (option) {
    case 'this-month':
      start = today.startOf('month');
      end = today.endOf('month');
      break;
    case 'last-month':
      start = today.subtract(1, 'month').startOf('month');
      end = today.subtract(1, 'month').endOf('month');
      break;
    case '3':
      start = today.subtract(3, 'month').startOf('month');
      end = today;
      break;
    case '6':
      start = today.subtract(6, 'month').startOf('month');
      end = today;
      break;
    case '12':
      start = today.subtract(12, 'month').startOf('month');
      end = today;
      break;
    case '24':
      start = today.subtract(24, 'month').startOf('month');
      end = today;
      break;
    case 'all':
      start = undefined;
      end = undefined;
      break;
    case 'custom':
    default:
      break;
  }

  return !format
    ? { start, end }
    : { start: start?.format(format), end: end?.format(format) };
};

const PeriodField = ({
  label,
  gridProps = { xs: 12 },
  dateFormat,
  name,
  onChange,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  // Calculate path notation for the field
  const startName = `${name}.start`;
  const endName = `${name}.end`;

  const handlePeriodChange = (event) => {
    const option = event.target.value;
    let { start, end } = getPeriodDates(option, dateFormat);

    setFieldValue(name, { option, start, end });

    if (onChange) {
      onChange({ option, start, end });
    }
  };

  return (
    <>
      <Grid item {...gridProps} sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControl sx={{ width: '100%' }}>
          {label && (
            <Typography sx={{ mb: 1 }} variant="label">
              {label}
            </Typography>
          )}

          <Select
            {...field}
            {...rest}
            value={field.value.option}
            onChange={handlePeriodChange}
            error={Boolean(meta.touched && meta.error)}
            size="small"
          >
            <MenuItem value="this-month">este mes</MenuItem>
            <MenuItem value="last-month">Mes anterior</MenuItem>
            <MenuItem value="3">ultimos 3 meses</MenuItem>
            <MenuItem value="6">ultimos 6 meses</MenuItem>
            <MenuItem value="12">ultimos 12 meses</MenuItem>
            <MenuItem value="24">ultimos 24 meses</MenuItem>
            <MenuItem value="custom">Personalizado</MenuItem>
            <MenuItem value="all">Todo</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {field.value.option === 'custom' && (
        <>
          <DateField
            label="Desde"
            name={startName}
            size="small"
            dateFormat={dateFormat}
            gridProps={{ sx: { maxWidth: 160, my: 'auto' } }}
            views={['year', 'month']}
            maxDate={dayjs().year(2040).month(0).startOf('month')}
            minDate={dayjs().year(2016).month(0).startOf('month')}
          />

          <DateField
            label="Hasta"
            name={endName}
            size="small"
            dateFormat={dateFormat}
            views={['year', 'month']}
            maxDate={dayjs().year(2040).month(0).startOf('month')}
            minDate={dayjs().year(2016).month(0).startOf('month')}
            gridProps={{ sx: { maxWidth: 160, my: 'auto' } }}
          />
        </>
      )}
    </>
  );
};

export default PeriodField;

import React, { useMemo, useRef } from 'react';
import { Formik } from 'formik';
import * as Sentry from '@sentry/react';
import {
  DialogContent,
  DialogActions,
  Button,
  Chip,
  Grid,
  Box,
  Typography,
  DialogTitle,
  IconButton,
} from '@mui/material';

import Form, { TextField } from 'src/components/ui-components/Form';
import { LoadingButton } from '@mui/lab';
import {
  keyWordsBackgrounds,
  specialKeyWords,
} from 'src/utils/notificationTemplates';

import * as Yup from 'yup';
import { LSX } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { useDialog } from '../Dialogs';
import { useMutation } from '@apollo/client';
import {
  CreateNotificationTemplateMutation,
  DeleteNotificationTemplateMutation,
  UpdateNotificationTemplateMutation,
} from './gql';
import { getNotificationTemplatesQuery } from 'src/tables/TemplateMessageTable/queries';

const groupBy = (array, key) =>
  array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue,
    );
    return result;
  }, {});

const groupedKeyWords = groupBy(specialKeyWords, 'model');

const NotificationTemplateFormSchemaValidation = Yup.object({
  name: Yup.string().required('Este campo es requerido.'),
  body: Yup.string().required('Este campo es requerido.'),
});

export const NotificationTemplateForm = ({ onClose, ...props }) => {
  const inputRef = useRef(null);
  const studioId = useStudioId();
  const { openSnackbar } = useSnackbar();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteNotificationTemplate] = useMutation(
    DeleteNotificationTemplateMutation,
  );

  const [createNotificationTemplate] = useMutation(
    CreateNotificationTemplateMutation,
    {
      refetchQueries: [getNotificationTemplatesQuery],
    },
  );

  const handleDeleteNotificationTemplate = async () => {
    try {
      await deleteNotificationTemplate({
        variables: {
          id: props.id,
          studioId,
        },
        update: (cache, { data }) => {
          cache.evict({
            id: cache.identify(data.deleteNotificationTemplate),
          });

          cache.gc();
        },
      });
      onClose();

      openSnackbar('Se ha eliminado correctamente.', {
        severity: 'success',
        placementType: 'bottom-right',
      });
    } catch (error) {
      openSnackbar('Hubo un error, intente mas tarde', {
        severity: 'error',
      });
      Sentry.captureException(error);
    }
  };

  const [updateNotificationTemplate] = useMutation(
    UpdateNotificationTemplateMutation,
    {
      refetchQueries: [getNotificationTemplatesQuery],
    },
  );

  const handleSubmit = async (values, formikBag) => {
    const isEdit = !!props.id;
    const mutation = isEdit
      ? updateNotificationTemplate
      : createNotificationTemplate;

    const query = {
      variables: {
        studioId,
        name: values.name,
        body: values.body,
      },
    };

    if (isEdit) {
      query.variables.id = props.id;
    }

    try {
      await mutation(query);

      onClose();
      formikBag.resetForm();
      openSnackbar(
        isEdit ? 'Plantilla editada con exito.' : 'Plantilla creada con exito.',
        {
          severity: 'success',
        },
      );
    } catch (error) {
      openSnackbar(error.message ?? 'Hubo un error, intente mas tarde.', {
        severity: 'error',
      });
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  // TODO: Refactor, too much
  const initialValues = useMemo(
    () => ({
      id: props.id,
      name: props.name || '',
      body: props.body || 'Hola [persona_nombre] 😁💵📂🔥',
    }),
    [props.body, props.id, props.name],
  );

  return (
    <Formik
      validationSchema={NotificationTemplateFormSchemaValidation}
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {({ values, isSubmitting, setFieldValue, handleSubmit }) => (
        <>
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            Formulario plantilla
            <IconButton onClick={onClose}>
              <LSX />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <Form>
              <TextField
                label="Nombre de la plantilla"
                name="name"
                placeholder="Nombre..."
              />

              <TextField
                multiline
                label="Cuerpo de la plantilla"
                name="body"
                placeholder="Descripción"
                ref={inputRef}
                rows={6}
              />

              <Grid item>
                <Typography sx={{ mb: 2 }} variant="subtitle2">
                  Utiliza campos dinámicos que serán rellenados en el momento de
                  utilizar esta plantilla:
                </Typography>

                <Box sx={{ gap: 3, display: 'flex', flexWrap: 'wrap' }}>
                  {Object.entries(groupedKeyWords).map(([key, group]) => (
                    <Box
                      key={key}
                      sx={{ gap: 1, display: 'flex', flexWrap: 'wrap' }}
                    >
                      {group.map((keyWord) => (
                        <Chip
                          key={keyWord.key}
                          label={keyWord.key}
                          size="small"
                          sx={{
                            backgroundColor:
                              key === 'event'
                                ? keyWordsBackgrounds[
                                    keyWord.alert ? 'alertEvent' : 'taskEvent'
                                  ]
                                : keyWordsBackgrounds[key],
                          }}
                          onClick={() => {
                            const { selectionStart, selectionEnd } =
                              inputRef.current;

                            setFieldValue(
                              'body',
                              values.body.slice(0, selectionStart) +
                                keyWord.key +
                                values.body.slice(selectionEnd),
                            );

                            inputRef.current.focus();

                            setTimeout(() => {
                              inputRef.current.setSelectionRange(
                                selectionStart + keyWord.key.length,
                                selectionStart + keyWord.key.length,
                              );
                            }, 0);
                          }}
                        />
                      ))}
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Form>
          </DialogContent>

          <DialogActions sx={{ mt: 2 }}>
            <Box
              sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
            >
              {values.id && (
                <Button
                  color="error"
                  disabled={isSubmitting}
                  sx={{ mr: 'auto' }}
                  variant="contained"
                  onClick={() =>
                    dispatchWarningPromptDialog(props.id, {
                      onAccept: handleDeleteNotificationTemplate,
                    })
                  }
                >
                  Eliminar plantilla
                </Button>
              )}

              <LoadingButton
                disabled={isSubmitting}
                loading={isSubmitting}
                sx={{ px: 4 }}
                type="button"
                variant="contained"
                onClick={handleSubmit}
              >
                {values.id ? 'Editar plantilla' : 'Crear plantilla'}
              </LoadingButton>
            </Box>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

import React from 'react';
import { Card } from '@mui/material';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

// Overridable components from v3 table - MIT DataGrid
import LoadingOverlay from './LoadingOverlay';
import NoRowsOverlay from './NoRowsOverlay';

// Table v3 - DataGrid needed constants
import { ROWS_PER_PAGE_OPTIONS } from './constants';
import { useBreakpoints } from 'src/utils/hooks/common';

export const defaultPageSize = ROWS_PER_PAGE_OPTIONS[1];

const Table = ({
  // pageSize = ROWS_PER_PAGE_OPTIONS[0],
  rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS,
  autoHeight = true,
  keepNonExistentRowsSelected = true,
  checkboxSelection = true,
  disableRowSelectionOnClick = true,
  // Required because MIT DataGrid doesn't allow to turn off pagination
  rowCount,
  // If paginationMode = 'client' row count is not required
  paginationMode = 'server',
  // Sorting mode by default on server, be careful!
  sortingMode = 'server',
  onSortModelChange,
  // Do not turn this off
  pagination = true,
  // Overridable components
  components = {},
  sx = {},
  componentsProps = {},
  sxCard = {},
  ...props
}) => {
  const { isMobile } = useBreakpoints();

  if (!pagination) {
    throw new Error(
      'Current version of MIT DataGrid does not support pagination = false',
    );
  }

  if (
    (rowCount === null || rowCount === undefined) &&
    paginationMode === 'server'
  ) {
    console.error(
      'Pagination mode is set to server, but rowCount is not provided',
    );
    throw new Error('Table v3 - DataGrid: rowCount is required');
  }

  // TODO: UNCOMMENT WHEN TABLES ARE STABLE WITH SERVER SORTING MODE
  // if (sortingMode === 'server' && !onSortModelChange) {
  //   throw new Error(
  //     'Table v3 - DataGrid: onSortModelChange is required when sortingMode = server (probably)',
  //   );
  // }

  return (
    <Card sx={sxCard}>
      <DataGrid
        disableColumnSelector
        autoHeight={autoHeight}
        checkboxSelection={checkboxSelection}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        experimentalFeatures={props.experimentalFeatures}
        keepNonExistentRowsSelected={keepNonExistentRowsSelected}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        pageSizeOptions={rowsPerPageOptions}
        pagination={pagination}
        paginationMode={paginationMode}
        rowCount={rowCount}
        slotProps={componentsProps}
        slots={{
          loadingOverlay: LoadingOverlay,
          noResultsOverlay: NoRowsOverlay,
          noRowsOverlay: NoRowsOverlay,
          ...components,
        }}
        sortingMode={sortingMode}
        sx={{
          // TODO: Move to theme later
          '& .MuiDataGrid-columnHeader': {
            '&:focus': {
              outline: 'solid transparent 1px',
            },
          },

          '& .MuiDataGrid-row': {
            '&:hover': {
              backgroundColor: 'primary.100',
            },
          },

          '& .MuiDataGrid-columnHeaderTitleContainer': {
            '&:focus-within': {
              outline: 'none',
            },
          },

          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid transparent 1px',

            display: 'flex',
            alignItems: 'center',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
          [`& .${gridClasses.row}`]: {
            '& .actions': {
              opacity: isMobile ? 1 : 0,
              transition: 'opacity 50ms ease-in-out',
            },
            '&:hover, &.Mui-hovered': {
              '.actionButton, .actions': {
                opacity: 1,
              },
            },
          },
          ...sx,
        }}
        onSortModelChange={onSortModelChange}
        {...props}
      />
    </Card>
  );
};

export { LoadingOverlay, NoRowsOverlay, ROWS_PER_PAGE_OPTIONS };
export default Table;

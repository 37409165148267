import React from 'react';
import { useQuery } from '@apollo/client';

import { SelectField } from 'src/components/ui-components/Form';
import { GET_CATEGORIES } from 'src/graphql/queries/category';
import { useStudioId } from 'src/utils/hooks/useStudioId';

export const useFilecaseCategories = (options = {}) => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(GET_CATEGORIES, {
    ...options,
    fetchPolicy: 'cache-and-network',
    variables: {
      studio: studioId,
    },
  });

  return { categories: data?.categories || [], loading };
};

const CategoriesAutocomplete = ({
  disabled,
  label = 'Categorias',
  placeholder = 'Ej. Comercial',
  ...rest
}) => {
  const studioId = useStudioId();
  const { data, loading } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-first',
    variables: {
      studio: studioId,
    },
  });
  const options =
    data?.categories?.map((val) => ({ value: val.name, label: val.name })) ||
    [];

  return (
    <SelectField
      disabled={disabled}
      label={label}
      loading={loading}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CategoriesAutocomplete;

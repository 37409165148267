import React, { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';

import Table, { defaultPageSize } from 'src/components/v3/Table';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import Header, { useTimeTrackingFilters } from './Header';
import { useTimeTrackingToExport } from './excel';
import { useTimeTrackingColumns } from './columns';
import { TimeTrackingQuery } from './queries';
import Page from 'src/components/v2/Page';

const TimeTrackingTable = () => {
  const columns = useTimeTrackingColumns();
  const [selectedItems, setSelectedItems] = useState([]);
  const { filters } = useTimeTrackingFilters();
  const [pagination, setPagination] = useState({
    page: 0,
    limit: defaultPageSize,
  });

  const studioId = useStudioId();

  const queryVariables = useMemo(() => {
    return {
      studio: studioId,
      filecases: filters.filecases?.map((val) => val?.value ?? val),
      assigned: filters.assigned?.map((val) => val?.value ?? val),
      categories: filters.categories?.map((val) => val?.value ?? val),
      date: filters.period?.start || filters.period?.end
        ? {
            start: filters.period.start,
            end: filters.period.end,
          }
        : undefined,
      limit: pagination.limit || defaultPageSize,
      offset: pagination.page * pagination.limit,
      orderBy: pagination.orderBy,
      sortOrder: pagination.sortOrder,
    };
  }, [filters, pagination, studioId]);

  const {
    data: { paginatedHours: hoursTracking } = { paginatedHours: {} },
    loading,
    refetch,
    variables,
    previousData,
  } = useQuery(TimeTrackingQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: false,
    onCompleted: () => setSelectedItems([]),
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !studioId,
  });

  const { getTimeTrackingToExport } = useTimeTrackingToExport();

  const handlePaginationModelChange = ({ pageSize, page }) =>
    setPagination({ page, limit: pageSize });

  const handleDownloadAsExcel = () =>
    getTimeTrackingToExport({ ...variables, ids: selectedItems });

  const rowCount =
    hoursTracking?.count ?? previousData?.paginatedHours?.count ?? 0;

  return (
    <Page title="Time Tracking | LegalSurf">
      <Stack gap={1}>
        <Header
          loading={loading}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          subtitle={`Total ${rowCount}`}
          title="Time Tracking"
          totalItems={rowCount}
          onDownloadAsExcel={handleDownloadAsExcel}
        />

        <Table
          columns={columns}
          componentsProps={{
            noResultsOverlay: {
              variant: 'time-tracking',
            },
            noRowsOverlay: {
              variant: 'time-tracking',
            },
          }}
          loading={loading}
          paginationModel={{
            page: pagination.page,
            pageSize: pagination.limit,
          }}
          rowCount={rowCount}
          rows={hoursTracking?.data ?? []}
          onPaginationModelChange={handlePaginationModelChange}
          onRowSelectionModelChange={(newSelectionModel) =>
            setSelectedItems(newSelectionModel)
          }
          onSortModelChange={([column]) => {
            refetch({
              ...variables,
              ...(column
                ? {
                    orderBy: column.field,
                    sortOrder: column.sort,
                  }
                : {
                    orderBy: null,
                    sortOrder: null,
                  }),
            });
          }}
        />
      </Stack>
    </Page>
  );
};

export default TimeTrackingTable;

import React from 'react';
import { Box, Avatar, Typography, Tooltip, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { humanizeHoursTrackingTime } from 'src/components/v2/FilecaseDetail/HoursTrackingSection/utils';
import { useDialog } from 'src/dialogs/Dialogs';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { RichEditor } from 'src/components/v3/RichEditor';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { LSEdit, LSTrash } from 'src/components/icons';
import { gql, useMutation } from '@apollo/client';
import { hoursTrackingByCategoryQuery } from 'src/components/v2/FilecaseDetail/HoursTrackingSection';
import { generateColorFromText } from 'src/utils/colors';
import { CommentTooltip } from 'src/components/v3/Comment/CommentTooltip';
import Comment from 'src/components/v3/Comment';

export const deleteHourMutation = gql`
  mutation deleteHourMutation($id: ID!) {
    deleteHourTracking(id: $id) {
      id
    }
  }
`;

export const useTimeTrackingColumns = ({ compact = false } = {}) => {
  const dispatchFilecaseDialog = useDialog('filecase');
  const dispatchHoursTrackingDialog = useDialog('timeTrackingForm');
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteHourTracking] = useMutation(deleteHourMutation);
  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.FILECASES.ACCESS,
  ]);

  const handleRemove = (row) =>
    dispatchWarningPromptDialog(`WarningPrompt-${row.id}`, {
      onAccept: () =>
        deleteHourTracking({
          variables: {
            id: row.id,
          },
          refetchQueries: [
            {
              query: hoursTrackingByCategoryQuery,
              variables: {
                filters: {
                  filecases: [row.filecase.id],
                },
              },
            },
          ],
          update: (cache) => {
            const normalizedId = cache.identify({
              id: row.id,
              __typename: 'Hour',
            });

            cache.evict({ id: normalizedId });
            cache.gc();
          },
        }),
      title: 'Eliminar hora cargada',
    });

  return [
    {
      disableColumnMenu: true,
      sortable: false,
      headerName: 'Asignado',
      field: 'assignedTo',
      width: 90,
      renderCell: ({ row }) => (
        <Tooltip key={row.assignedTo.id} title={row.assignedTo.name}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              m: 'auto',
              gap: 1,
            }}
          >
            <Avatar
              alt="Responsable"
              size="small"
              src={row.assignedTo.picture}
              sx={{ margin: 1, width: 30, height: 30 }}
            />
          </Box>
        </Tooltip>
      ),
    },
    {
      disableColumnMenu: true,
      headerName: 'Horas',
      field: 'amount',
      width: 70,
      renderCell: ({ row }) => humanizeHoursTrackingTime(row.amount),
    },
    {
      disableColumnMenu: true,
      sortable: false,
      flex: 1,
      headerName: 'Categoría',
      field: 'category',
      renderCell: (props) =>
        props.row?.category?.name ? (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                bgcolor: generateColorFromText(props.row.category.name),
                borderRadius: '50%',
              }}
            />

            <Typography fontWeight={500}>{props.row.category.name}</Typography>
          </Stack>
        ) : null,
    },
    compact
      ? null
      : {
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          headerName: 'Concepto',
          field: 'concept',
          renderCell: ({ row }) => (
            <CommentTooltip
              placement="left"
              title={
                <Comment
                  readOnly
                  comment={{
                    createdBy: row.createdBy,
                    content: row.concept,
                    updatedAt: row.updatedAt,
                    createdAt: row.createdAt,
                  }}
                  key={row.id}
                />
              }
            >
              <Box>
                <RichEditor
                  disabled
                  initialValue={row.concept}
                  placeholder=""
                  sx={{
                    maxHeight: 52,
                    width: '100%',
                    whiteSpace: 'normal',
                    wordBreak: 'break-word',
                    textAlign: 'left',
                  }}
                />
              </Box>
            </CommentTooltip>
          ),
        },

    {
      disableColumnMenu: true,
      headerName: 'Fecha',
      field: 'date',
      width: 100,
      renderCell: ({ row }) =>
        row.date ? (
          <Box sx={{ flex: 1 }}>
            <Tooltip
              placement="left"
              title={dayjs(row.date).format('LLL')}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                }}
              >
                {dayjs(row.date).format('L')}
              </Box>
            </Tooltip>
          </Box>
        ) : null,
    },
    compact
      ? null
      : {
          disableColumnMenu: true,
          sortable: false,
          flex: 1,
          headerName: 'Expediente',
          field: 'filecase',
          maxWidth: '100%',
          renderCell: ({ row }) => (
            <Box
              sx={{
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                role="button"
                sx={{
                  cursor: hasPermissions ? 'pointer' : 'initial',
                  fontWeight: '500',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  ...(hasPermissions && {
                    '&:hover': {
                      color: 'primary.main',
                    },
                  }),
                }}
                onClick={() => {
                  if (hasPermissions) {
                    dispatchFilecaseDialog(row.filecase.id, {
                      id: row.filecase.id,
                    });
                  }
                }}
              >
                {row.filecase.title}

                {hasPermissions && <OpenInNewIcon />}
              </Typography>
            </Box>
          ),
        },
    {
      field: 'actions',
      type: 'actions',
      width: 70,
      cellClassName: 'actions',
      getActions: ({ row }) => [
        <GridActionsCellItem
          key={row.id}
          icon={<LSEdit color="primary" />}
          label="Editar"
          onClick={() =>
            dispatchHoursTrackingDialog(`TimeTrackingForm-${row.id}`, {
              hourTracking: row,
            })
          }
        />,
        <GridActionsCellItem
          key={row.id}
          icon={<LSTrash color="error" />}
          label="Eliminar"
          onClick={() => handleRemove(row)}
        />,
      ],
    },
  ].filter(Boolean);
};

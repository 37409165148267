import React from 'react';
import { Box, Select, MenuItem, Button } from '@mui/material';
import { DateField, PeriodField } from 'src/components/ui-components/Form';
import { Formik } from 'formik';
import { FormikListener } from 'src/components/v3/SectionHeader';
import dayjs from 'dayjs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import {
  AssigneesAutocomplete,
  CategoriesAutocomplete,
  EntitiesAutocomplete,
  SidesAutocomplete,
  StatusAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';

const initialValues = {
  period: {
    option: 'all',
    start: null,
    end: null,
  },
  side: null,
  state: null,
  category: null,
  entities: [],
  tags: [],
  assigned: [],
};

export const FilecaseReportsFilters = ({ onSubmit }) => {
  const handleFormChange = (current, prev) => {
    if (current.period.option === 'custom' && prev.period.option !== 'custom') {
      return;
    }

    if (
      (!current.period.start || !current.period.end) &&
      current.period.option === 'custom'
    ) {
      return;
    }

    onSubmit({
      period: current.period,
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit }) => (
        <Box display="flex" gap={2} alignItems="center">
          <FormikListener callback={handleFormChange} />

          <PeriodField name="period" />

          {values.period.option === 'custom' && (
            <Button variant="contained" size="small" onClick={handleSubmit}>
              Aplicar rango
            </Button>
          )}

          <SectionHeaderAdvanceFilters
            onReset={(formikBag) => formikBag.resetForm()}
            buttonProps={{ variant: 'contained', size: 'small' }}
          >
            <StatusAutocomplete label="Estado" name="state" size="small" />

            <CategoriesAutocomplete
              label="Categoría"
              name="category"
              size="small"
            />

            <SidesAutocomplete
              label="Parte"
              name="side"
              size="small"
            />

            <EntitiesAutocomplete
              multiple
              label="Directorios"
              name="entities"
              size="small"
            />

            <TagsAutocomplete
              multiple
              label="Etiquetas"
              name="tags"
              size="small"
            />

            <AssigneesAutocomplete
              multiple
              label="Asignados"
              name="assigned"
              placeholder="Ej: Nicolas Salazar"
              size="small"
            />
          </SectionHeaderAdvanceFilters>
        </Box>
      )}
    </Formik>
  );
};

import { useMutation, MutationHookOptions } from '@apollo/client';
import { DeleteCalendarEventQuery } from '@legalsurf/queries';

import { EventVariables } from '../@types';

export const useDeleteCalendarEvent = (
  options: MutationHookOptions<any, EventVariables>,
) =>
  useMutation(DeleteCalendarEventQuery, {
    update: (cache, data, options) => {
      const normalizedId = cache.identify({
        id: options.variables.id,
        calendarId: options.variables.calendarId,
        __typename: 'Event',
      });

      cache.evict({ id: normalizedId });
    },
    ...options,
  });

import React, { useRef, useState } from 'react';
import {
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
  Chip,
  Tooltip,
} from '@mui/material';

import { LSCalendar } from 'src/components/icons';
import LSClock from 'src/components/icons/LSClock';
import { calculateWorkflowTotalDuration } from 'src/pages/Dashboard/Settings/WorkflowCreate/utils';
import { CheckListPopover } from 'src/popovers/CheckListPopover';
import { LinearProgressWithLabel } from 'src/components/v3/LinearProgressWithLabel';
import { Pressable } from 'src/components/ui-components/Pressable';

export const WorkflowTimelineItemEvent = ({ step, onClick, onUpdate }) => {
  const { title, calendar } = step.data;
  const [open, setOpen] = useState(false);
  const popoverRef = useRef(null);
  const [todos, setTodos] = useState(() => step?.data?.todos ?? []);

  const porcetange = todos.length
    ? Math.floor(
        (todos.filter((todo) => todo?.done).length / todos.length) * 100,
      )
    : null;

  const porcentageTitle =
    porcetange === null
      ? 'Click acá para crear una lista de subtareas'
      : `${porcetange}% completado`;

  const handleUpdate = (todo, index) => {
    const _todos = [...todos];
    _todos[index] = { ...todo, __typename: 'EventTodo' };

    setTodos(_todos);
    onUpdate({ ...step, data: { ...step?.data, todos: _todos } });
  };

  const handleCreate = () => {
    const _todos = [
      ...todos,
      {
        __typename: 'EventTodo',
        id: Math.random().toString(),
        title: '',
        done: false,
      },
    ];

    setTodos(_todos);
    onUpdate({ ...step, data: { ...step?.data, todos: _todos } });
  };

  const handleDelete = (todo, index) => {
    const _todos = [
      ...todos.slice(0, index),
      ...todos.slice(index, todos.length - 1),
    ];

    setTodos(_todos);
    onUpdate({ ...step, data: { ...step?.data, todos: _todos } });
  };

  return (
    <>
      <Card onClick={onClick}>
        <CardContent sx={{ p: 2, pb: '12px !important', cursor: 'pointer' }}>
          <Stack direction="column" justifyContent="space-between" spacing={2}>
            <Box>
              <Typography
                sx={(theme) => ({
                  color: theme.palette.computeContrast(calendar.color),
                  backgroundColor: calendar.color,
                  display: 'inline-flex',
                  gap: 0.5,
                  alignItems: 'center',
                  borderRadius: 0.4,
                  p: 1,
                  py: 0.5,
                })}
                variant="body2"
              >
                <LSCalendar sx={{ width: 14 }} />

                {calendar.name ?? calendar.label}
              </Typography>
            </Box>

            <Stack direction="row">
              <Typography
                sx={{
                  borderRadius: 0.4,
                  fontSize: 12,
                }}
                variant="body1"
              >
                {title}
              </Typography>
            </Stack>

            <Stack direction="column" gap={1}>
              <Stack alignItems="center" direction="row" gap={1}>
                <Typography fontWeight={600} variant="body2">
                  Duración:
                </Typography>

                <Chip
                  label={
                    <Stack alignItems="center" direction="row" gap={0.5}>
                      <LSClock color="primary" sx={{ width: 16 }} />

                      <Box>{calculateWorkflowTotalDuration([step])}</Box>
                    </Stack>
                  }
                  size="small"
                  sx={{
                    color: 'primary.dark',
                    backgroundColor: 'primary.100',
                    fontWeight: '400',
                    borderRadius: 0.5,
                  }}
                />
              </Stack>

              <Pressable
                ref={popoverRef}
                sx={{
                  p: 0.5,
                  px: 1,
                  borderRadius: 1,
                  '&:hover': {
                    bgcolor: 'background.input',
                  },
                }}
                onClick={(e) => {
                  e?.stopPropagation();
                  setOpen(true);
                }}
              >
                <Stack gap={1}>
                  <Tooltip title={porcentageTitle}>
                    <Box>
                      <LinearProgressWithLabel
                        color={porcetange === 100 ? 'success' : 'primary'}
                        linearProgressSx={{
                          borderRadius: 3,
                          height: 10,
                          '& .MuiLinearProgress-bar': {
                            borderRadius: 3,
                          },
                        }}
                        value={porcetange}
                      />
                    </Box>
                  </Tooltip>

                  {!!step?.data?.todos?.length && (
                    <Stack
                      sx={{
                        bgcolor: 'background.input',
                        borderRadius: 0.5,
                        p: 1,
                        gap: 0.5,
                      }}
                    >
                      <Typography fontSize={12} sx={{ pl: 0.5 }}>
                        Lista de tareas
                      </Typography>

                      <Box>
                        {step?.data?.todos?.map((todo) => (
                          <Box key={todo.id + todo.title}>
                            <Typography variant="body2">
                              ・ {todo.title}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Stack>
                  )}
                </Stack>
              </Pressable>
            </Stack>
          </Stack>
        </CardContent>
      </Card>

      <CheckListPopover
        anchorEl={popoverRef.current}
        open={open}
        todos={todos}
        onClose={() => setOpen(false)}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
      />
    </>
  );
};

import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Stack } from '@mui/material';

import Table from 'src/components/v3/Table';
import { SalesQuery } from 'src/pages/Dashboard/Sales/SalesQueries';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useDialog } from 'src/dialogs/Dialogs';
import Header, { useSalesFilters } from './Header';
import { useSalesToExport } from './excel';
import { useSalesColumns } from './columns';

const SalesTable = () => {
  const columns = useSalesColumns();
  const [selectedItems, setSelectedItems] = useState([]);
  const { filters } = useSalesFilters();
  const dispatchSaleDialog = useDialog('sale');

  const studioId = useStudioId();

  const queryVariables = {
    studio: studioId,
    entities: filters.entities?.map((val) => val?.value ?? val),
    filecases: filters.filecases?.map((val) => val?.value ?? val),
    responsibles: filters.responsibles?.map((val) => val?.value ?? val),
    currencies: filters.currencies?.map((val) => val?.value ?? val),
    tags: filters.tags?.map((val) => val?.value ?? val),
  };

  const {
    data: { sales } = { sales: {} },
    loading,
    refetch,
    variables,
  } = useQuery(SalesQuery, {
    notifyOnNetworkStatusChange: true,
    partialRefetch: false,
    onCompleted: () => setSelectedItems([]),
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !studioId,
  });

  const { getSalesToExport } = useSalesToExport();

  const handleDownloadAsExcel = () =>
    getSalesToExport({ ...variables, ids: selectedItems });

  return (
    <Stack gap={1}>
      <Header
        loading={loading}
        selectedItems={selectedItems}
        subtitle={`Totales ${sales?.length || '0'}`}
        title="Honorarios"
        totalItems={sales?.length ?? 0}
        onDownloasdAsExcel={handleDownloadAsExcel}
      />

      <Table
        columns={columns}
        componentsProps={{
          noResultsOverlay: {
            variant: 'sales',
          },
          noRowsOverlay: {
            variant: 'sales',
          },
        }}
        loading={loading}
        paginationMode="client"
        rows={sales ?? []}
        rowsPerPageOptions={[]}
        rowSelectionModel={selectedItems}
        onRowClick={(params) =>
          dispatchSaleDialog(params.row.id, { id: params.row.id })
        }
        onRowSelectionModelChange={(newSelectionModel) =>
          setSelectedItems(newSelectionModel)
        }
        onSortModelChange={([column]) => {
          refetch({
            ...variables,
            ...(column
              ? {
                  orderBy: column.field,
                  sortOrder: column.sort,
                }
              : {
                  orderBy: null,
                  sortOrder: null,
                }),
          });
        }}
      />
    </Stack>
  );
};

export default SalesTable;

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { SelectField } from 'src/components/ui-components/Form';

const GetCategoryHours = gql`
  query GetCategoryHours {
    getCategoryHours {
      name
      studioId
    }
  }
`;

const defaultCategoryOptions = [
  { name: 'Atención al Cliente' },
  { name: 'Redacción y Documentación' },
  { name: 'Investigación y Análisis Jurídico' },
  { name: 'Gestión de Casos y Litigios' },
];

const useCategoryHours = () => {
  const { loading, data } = useQuery(GetCategoryHours, {
    fetchPolicy: 'cache-first',
  });

  return {
    loading,
    categoryHours: [
      ...defaultCategoryOptions,
      ...(data?.getCategoryHours?.filter(
        (option) =>
          !defaultCategoryOptions.some(
            (defaultOption) => defaultOption.name === option.name,
          ),
      ) || []),
    ],
  };
};

const CategoryHoursAutocomplete = ({
  disabled,
  label = 'Categoría',
  placeholder = '',
  name,
  ...rest
}) => {
  const { loading, categoryHours } = useCategoryHours();

  const options = categoryHours.map((category) => ({
    value: category.name,
    label: category.name,
  }));

  return (
    <SelectField
      creatable
      disabled={disabled || loading}
      label={label}
      loading={loading}
      name={name}
      options={options}
      placeholder={placeholder}
      {...rest}
    />
  );
};

export default CategoryHoursAutocomplete;

import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';

import { LSCalendar } from 'src/components/icons';

const DateFieldOpenPickerIcon = (params) => (
  <LSCalendar color="primary" {...params} />
);

const DatePickerTextField = ({
  handleClick,
  disabled,
  error,
  helperText,
  name,
  size,
  ...props
}) => (
  <TextField
    fullWidth
    onClick={handleClick}
    {...props}
    disabled={disabled}
    error={!!error}
    helperText={helperText}
    name={name}
    size={size}
  />
);

export const DatePicker = ({
  open,
  disabled,
  error,
  helperText,
  name,
  size,
  onClick: handleClick,
  onClose: handleClose,
  onBlur: handleBlur,
  onChange,
  ...rest
}) => (
  <MuiDatePicker
    {...rest}
    inputFormat="DD/MM/YYYY"
    open={open}
    slotProps={{
      textField: {
        handleClick,
        disabled,
        error,
        helperText,
        name,
        size,
        onBlur: handleBlur,
      },
      yearButton: {
        sx: {
          fontSize: 13,
          fontWeight: 500,
          color: 'black',
        },
      },
      monthButton: {
        sx: {
          fontSize: 13,
          fontWeight: 500,
          color: 'black',
        },
      },
    }}
    slots={{
      openPickerIcon: DateFieldOpenPickerIcon,
      textField: DatePickerTextField,
    }}
    onChange={onChange}
    onClose={handleClose}
  />
);

import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { TabPanel, useTabContext } from '@mui/lab';
import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';

import { useTags } from 'src/autocompletes/TagsAutocomplete';
import { LSEdit, LSTrash } from 'src/components/icons';
import Table from 'src/components/v3/Table';
import { useDialog } from 'src/dialogs/Dialogs';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useSnackbar } from 'src/components/v3/Snackbar';
import { TagPopover } from 'src/popovers/TagPopover';
import { OrganizersTabToolbar } from './OrganizerTabToolbar';

const DeleteTagMutation = gql`
  mutation DeleteTagMutation($name: ID!, $studioId: ID!) {
    deleteTag(name: $name, studioId: $studioId) {
      id
      name
      studioId
    }
  }
`;

export const CreateTagMutation = gql`
  mutation CreateTagMutation($name: ID!, $studioId: ID!) {
    createTag(name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

export const UpdateTagMutation = gql`
  mutation UpdateTagMutation($id: ID!, $name: ID!, $studioId: ID!) {
    updateTag(id: $id, name: $name, studioId: $studioId) {
      id
      name
      updatedAt
      studioId
    }
  }
`;

const Actions = ({ row, onEditClick }) => {
  const studioId = useStudioId();
  const dispatchWarningPromptDialog = useDialog('warningPrompt');
  const [deleteTag] = useMutation(DeleteTagMutation, {
    update: (cache, result) => {
      cache.evict(cache.identify(result.data.deleteTag));
      cache.gc();
    },
  });

  return (
    <Box height="100%">
      <Stack
        direction="row"
        gap={0.5}
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <IconButton color="inherit" size="small" onClick={onEditClick}>
          <LSEdit color="primary" fontSize="small" />
        </IconButton>

        <IconButton
          color="inherit"
          label="Delete"
          size="small"
          onClick={() =>
            dispatchWarningPromptDialog(`TagsTab-${row.name}`, {
              onAccept: () =>
                deleteTag({
                  variables: {
                    name: row.name,
                    studioId,
                  },
                }),
              title: 'Eliminar etiqueta',
            })
          }
        >
          <LSTrash color="error" fontSize="small" />
        </IconButton>
      </Stack>
    </Box>
  );
};

export const TagsTab = ({ index }) => {
  const { openSnackbar } = useSnackbar();
  const { value } = useTabContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { tags, loading } = useTags({ skip: value !== index });
  const [selectedTag, setSelectedTag] = useState();

  const handleCloseTagsPopover = () => {
    setAnchorEl(null);
    setSelectedTag(null);
  };

  const columns = [
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      flex: 1,
      headerName: 'Nombre',
      field: 'name',
      editable: false,
    },
    {
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      filterable: false,
      width: 180,
      headerName: 'Ultima actualización',
      field: 'updatedAt',
      editable: false,
      renderCell: ({ row }) => dayjs(row.updatedAt).format('LL'),
    },
    {
      headerName: 'Acciones',
      width: 100,
      editable: false,
      renderCell: (props) => (
        <Actions
          {...props}
          onEditClick={(event) => {
            setAnchorEl(event.currentTarget);
            setSelectedTag(props.row);
          }}
        />
      ),
    },
  ];

  return (
    <TabPanel value={index}>
      <TagPopover
        tag={selectedTag}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseTagsPopover}
        onSubmit={handleCloseTagsPopover}
      />

      <Table
        hideFooter
        checkboxSelection={false}
        columns={columns}
        editMode="row"
        loading={loading}
        pageSize={10}
        rowCount={tags?.length}
        components={{
          toolbar: OrganizersTabToolbar,
        }}
        componentsProps={{
          toolbar: {
            label: 'Crear etiqueta',
            onClick: (event) => setAnchorEl(event.currentTarget),
          },
        }}
        rows={tags}
        sx={{
          '& .MuiDataGrid-cell': {
            position: 'relative',
            outline: 'solid #ccc 0.1px',

            '&:not(.MuiDataGrid-cell--editable)': {
              '&:focus-within': {
                outline: 'none',
              },
            },
          },
        }}
        sxCard={{
          boxShadow: 'none',
        }}
        onProcessRowUpdateError={(error) => {
          openSnackbar(
            error.message.match('Unique constraint failed')
              ? 'Ya existe una etiqueta con ese nombre. Por favor, utiliza otro.'
              : 'Ocurrio un error',
            {
              severity: 'error',
            },
          );
        }}
      />
    </TabPanel>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Stack,
  Card,
  CardContent,
  Divider,
  Typography,
  Button,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { LSAdd as LSAddIcon, LSFilecase } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import EmptyState from 'src/components/v3/EmptyState';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import Table from 'src/components/v3/Table';
import { useCompactColumns } from 'src/tables/FilecasesTable/columns';

import { gql, useQuery } from '@apollo/client';
import { KeyboardArrowRight } from '@mui/icons-material';

export const HomeFilecaseSectionSummaryQuery = gql`
  query HomeFilecaseSectionSummaryQuery(
    $limit: Int
    $studio: ID!
    $orderBy: PaginatedFilecasesSortBy
    $sortOrder: SortOrder
  ) {
    paginatedFilecases(
      limit: $limit
      studio: $studio
      orderBy: $orderBy
      sortOrder: $sortOrder
    ) {
      count
      data {
        id
        title
        externalId
        updatedAt
        description
        stateUpdatedAt
        latestEntry {
          id
          content
          date
          createdAt
          updatedAt
          createdBy {
            id
            picture
            name
          }
        }
        createdAt
        category {
          id
          name
        }
        state {
          id
          color
          name
        }
        tags {
          name
          studioId
        }
        assigned {
          id
          picture
          name
        }
        documents {
          id
        }
        chargedHours {
          id
          date
          amount
        }
      }
    }
  }
`;

const FilecasesSection = () => {
  const dispatchFilecaseFormDialog = useDialog('filecaseForm');
  const columns = useCompactColumns();
  const dispatchFilecaseDialog = useDialog('filecase');
  const navigate = useNavigate();

  const studioId = useStudioId();

  const {
    data: { paginatedFilecases: { data: filecases = [] } = {} } = {},
    loading,
  } = useQuery(HomeFilecaseSectionSummaryQuery, {
    variables: {
      studio: studioId,
      limit: 5,
      orderBy: 'updatedAt',
      sortOrder: 'desc',
    },
  });

  return (
    <Card>
      <CardContent sx={{ pb: 1 }}>
        <Stack direction="row" gap={1}>
          <LSFilecase color="primary" />

          <Typography variant="h6" fontWeight="600">
            Expedientes recientes
          </Typography>

          <Stack direction="row" gap={1} alignItems="center" ml="auto">
            <Button
              color="primary"
              startIcon={<LSAddIcon color="white" />}
              variant="text"
              onClick={() => dispatchFilecaseFormDialog('FilecaseFormDialog')}
            >
              Crear expediente
            </Button>

            <IconButton
              size="small"
              sx={{ bgcolor: 'primary.200' }}
              onClick={() => navigate(`/dashboard/${studioId}/filecases`)}
            >
              <KeyboardArrowRight color="primary" />
            </IconButton>
          </Stack>
        </Stack>
      </CardContent>

      <Divider />

      <CardContent>
        {loading && (
          <Stack alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Stack>
        )}

        {!loading && !filecases.length && (
          <EmptyState color="primary">
            <Typography color="primary">
              Aún no cuentas con ningún expediente creado. Comienza a trabajar
              ahora mismo.
            </Typography>

            <Box>
              <Button
                color="primary"
                startIcon={<LSAddIcon color="white" />}
                variant="contained"
                onClick={() => dispatchFilecaseFormDialog('FilecaseFormDialog')}
              >
                Crear expediente
              </Button>
            </Box>
          </EmptyState>
        )}

        {!loading && filecases.length > 0 && (
          <Stack
            direction="column"
            gap={2}
            sx={{
              '& .MuiDataGrid-footerContainer': {
                display: 'none',
              },
            }}
          >
            <Table
              checkboxSelection={false}
              columns={columns}
              loading={loading}
              rowCount={filecases?.length}
              rows={filecases}
              sxCard={{
                boxShadow: 'none',
              }}
              onRowClick={(params) =>
                dispatchFilecaseDialog(params.row.id, { id: params.row.id })
              }
            />

            {/* <Stack direction="row" gap={2} justifyContent="flex-end">
              <Button
                color="primary"
                variant="text"
                onClick={() => navigate(`/dashboard/${studioId}/filecases`)}
              >
                Ver expedientes
              </Button>

              
            </Stack> */}
          </Stack>
        )}
      </CardContent>
    </Card>
  );
};

export default FilecasesSection;

import React from 'react';
import {
  Box,
  CircularProgress,
  DialogContent,
  IconButton,
  Tooltip,
  iconButtonClasses,
} from '@mui/material';
import {
  Download as DownloadIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import useDigitalOceanSpace from 'src/utils/hooks/useDigitalOceanSpace';

import FilePreviewerContent from './FilePreviewerContent';

const FilePreviewer = ({ document = {}, downloable, onClose }) => {
  const { handleDownload, isDownloading } = useDigitalOceanSpace();
  const isPdf = document.mimetype === 'application/pdf';

  return (
    <DialogContent sx={{ padding: 0, overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          top: 10,
          right: 10,
          display: 'flex',
          alignItems: 'center',
          gap: 3,
          zIndex: 10,
        }}
      >
        {downloable && !isPdf && (
          <Tooltip title="Descargar documento">
            <IconButton
              disabled={isDownloading}
              sx={{
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',

                [`&.${iconButtonClasses.disabled}`]: {
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                },

                '& svg': {
                  color: 'white',
                },

                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.95)',
                },
              }}
              onClick={() => handleDownload(document?.id)}
            >
              {isDownloading ? (
                <CircularProgress size={24} />
              ) : (
                <DownloadIcon />
              )}
            </IconButton>
          </Tooltip>
        )}

        <Tooltip title="Cerrar previsualización">
          <IconButton
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.95)',
              },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <FilePreviewerContent document={document} />
    </DialogContent>
  );
};

export default FilePreviewer;

import React, { useRef } from 'react';
import {
  Avatar,
  Typography,
  CircularProgress,
  CardContent,
  Card,
  Button,
  CardActions,
  Tooltip,
  Stack,
} from '@mui/material';

import Page from 'src/components/v2/Page';
import { useReactToPrint } from 'react-to-print';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toDateFormat } from 'src/utils/dates';
import { GET_SALE } from '../SalesQueries';
import { stringAvatar } from 'src/utils/formatters';
import { SaleConcept } from 'src/components/v2/SaleDetail/SingleSaleHeader';
import { LSCalendar, LSFilecase } from 'src/components/icons';
import { useDialog } from 'src/dialogs/Dialogs';
import LSClock from 'src/components/icons/LSClock';

export const VoucherSale = () => {
  const { saleId } = useParams();

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    pageStyle: () => `
        .btn-download {
          visibility: hidden
        }

        @media print {
          @page { margin: 0; }
          body { margin: 1.6cm; }
        }  
        `,
  });

  const { data: { sale } = {}, loading } = useQuery(GET_SALE, {
    variables: {
      id: saleId,
    },
  });
  const dispatchEntityDialog = useDialog('entity');
  const dispatchFilecaseDialog = useDialog('filecase');

  if (loading) return <CircularProgress />;

  return (
    <Page sx={{ backgroundColor: '#E6E8F2', minHeight: '100vh' }}>
      <Card
        sx={{
          margin: 'auto',
          maxWidth: 500,
          border: '1px solid #C7C7C7',
          boxShadow: 'none',
          borderRadius: 2,
        }}
      >
        <CardContent ref={componentRef}>
          <Stack gap={2} p={6}>
            <Avatar
              alt={null}
              src={sale.studio.picture}
              style={{
                width: 70,
                height: 70,
                borderRadius: 80,
              }}
            />

            <Typography sx={{ fontWeight: 500, fontSize: 24 }} variant="span">
              {sale.studio.name}
            </Typography>

            <Typography
              sx={{
                backgroundColor: 'success.light',
                padding: 0.9,
                borderRadius: '0.3rem',
                width: 'fit-content',
                // color: '#358489',
                fontWeight: 500,
                fontSize: 12,
              }}
              variant="span"
            >
              Comprobante de honorarios
            </Typography>

            <Tooltip
              title={`Fecha del honorario: ${toDateFormat(sale.date)}`}
              placement="left"
            >
              <Stack
                direction="row"
                gap={0.5}
                alignItems="center"
                pl={1}
                mt={1}
              >
                <LSCalendar color="primary" />

                <Typography fontWeight="600" fontSize={14}>
                  {toDateFormat(sale.date, 'DD/MM/YYYY')}
                </Typography>
              </Stack>
            </Tooltip>

            {sale.entity && (
              <Tooltip title={sale.entity.displayName} placement="left">
                <Button
                  variant="text"
                  sx={{
                    gap: 1,
                    justifyContent: 'flex-start',
                    textTransform: 'none',
                  }}
                  onClick={() =>
                    dispatchEntityDialog(sale.entity.id, {
                      id: sale.entity.id,
                    })
                  }
                >
                  <Avatar
                    variant="squared"
                    {...stringAvatar(sale.entity.displayName, {
                      fontSize: 10,
                      width: 26,
                      height: 26,
                    })}
                  />

                  <Typography>Cliente: {sale.entity.displayName}</Typography>
                </Button>
              </Tooltip>
            )}

            {sale.filecase && (
              <Button
                variant="text"
                sx={{
                  gap: 1,
                  justifyContent: 'flex-start',
                  textTransform: 'none',
                }}
                onClick={() =>
                  dispatchFilecaseDialog(sale.filecase.id, {
                    id: sale.filecase.id,
                  })
                }
              >
                <Stack direction="row" gap={1}>
                  <LSFilecase color="primary" />

                  <Typography textAlign="left" fontWeight={500}>
                    {sale.filecase.title}
                  </Typography>
                </Stack>
              </Button>
            )}

            {sale?.hours > 0 && (
              <Stack direction="row" gap={1} alignItems="center" pl={1}>
                <LSClock color="primary" />

                <Typography fontWeight="500">
                  {sale.hours} Horas contabilizadas
                </Typography>
              </Stack>
            )}

            <SaleConcept sale={sale} />

            <Typography mt={4} textAlign="center">
              Powered by <a href="https://legal.surf">LegalSurf</a>
            </Typography>
          </Stack>
        </CardContent>

        <CardActions>
          <Button
            className="btn-download"
            sx={{
              marginLeft: 'auto',
            }}
            variant="filled"
            onClick={() => handlePrint()}
          >
            ⬇ Descargar Comprobante
          </Button>
        </CardActions>
      </Card>
    </Page>
  );
};

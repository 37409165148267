import React, { useMemo } from 'react';
import {
  Chip,
  Checkbox,
  Grid,
  FormControl,
  Avatar,
  Stack,
  Box,
  Tooltip,
} from '@mui/material';
import { useFormikContext } from 'formik';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {
  AutocompleteField,
  defaultAutocompleteRenderTags,
} from 'src/components/ui-components/Form';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const filterRepeatedValues = (values) => {
  const nonRepeated = values.reduce((acc, curr) => {
    const isRepeated = acc.has(curr.id ?? curr.value ?? curr.value);

    if (isRepeated) {
      return acc;
    }

    return acc.set(curr.id ?? curr.value ?? curr.value, curr);
  }, new Map());

  return [...nonRepeated.values()];
};

const parseNewValue = (newValues) => {
  const values = newValues.map((newValue) => {
    // If value is string, that means that creatable was triggered by Enter key
    if (typeof newValue === 'string') {
      // Return same shape as an autocomplete "create" option
      return {
        value: newValue,
        label: newValue,
      };
    }

    return newValue;
  });

  return filterRepeatedValues(values);
};

const useComputeValue = ({ fieldValue, multiple, options, valueKey }) => {
  return useMemo(() => {
    if (!multiple) {
      const shouldFindByValue =
        typeof fieldValue === 'string' &&
        options.every((option) => typeof option?.value === 'string');

      if (shouldFindByValue) {
        return options.find((option) => option?.value === fieldValue);
      }

      return fieldValue;
    }

    let val = [...fieldValue];
    const isStringArray = val.every((x) => typeof x === 'string');

    if (isStringArray) {
      val = val
        .map((x) => options.find((y) => y[valueKey] === x))
        .filter((x) => !!x);
    }

    return val;
  }, [fieldValue, multiple, options, valueKey]);
};

const defaultRenderOption = (option) => (
  <Tooltip title={option.label ?? option.name} placement="left">
    <Box
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'block',
        maxWidth: '100%',
      }}
    >
      {option.name ?? option.label}
    </Box>
  </Tooltip>
);

const SelectField = ({
  gridProps = { xs: 12 },
  name,
  disabled,
  label,
  loading,
  onChange,
  onBlur,
  multiple,
  withSelectAll,
  renderOption = defaultRenderOption,
  helperText,
  valueKey = 'value',
  fixedOptions = [],
  options,
  placeholder = '',
  ...rest
}) => {
  const form = useFormikContext();
  const { error, touched, value: fieldValue } = form.getFieldMeta(name);
  const value = useComputeValue({ multiple, fieldValue, options, valueKey });

  const handleChange = (event, newValue) => {
    let currentValue;
    const allOptions = newValue?.length === options?.length ? [] : options;

    if (Array.isArray(newValue)) {
      currentValue = newValue.some((val) => val.value === 'all')
        ? allOptions
        : parseNewValue(newValue);
    } else {
      currentValue = newValue;
    }

    if (fixedOptions.length && Array.isArray(newValue)) {
      currentValue = [
        ...options.filter((opt) =>
          fixedOptions.includes(opt.value ?? opt.id ?? opt.label),
        ),
        ...currentValue.filter(
          (opt) => !fixedOptions.includes(opt.value ?? opt.id ?? opt.label),
        ),
      ];
    }

    if (onChange && typeof onChange === 'function') {
      return onChange(event, currentValue);
    }

    return form.setFieldValue(name, currentValue);
  };

  const renderTags = (tagValue, getTagProps) => {
    if (withSelectAll && multiple && value?.length === options?.length) {
      return (
        <Chip
          {...getTagProps({})}
          label="Todos"
          size="small"
          variant="outlined"
          onDelete={(event) => handleChange(event, [])}
        />
      );
    }

    return defaultAutocompleteRenderTags(tagValue, getTagProps);
  };

  return (
    <Grid item {...gridProps}>
      <FormControl sx={{ width: '100%' }}>
        <AutocompleteField
          fullWidth
          clearOnBlur
          key={options.length}
          withSelectAll={withSelectAll}
          disabled={form.isSubmitting || loading || disabled}
          error={Boolean(error) && touched}
          helperText={(touched && error) || helperText}
          label={label}
          loading={loading}
          multiple={multiple}
          options={options}
          placeholder={placeholder}
          renderOption={(props, option, state) => {
            const isChecked =
              (withSelectAll &&
                multiple &&
                value?.length === options?.length) ||
              state.selected;

            return (
              <Stack direction="row" gap={1} alignItems="center" {...props}>
                {multiple && (
                  <Checkbox
                    // Choto solution, please refactor
                    checked={isChecked}
                    checkedIcon={checkedIcon}
                    icon={icon}
                  />
                )}

                {option?.picture ? (
                  <Avatar
                    sx={{ width: 24, height: 24 }}
                    alt={option.label}
                    src={option.picture}
                  />
                ) : null}

                {renderOption
                  ? renderOption(option, state)
                  : (option.name ?? option.label)}
              </Stack>
            );
          }}
          renderTags={renderTags}
          value={value}
          onBlur={onBlur}
          onChange={handleChange}
          {...rest}
        />
      </FormControl>
    </Grid>
  );
};

export default SelectField;

import { useMatch } from 'react-router-dom';

export const useStudioId = () => {
  const match = useMatch({
    path: '/dashboard/:studioId',
    caseSensitive: false,
    end: false,
  });

  const studioId = match?.params?.studioId?.startsWith('org_')
    ? match.params?.studioId
    : null;

  // TODO: check how to reimplement this
  // const { organization } = useOrganization();
  // const { setActive } = useOrganizationList();

  // useConditionalEffect(() => {
  //   if (studioId !== organization?.id) {
  //     setActive?.({ organization: studioId });
  //   }
  // }, [studioId, organization?.id]);

  return studioId;
};

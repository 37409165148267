import React, { useCallback, useState } from 'react';
import { Box, Button, InputAdornment, Stack } from '@mui/material';
import { ArrayParam, StringParam, BooleanParam } from 'use-query-params';

import SectionHeader, {
  SectionHeaderHeading,
  SectionHeaderActiveFilters,
} from 'src/components/v3/SectionHeader';
import { useDialog } from 'src/dialogs/Dialogs';
import SectionHeaderAdvanceFilters from 'src/components/v3/SectionHeader/SectionHeaderAdvanceFilters';
import { SwitchField, TextField } from 'src/components/ui-components/Form';
import { LSAdd as LSAddIcon, LSLen, LSTrash } from 'src/components/icons';
import {
  AssigneesAutocomplete,
  CategoriesAutocomplete,
  EntitiesAutocomplete,
  StatusAutocomplete,
  TagsAutocomplete,
} from 'src/autocompletes';
import { createHeaderFiltersStore } from 'src/components/v3/SectionHeader/utils';
import PermissionGuard from 'src/components/v2/PermissionGuard';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { useMutation } from '@apollo/client';
import { DELETE_FILECASE } from 'src/graphql/mutations/filecases';
import { GET_PAGINATED_FILECASES } from 'src/graphql/queries/filecases';
import MoreButton from 'src/components/v2/MoreButton';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { Download, Visibility } from '@mui/icons-material';
import { GiveFilecasesSeePermissionsModal } from './GiveFilecasesSeePermissionsModal';
import { useFilters } from 'src/components/v3/SectionHeader/useFilters';

export const FilecasesTableSearchFiltersId = 'FilecasesTableSearchFilters-v2';

const paramConfigMap = {
  tags: ArrayParam,
  filecases: ArrayParam,
  assigned: ArrayParam,
  entities: ArrayParam,
  search: StringParam,
  onlyFavorites: BooleanParam,
  archived: BooleanParam,
  category: StringParam,
  state: StringParam,
};

const filecasesTableHeaderdefaultInitialValues = {
  tags: [],
  filecases: [],
  assigned: [],
  entities: [],
  search: null,
  onlyFavorites: null,
  archived: false,
  category: null,
  state: null,
};

const useEntitiesFiltersStore = createHeaderFiltersStore(
  FilecasesTableSearchFiltersId,
  filecasesTableHeaderdefaultInitialValues,
);

export const useFilecasesFilters = () => {
  return useFilters(
    useEntitiesFiltersStore,
    paramConfigMap,
    filecasesTableHeaderdefaultInitialValues,
  );
};

const Header = ({
  title,
  subtitle,
  loading,
  totalItems,
  selectedItems,
  setSelectedItems,
  loadingFilecasesToExport,
  onDownloasdAsExcel: handleDownloadAsExcel,
}) => {
  const studioId = useStudioId();
  const dispatchFilecaseFormDialog = useDialog('filecaseForm');
  const { filters, setFilters, resetFilters, initialValues } =
    useFilecasesFilters();
  const [giveFilecasesSeePermissionsOpen, setGiveFilecasesSeePermissionsOpen] =
    useState(false);
  const dispatchWarningPromptDialog = useDialog('warningPrompt');

  const [deleteFilecase, { loading: isDeleting }] = useMutation(
    DELETE_FILECASE,
    {
      refetchQueries: [GET_PAGINATED_FILECASES],
      update: (cache, data, options) => {
        setSelectedItems([]);

        const normalizedId = cache.identify({
          id: options.variables.filecase,
          __typename: 'Filecase',
        });

        cache.evict({ id: normalizedId });
        cache.gc();
      },
    },
  );

  const handleFiltersChange = useCallback(
    (values, prev) =>
      Promise.resolve(setFilters(values, values.search !== prev.search)),
    [setFilters],
  );

  const handleReset = useCallback(
    (formikBag) => {
      resetFilters();
      formikBag.setValues(filecasesTableHeaderdefaultInitialValues);
    },
    [setFilters, resetFilters],
  );

  return (
    <>
      <SectionHeader
        filters={initialValues}
        loading={loading}
        onChange={handleFiltersChange}
        onSubmit={handleFiltersChange}
      >
        {() => (
          <>
            <Stack alignItems="center" direction="row" gap={2}>
              <Stack direction="column">
                <SectionHeaderHeading variant="h3">
                  {title}
                </SectionHeaderHeading>

                {subtitle && (
                  <SectionHeaderHeading variant="subtitle1">
                    {subtitle}
                  </SectionHeaderHeading>
                )}
              </Stack>

              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LSLen color="primary" />
                    </InputAdornment>
                  ),
                }}
                name="search"
                placeholder="Buscar..."
                size="small"
              />

              <SectionHeaderAdvanceFilters onReset={handleReset}>
                <SwitchField label="Solo favoritos" name="onlyFavorites" />

                <SwitchField label="Incluir archivados" name="archived" />

                <StatusAutocomplete label="Estado" name="state" size="small" />

                <CategoriesAutocomplete
                  label="Categoría"
                  name="category"
                  size="small"
                />

                <EntitiesAutocomplete
                  multiple
                  label="Directorios"
                  name="entities"
                  size="small"
                />

                <TagsAutocomplete
                  multiple
                  label="Etiquetas"
                  name="tags"
                  size="small"
                />

                <AssigneesAutocomplete
                  multiple
                  label="Asignados"
                  name="assigned"
                  placeholder="Ej: Nicolas Salazar"
                  size="small"
                />
              </SectionHeaderAdvanceFilters>

              <Box
                sx={{
                  ml: 'auto',
                  gap: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <PermissionGuard
                  permissions={[LS_PERMISSIONS_MAP.FILECASES.CREATE]}
                >
                  <Button
                    color="primary"
                    startIcon={<LSAddIcon color="white" />}
                    variant="contained"
                    onClick={() =>
                      dispatchFilecaseFormDialog('FilecasesTableForm')
                    }
                  >
                    Crear expediente
                  </Button>
                </PermissionGuard>

                <MoreButton popperHeader="Acciones">
                  <Stack gap={0.5} p={1}>
                    <PermissionGuard
                      permissions={[LS_PERMISSIONS_MAP.FILECASES.DELETE]}
                    >
                      <Button
                        disabled={!selectedItems.length || isDeleting}
                        size="large"
                        startIcon={<LSTrash />}
                        sx={{ justifyContent: 'flex-start' }}
                        variant="text"
                        onClick={() =>
                          dispatchWarningPromptDialog('FilecasesTableHeader', {
                            onAccept: () =>
                              deleteFilecase({
                                variables: {
                                  filecaseIds: selectedItems,
                                  studio: studioId,
                                },
                              }),
                            title: 'Eliminar expedientes',
                          })
                        }
                      >
                        Eliminar
                      </Button>
                    </PermissionGuard>

                    <PermissionGuard
                      permissions={[LS_PERMISSIONS_MAP.FILECASES.ACCESS]}
                    >
                      <Button
                        disabled={isDeleting || loadingFilecasesToExport}
                        size="large"
                        startIcon={<Download />}
                        variant="text"
                        onClick={handleDownloadAsExcel}
                      >
                        Descargar Excel ({selectedItems?.length || totalItems})
                      </Button>
                    </PermissionGuard>

                    <PermissionGuard
                      permissions={[LS_PERMISSIONS_MAP.FILECASES.UPDATE]}
                    >
                      <Button
                        size="large"
                        startIcon={<Visibility />}
                        sx={{ justifyContent: 'flex-start' }}
                        variant="text"
                        onClick={() => setGiveFilecasesSeePermissionsOpen(true)}
                      >
                        Dar permisos ({selectedItems?.length || totalItems})
                      </Button>
                    </PermissionGuard>
                  </Stack>
                </MoreButton>
              </Box>
            </Stack>

            <SectionHeaderActiveFilters
              filterLabels={{
                tags: 'Etiquetas',
                entities: 'Directorios',
                assigned: 'Asignados',
                search: 'Busqueda',
                state: 'Estado',
                category: 'Categoría',
                onlyFavorites: 'Favoritos',
              }}
              filters={filters}
            />
          </>
        )}
      </SectionHeader>

      <GiveFilecasesSeePermissionsModal
        filecases={
          selectedItems?.length === 0 && totalItems !== 0
            ? [{ label: `Todos (${totalItems})`, value: 'all' }]
            : selectedItems
        }
        open={giveFilecasesSeePermissionsOpen}
        onClose={() => setGiveFilecasesSeePermissionsOpen(false)}
        onSuccess={() => setGiveFilecasesSeePermissionsOpen(false)}
      />
    </>
  );
};

export default Header;

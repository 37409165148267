import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { timelineContentClasses } from '@mui/lab';
import { ErrorBoundary } from '@sentry/react';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import { HourglassEmpty } from '@mui/icons-material';
import { useDraggableSections } from '@legalsurf/hooks';

import { LS_PERMISSIONS_MAP } from '@legalsurf/common';

import {
  LSCalendar,
  LSDirectory,
  LSDocument,
  LSBooks,
  LSPin,
  LSSale,
  LSExpense,
} from 'src/components/icons';

import { GET_FILECASE_SINGLE_DATA } from '../../../graphql/queries/filecases';
import { SingleFilecaseHeader } from './SingleFilecaseHeader';
import { SingleFilecaseEntities } from './SingleFilecaseEntities';
import { SingleFilecaseEvents } from './SingleFilecaseEvents';
import FilecaseDetailsSkeleton from './FilecaseDetailsSkeleton';
import SingleFilecaseDocuments from './SingleFilecaseDocuments';
import DetailSection from './DetailSection';

import SingleFilecaseErrorBoundary from './SingleFilecaseErrorBoundary';
import HoursTrackingSection from './HoursTrackingSection';
import PermissionGuard from '../PermissionGuard';
import { SingleFilecaseSales } from './SingleFilecaseSales';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { SingleFilecaseEntries } from './SingleFilecaseEntries';

export const filecaseRawSections = [
  'entries',
  'entities',
  'documents',
  'alerts',
  'tasks',
  'sales',
  'expenses',
  'tracking',
];

export const useFilecaseSections = (filecase) => {
  const getTitle = useCallback(
    ({ type, title }) => {
      if (!filecase) {
        return title;
      }

      const casts = {
        entries: () => filecase.entries.length,
        entities: () => filecase.entities.length,
        documents: () => filecase.documents.length,
        tasks: () =>
          filecase.events.filter((event) => event.type === 'task').length,
        alerts: () =>
          filecase.events.filter((event) => event.type === 'alert').length,
        sales: () => filecase.sales?.length,
        expenses: () => filecase.expenses?.length,
        tracking: () => filecase.chargedHours.length,
      };

      const count = casts[type]();

      return count ? `${title} (${count})` : title;
    },
    [filecase],
  );

  const sectionData = useMemo(
    () => ({
      entries: {
        icon: <LSBooks color="primary" />,
        title: getTitle({ type: 'entries', title: 'Actuaciones' }),
      },
      entities: {
        icon: <LSDirectory color="primary" />,
        title: getTitle({ type: 'entities', title: 'Vinculaciones' }),
        permissions: [LS_PERMISSIONS_MAP.DIRECTORY.ACCESS],
      },
      documents: {
        icon: <LSDocument color="primary" />,
        title: getTitle({ type: 'documents', title: 'Documentos vinculados' }),
        permissions: [LS_PERMISSIONS_MAP.DOCUMENTS.ACCESS],
      },
      alerts: {
        icon: <LSCalendar color="primary" />,
        title: getTitle({ type: 'alerts', title: 'Alertas' }),
        permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
      },
      tasks: {
        icon: <LSPin color="primary" />,
        title: getTitle({ type: 'tasks', title: 'Tareas' }),
        permissions: [LS_PERMISSIONS_MAP.CALENDAR.ACCESS],
      },
      sales: {
        icon: <LSSale color="primary" />,
        title: getTitle({ type: 'sales', title: 'Honorarios' }),
        permissions: [LS_PERMISSIONS_MAP.SALES.ACCESS],
      },
      expenses: {
        icon: <LSExpense color="primary" />,
        title: getTitle({ type: 'expenses', title: 'Gastos' }),
        permissions: [LS_PERMISSIONS_MAP.EXPENSES.ACCESS],
      },
      tracking: {
        icon: <HourglassEmpty color="primary" />,
        title: getTitle({ type: 'tracking', title: 'Time Tracking' }),
        permissions: [LS_PERMISSIONS_MAP.TIME_TRACKING.ACCESS],
      },
    }),
    [getTitle],
  );

  return sectionData;
};

export const getFilecaseSections = (sectionId) => {
  switch (sectionId) {
    case 'entries':
      return SingleFilecaseEntries;
    case 'entities':
      return SingleFilecaseEntities;
    case 'documents':
      return SingleFilecaseDocuments;
    case 'alerts':
    case 'tasks':
      return SingleFilecaseEvents;
    case 'sales':
    case 'expenses':
      return SingleFilecaseSales;
    case 'tracking':
      return HoursTrackingSection;
    default:
      return null;
  }
};

const FilecaseDetail = ({ filecaseId, onClose: handleClose, isFullPage }) => {
  const studioId = useStudioId();

  const { data: { filecase } = {}, loading } = useQuery(
    GET_FILECASE_SINGLE_DATA,
    {
      // fetchPolicy: 'cache-and-network',
      // nextFetchPolicy: 'cache-and-network',
      variables: {
        filecase: filecaseId,
      },
      skip: !filecaseId,
    },
  );

  const sectionData = useFilecaseSections(filecase);
  const [sections, handleDragEnd] = useDraggableSections(
    filecaseRawSections,
    'v1-filecase-detail',
  );

  if (loading) return <FilecaseDetailsSkeleton />;

  return (
    <Box
      sx={{
        p: 4,
        // Overrides any inputs background, if this patter repeats, find a better way.
        overflow: 'hidden',
        fieldset: { backgroundColor: '#FFF !important' },

        [`& .${timelineContentClasses.root}`]: {
          maxWidth: (theme) => `calc(100% - ${theme.spacing(3)})`,
        },

        '& :before': {
          display: 'none',
        },
      }}
    >
      {!!filecaseId && (
        <ErrorBoundary fallback={SingleFilecaseErrorBoundary}>
          <Stack direction="column" gap={2}>
            <SingleFilecaseHeader
              filecase={filecase}
              singlePage={isFullPage}
              onClose={handleClose}
              onCreatePdf={() =>
                window.open(
                  `/dashboard/${studioId}/filecase/${filecase.id}/pdf`,
                  '_blank',
                )
              }
            />

            <Box>
              <DragDropContext onDragEnd={handleDragEnd}>
                {sections.map((sectionId, index) => {
                  const Section = getFilecaseSections(sectionId);
                  const { title, icon, permissions } = sectionData[sectionId];

                  const content = (
                    <Droppable
                      direction="vertical"
                      droppableId={sectionId}
                      key={sectionId}
                    >
                      {(dropProvided, snapshot) => (
                        <Box
                          {...dropProvided.droppableProps}
                          direction="column"
                          ref={dropProvided.innerRef}
                          sx={{
                            ...(snapshot.isDraggingOver && {
                              backgroundColor: 'primary.100',
                            }),
                          }}
                        >
                          <DetailSection
                            draggableId={sectionId}
                            icon={icon}
                            index={index}
                            title={title}
                          >
                            <Section filecase={filecase} type={sectionId} />
                          </DetailSection>

                          {dropProvided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  );

                  if (!permissions) {
                    return content;
                  }

                  return (
                    <PermissionGuard key={sectionId} permissions={permissions}>
                      {content}
                    </PermissionGuard>
                  );
                })}
              </DragDropContext>
            </Box>
          </Stack>
        </ErrorBoundary>
      )}
    </Box>
  );
};

export default FilecaseDetail;

import React, { useMemo, useRef, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import {
  Box,
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Button,
  Stack,
  Avatar,
  CircularProgress,
  Divider,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Page from 'src/components/v2/Page';
import { LSActivity, LSStats, LSClock } from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { useReactToPrint } from 'react-to-print';
import { TimeTrackingReportsFilters } from './TimeTrackingReportsFilters';
import { humanizeHoursTrackingTime } from 'src/components/v2/FilecaseDetail/HoursTrackingSection/utils';
import { PeopleAltOutlined } from '@mui/icons-material';
import { generateColorFromText } from 'src/utils/colors';
import dayjs from 'dayjs';

const AllTimeTrackingDataQuery = gql`
  query AllTimeTrackingDataQuery($filters: HoursTrackingReportsFilters) {
    hoursTrackingStats(filters: $filters) {
      totalHours
      totalUsers
      totalCategories
    }

    hoursTrackingByUser(filters: $filters) {
      id
      name
      picture
      hours
      percentage
    }

    hoursTrackingByCategory(filters: $filters) {
      id
      name
      hours
      percentage
    }

    hoursTrackingReport(filters: $filters) {
      year
      month
      day
      hours
    }

    hoursTrackingByUserMonthly(filters: $filters) {
      user {
        id
        name
        picture
      }
      monthlyData {
        year
        month
        hours
      }
    }
  }
`;

const ReportsCustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box sx={{ backgroundColor: 'black', p: 1, borderRadius: 1 }}>
        <Typography variant="body1" color="white">
          {payload[0].payload.name}:{' '}
          {humanizeHoursTrackingTime(payload[0].value)}
        </Typography>
      </Box>
    );
  }

  return null;
};

const CardTitle = ({ children, ...props }) => (
  <Typography fontSize={14} fontWeight={600} mb={2} {...props}>
    {children}
  </Typography>
);

// Time Tracking Stats Summary Component
export const TimeTrackingStatsSummary = ({ data }) => {
  if (!data) return <p>No data available for Time Tracking Stats.</p>;

  const { totalHours, totalUsers } = data.hoursTrackingStats;

  const maxHours = Math.max(
    ...data.hoursTrackingByUser.map((user) => user.hours),
  );

  return (
    <Card variant="outlined">
      <CardContent>
        <Stack gap={3}>
          <Stack gap={2}>
            <Stack gap={1}>
              <Stack direction="row" gap={1}>
                <LSActivity color="primary" sx={{ fontSize: 22 }} />

                <CardTitle mb={0} fontWeight={500}>
                  Estadísticas Generales
                </CardTitle>
              </Stack>

              <Divider />
            </Stack>

            <Box>
              <Stack direction="row" gap={1}>
                <LSClock color="primary" sx={{ fontSize: 20 }} />

                <Stack direction="row" gap={0.5}>
                  <Typography fontSize={14} fontWeight="bold">
                    {humanizeHoursTrackingTime(totalHours)}
                  </Typography>

                  <Typography fontSize={14} fontWeight={400}>
                    horas registradas
                  </Typography>
                </Stack>
              </Stack>

              <Stack direction="row" gap={1}>
                <PeopleAltOutlined color="primary" sx={{ fontSize: 20 }} />

                <Typography fontSize={14} fontWeight="bold">
                  {totalUsers}
                </Typography>

                <Typography fontSize={14} fontWeight={400}>
                  usuarios cargaron horas
                </Typography>
              </Stack>
            </Box>

            <Typography variant="h6" fontWeight={500}>
              Horas por usuario
            </Typography>

            <Stack gap={1.5}>
              {data.hoursTrackingByUser.map((user) => (
                <Box key={user.id}>
                  <Box display="flex" alignItems="center" mb={0.5}>
                    <Avatar
                      src={user.picture}
                      sx={{ width: 24, height: 24, mr: 1 }}
                    />

                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      maxWidth={140}
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      mr={2}
                    >
                      {user.name}
                    </Typography>

                    <Stack
                      direction="row"
                      gap={1}
                      alignItems="center"
                      ml="auto"
                    >
                      <Typography fontSize={14} fontWeight="bold">
                        {humanizeHoursTrackingTime(user.hours)}
                      </Typography>

                      <Stack gap={0.25}>
                        <Typography fontSize={14} color="text.secondary">
                          {user.percentage}%
                        </Typography>

                        <LinearProgress
                          variant="determinate"
                          value={(user.hours / maxHours) * 100}
                          sx={{ height: 4, borderRadius: 1 }}
                        />
                      </Stack>
                    </Stack>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

// Monthly Hours Chart Component
export const MonthlyHoursChart = ({ data, loading }) => {
  if (loading) return <CircularProgress />;

  const monthNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  const chartData = useMemo(() => {
    if (!data?.hoursTrackingReport) return [];

    return data.hoursTrackingReport.map((item) => ({
      month: monthNames[item.month - 1],
      hours: item.hours,
      date: `${item.year}-${item.month}`,
    }));
  }, [data]);

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Horas registradas por mes</CardTitle>

        <Box height={256}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={chartData}
              margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="month" axisLine={false} tickLine={false} />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) =>
                  humanizeHoursTrackingTime(value, false)
                }
              />

              <Line
                type="monotone"
                dataKey="hours"
                name="Horas"
                stroke="#6366f1"
                strokeWidth={2}
                dot={{ r: 0 }}
                activeDot={{ r: 6, fill: '#6366f1' }}
              />

              <Tooltip
                formatter={(value) => [
                  humanizeHoursTrackingTime(value),
                  'Horas',
                ]}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

// Hours by User Chart Component
export const HoursByUserChart = ({ data }) => {
  const monthNames = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  const colors = ['#6366f1', '#10b981', '#f59e0b', '#ef4444', '#8b5cf6'];

  // Process data for the chart
  const chartData = useMemo(() => {
    if (!data?.hoursTrackingByUserMonthly?.length) return [];

    // Get all unique year-month combinations
    const allMonths = new Set();
    data.hoursTrackingByUserMonthly.forEach((userData) => {
      userData.monthlyData.forEach((month) => {
        allMonths.add(`${month.year}-${month.month}`);
      });
    });

    // Sort months chronologically
    const sortedMonths = Array.from(allMonths).sort();

    // Create chart data with all users for each month
    return sortedMonths.map((monthKey) => {
      const [year, month] = monthKey.split('-').map(Number);
      const result = {
        month: monthNames[month - 1],
        fullMonth: monthKey,
      };

      // Add data for each user
      data.hoursTrackingByUserMonthly.forEach((userData) => {
        const monthData = userData.monthlyData.find(
          (m) => m.year === year && m.month === month,
        );

        result[userData.user.name] = monthData ? monthData.hours : 0;
      });

      return result;
    });
  }, [data]);

  // Get user names for the chart
  const userNames = useMemo(() => {
    if (!data?.hoursTrackingByUserMonthly) return [];
    return data.hoursTrackingByUserMonthly.map((item) => item.user.name);
  }, [data]);

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Horas por usuario por mes</CardTitle>

        <Box height={256}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={chartData}
              margin={{ top: 5, right: 20, bottom: 20, left: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="month" axisLine={false} tickLine={false} />
              <YAxis
                axisLine={false}
                tickLine={false}
                tickFormatter={(value) =>
                  humanizeHoursTrackingTime(value, false)
                }
              />

              {userNames.map((userName, index) => (
                <Bar
                  key={userName}
                  dataKey={userName}
                  stackId="a"
                  fill={colors[index % colors.length]}
                />
              ))}

              <Tooltip
                cursor={{ opacity: 0.4 }}
                formatter={(value, name) => [
                  humanizeHoursTrackingTime(value),
                  name,
                ]}
              />
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

// Hours by Category Component
export const HoursByCategoryChart = ({ data }) => {
  const chartData = useMemo(() => {
    if (!data?.hoursTrackingByCategory) return [];

    return data.hoursTrackingByCategory.map((item) => ({
      name: item.name || 'Sin categoría',
      value: item.hours,
      percentage: item.percentage,
    }));
  }, [data]);

  return (
    <Card variant="outlined">
      <CardContent>
        <CardTitle>Horas por categoría</CardTitle>

        <Box height={256} display="flex" justifyContent="center">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
                nameKey="name"
              >
                {chartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={generateColorFromText(entry.name)}
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value) => [
                  humanizeHoursTrackingTime(value),
                  'Horas',
                ]}
                content={<ReportsCustomTooltip />}
              />
            </PieChart>
          </ResponsiveContainer>
        </Box>

        <Stack gap={1} mt={2}>
          {chartData.map((item, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Box
                sx={{
                  width: 12,
                  height: 12,
                  borderRadius: '50%',
                  backgroundColor: generateColorFromText(item.name),
                  mr: 1,
                }}
              />
              <Typography fontSize={14}>{item.name}</Typography>
              <Typography fontSize={14} fontWeight="bold" ml="auto" mr={1}>
                {humanizeHoursTrackingTime(item.value)}
              </Typography>
              <Typography fontSize={14} color="text.secondary" width={40}>
                {item.percentage}%
              </Typography>
            </Box>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
};

const layoutGap = 2;

export const ReportsTimeTracking = () => {
  const [today] = useState(dayjs());
  const componentRef = useRef();
  const studioId = useStudioId();
  const [filters, setFilters] = useState({
    period: {
      option: '3',
      start: today.subtract(3, 'month').startOf('month').toDate(),
      end: today.endOf('date').toDate(),
    },
    filecases: [],
    responsibles: [],
    categories: [],
  });

  const queryFilters = useMemo(
    () =>
      filters
        ? {
            date: filters.period?.start || filters.period?.end
              ? {
                  start: filters.period.start,
                  end: filters.period.end,
                }
              : undefined,
            filecases: filters.filecases?.map((val) => val?.value ?? val),
            responsibles: filters.responsibles?.map((val) => val?.value ?? val),
            categories: filters.categories?.map((val) => val?.value ?? val),
          }
        : null,
    [filters],
  );

  const { loading, error, data } = useQuery(AllTimeTrackingDataQuery, {
    variables: {
      studioId: studioId,
      filters: queryFilters,
    },
  });

  const handleFiltersSubmit = (values) => Promise.resolve(setFilters(values));

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <Typography color="error">
          Error loading time tracking data: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Page title="Time Tracking Report | LegalSurf">
      <Stack maxWidth={1200} mx="auto" gap={2}>
        <Stack
          direction={['column', 'row']}
          justifyContent="space-between"
          gap={2}
          alignItems={['initial', 'center']}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <LSStats color="primary" sx={{ fontSize: 30 }} />

            <Typography fontWeight={500} fontSize={18}>
              Estadísticas - Time Tracking
            </Typography>
          </Stack>

          <Box display="flex" alignItems="center" gap={2}>
            <TimeTrackingReportsFilters onSubmit={handleFiltersSubmit} />

            <Button
              variant="outlined"
              size="small"
              startIcon={<CloudDownloadIcon />}
              onClick={() => handlePrint()}
            >
              Descargar
            </Button>
          </Box>
        </Stack>

        {loading ? (
          <CircularProgress />
        ) : (
          <Stack gap={4} ref={componentRef}>
            <Stack
              sx={{
                '@media print': {
                  padding: 4,
                  flexDirection: 'column',
                },
              }}
              direction={['column', 'row']}
              gap={layoutGap}
            >
              <TimeTrackingStatsSummary data={data} />

              <Stack flex={3} gap={layoutGap}>
                <MonthlyHoursChart data={data} loading={loading} />

                <Stack
                  direction={['column', 'row']}
                  gap={layoutGap}
                  sx={{
                    '@media print': {
                      flexDirection: 'column',
                    },
                  }}
                >
                  <Box flex={1}>
                    <HoursByUserChart data={data} />
                  </Box>

                  <Box flex={1}>
                    <HoursByCategoryChart data={data} />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Page>
  );
};

export default ReportsTimeTracking;

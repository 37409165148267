// Predefined color palette for common categories
const CATEGORY_COLOR_MAP = {
  // Client related
  'atención al cliente': '#ffc107',
  'client attention': '#ffc107',
  'cliente': '#ffc107',
  'client': '#ffc107',
  
  // Case management
  'gestión de casos': '#f06292',
  'case management': '#f06292',
  'litigios': '#f06292',
  'litigation': '#f06292',
  
  // Documentation
  'redacción': '#8d6e63',
  'documentación': '#8d6e63',
  'documentation': '#8d6e63',
  'drafting': '#8d6e63',
  
  // Meetings
  'reunión': '#4fc3f7',
  'meeting': '#4fc3f7',
  'consulta': '#4fc3f7',
  'consultation': '#4fc3f7',
  
  // Research
  'investigación': '#7cb342',
  'research': '#7cb342',
  'estudio': '#7cb342',
  'study': '#7cb342',
  
  // Court
  'audiencia': '#673ab7',
  'audience': '#673ab7',
  'corte': '#673ab7',
  'court': '#673ab7',
  
  // Administrative
  'administrativo': '#ff9800',
  'administrative': '#ff9800',
  'gestión': '#ff9800',
  'management': '#ff9800',
};

// Visually pleasing color palette for fallback colors
const FALLBACK_COLORS = [
  '#3f51b5', // Indigo
  '#009688', // Teal
  '#e91e63', // Pink
  '#4caf50', // Green
  '#ff5722', // Deep Orange
  '#2196f3', // Blue
  '#9c27b0', // Purple
  '#00bcd4', // Cyan
  '#ff9800', // Orange
  '#8bc34a', // Light Green
  '#795548', // Brown
  '#607d8b', // Blue Grey
];

/**
 * Generate a visually pleasing color for a category name
 * Uses a predefined map for common categories and a fallback palette for others
 * 
 * @param {string} str - The category name
 * @returns {string} - A hex color code
 */
export function generateColorFromText(str) {
  if (!str) return '#9e9e9e'; // Default gray for undefined/empty
  
  const lowerStr = str.toLowerCase();
  
  // Check if the category name contains any of our predefined keys
  for (const [key, color] of Object.entries(CATEGORY_COLOR_MAP)) {
    if (lowerStr.includes(key)) {
      return color;
    }
  }
  
  // If no match, use a consistent color from our fallback palette
  // This ensures the same category always gets the same color
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // Make hash positive and get an index from our fallback colors
  hash = Math.abs(hash);
  const colorIndex = hash % FALLBACK_COLORS.length;
  
  return FALLBACK_COLORS[colorIndex];
}

import React from 'react';
import { create } from 'zustand';
import { Box, Dialog } from '@mui/material';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import { usePostHog } from 'posthog-js/react';

import FilecaseDetail from 'src/components/v2/FilecaseDetail';
import { EntityDetail } from 'src/components/v2/EntityDetail';
import FilePreviewer from 'src/components/v2/FilePreviewer';
import { ExpenseDetail } from 'src/components/v2/ExpenseDetail';
import FilecaseForm from 'src/forms/FilecaseForm';
import { EventForm } from 'src/forms/CalendarEventForm';
import { CalendarEventDetailsDialogContent } from '../calendar/CalendarEventDetailsDialog/CalendarEventDetailsDialogContent';
import DocumentsForm from 'src/forms/DocumentsForm';
import { EntityForm } from 'src/forms/DirectoryForm';
import { SalesForm } from 'src/forms/SalesForm';
import { SaleDetail } from 'src/components/v2/SaleDetail';
import ChargeForm from 'src/forms/ChargeForm';
import { WarningDialogContent } from '../WarningDialog';
import ExpenseForm from 'src/forms/ExpenseForm';
import PaymentForm from 'src/forms/PaymentForm';
import { ContractPreviewDialog } from '../ContractPreviewDialog';
import { CustomFieldFormDialog } from '../CustomFieldFormDialog';
import { WorkflowFormDialog } from '../WorkflowFormDialog';
import { WorkflowTimelineItemEventDialogForm } from '../WorkflowFormDialog/WorkflowTimelineContent/components/WorkflowTimelineItemEvent/WorkflowTimelineItemEventDialogForm';
import { NotificationTemplateForm } from '../NotificationTemplateFormDialog/NotificationTemplateForm';
import HoursTrackingForm from 'src/forms/HoursTrackingForm';

const dialogsSideViewDefaultProps = {
  fullScreen: true,
  sx: { width: ['initial', 700], ml: 'auto' },
};

// Map dialog types to content components
const DialogContentComponents = {
  filecase: {
    component: FilecaseDetail,
    propMapping: { id: 'filecaseId' },
    defaultProps: dialogsSideViewDefaultProps,
  },
  filecaseForm: { component: FilecaseForm },
  eventForm: { component: EventForm },
  event: {
    component: CalendarEventDetailsDialogContent,
    propMapping: { id: 'eventId' },
    defaultProps: dialogsSideViewDefaultProps,
  },
  entity: {
    component: EntityDetail,
    propMapping: { id: 'entityId' },
    defaultProps: dialogsSideViewDefaultProps,
  },
  documentsForm: { component: DocumentsForm },
  entityForm: { component: EntityForm },
  documentsPreview: {
    component: FilePreviewer,
    defaultProps: { maxWidth: 'xl' },
  },
  salesForm: { component: SalesForm },
  sale: {
    component: SaleDetail,
    propMapping: { id: 'saleId' },
    defaultProps: dialogsSideViewDefaultProps,
  },
  chargeForm: { component: ChargeForm },
  warningPrompt: { component: WarningDialogContent },
  expense: {
    component: ExpenseDetail,
    propMapping: { id: 'expenseId' },
    defaultProps: dialogsSideViewDefaultProps,
  },
  expenseForm: { component: ExpenseForm },
  expensesPaymentForm: { component: PaymentForm },
  contractPreview: {
    component: ContractPreviewDialog,
    defaultProps: { fullWidth: true, maxWidth: 'lg' },
  },
  customFieldForm: { component: CustomFieldFormDialog },
  workflowsForm: { component: WorkflowFormDialog },
  workflowsEventForm: {
    component: WorkflowTimelineItemEventDialogForm,
  },
  notificationTemplateFormDialog: {
    component: NotificationTemplateForm,
  },
  timeTrackingForm: { component: HoursTrackingForm },
};

// Generic Dialog component that renders any content component
const GenericDialog = ({ dialogType, data, open, onClose }) => {
  const {
    component: ContentComponent,
    propMapping = {},
    defaultProps,
  } = DialogContentComponents[dialogType];

  // Map props according to the component's expected prop names
  const mappedProps = Object.entries(data || {}).reduce((acc, [key, value]) => {
    const mappedKey = propMapping[key] || key;
    return { ...acc, [mappedKey]: value };
  }, {});

  return (
    <Dialog open={open} onClose={onClose} {...defaultProps}>
      <Box sx={{ overflowY: 'scroll' }}>
        {open ? (
          <ContentComponent {...mappedProps} onClose={onClose} open={open} />
        ) : null}
      </Box>
    </Dialog>
  );
};

const useDialogStore = create((set) => ({
  dialogs: {},
  setDialog: (dialog, key, data, open) =>
    set((state) => {
      const newDialogs = {
        ...state.dialogs,
        [dialog]: {
          ...state.dialogs[dialog],
          [key]: { open, key, dialogType: dialog, data: data ?? null },
        },
      };

      if (open === false) {
        delete newDialogs[dialog]?.[key];

        return { dialogs: newDialogs };
      }

      return {
        dialogs: newDialogs,
      };
    }),
}));

// https://github.com/pmndrs/zustand/issues/934
export const zustandHmrFix = (name, useStore) => {
  if (import.meta.hot) {
    const state = import.meta.hot.data[name];

    if (state) {
      useStore.setState(import.meta.hot.data[name]);
    }

    useStore.subscribe((state) => {
      import.meta.hot.data[name] = state;
    });

    import.meta.hot.accept((newModule) => {
      if (newModule) {
        useStore.setState(import.meta.hot.data[name]);
      }
    });
  }
};

zustandHmrFix('dialogs store', useDialogStore);

export const useDialog = (dialogType) => {
  const studioId = useStudioId();
  const posthog = usePostHog();
  const setDialog = useDialogStore((state) => state.setDialog);

  return React.useCallback(
    (key, data) => {
      setDialog(dialogType, key, data ?? null, true);

      posthog.capture('DialogView', { key, dialog: dialogType, studioId });
    },
    [dialogType, setDialog, posthog, studioId],
  );
};

export const DialogsProvider = () => {
  const { dialogs, setDialog } = useDialogStore();

  const handleDialogClose = (dialog, key) =>
    setDialog(dialog, key, dialogs[dialog][key]?.data, false);

  const components = Object.values(dialogs)
    .map((dialogList) => Object.values(dialogList))
    .flat()
    .map((dialogData) => {
      return (
        <GenericDialog
          key={dialogData.key}
          dialogType={dialogData.dialogType}
          data={dialogData.data}
          open={dialogData.open}
          onClose={() =>
            handleDialogClose(dialogData.dialogType, dialogData.key)
          }
        />
      );
    });

  return components;
};

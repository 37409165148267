import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useUser as useClerkUser } from '@clerk/clerk-react';

import { GET_CURRENT_MEMBER_WITH_PERMISSIONS } from 'src/graphql/queries/studios';
import { useStudioId } from './useStudioId';

const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const useUser = () => {
  const studioId = useStudioId();
  const { isLoaded, user: clerkUser, isSignedIn } = useClerkUser();

  const {
    loading,
    data: { getUserInfo: { member, ...user } = {} } = {},
    ...rest
  } = useQuery(GET_CURRENT_MEMBER_WITH_PERMISSIONS, {
    variables: {
      studioId,
    },
    skip: !isSignedIn,
  });

  return useMemo(
    () => ({
      ...rest,
      loading: loading || !isLoaded,
      isSignedIn,
      // TODO: La shape retornada *deberia* matchear la de useQuery. De manera que es la misma structura de datos
      // Alrededor de toda la app.
      // Side-note: Tambien hace mas facil la migracion a otra herramienta, ya que es la misma api.
      user: {
        timezone: defaultTimezone || user?.timezone,
        role: 'member',
        permissions: [],
        ...member,
        ...user,
      },
    }),
    [clerkUser?.id, isLoaded, isSignedIn, loading, member, rest, user],
  );
};

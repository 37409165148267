import React, { useCallback, useMemo } from 'react';
import {
  Box,
  DialogContent,
  Tooltip,
  Typography,
  Button,
  Stack,
  Divider,
  IconButton,
  Avatar,
  Alert,
  AlertTitle,
} from '@mui/material';

import {
  LSX as LSXIcon,
  LSPin as LSPinIcon,
  LSDangerSign as LSDangerSignIcon,
  LSTodo,
  LSMessages,
  LSDirectory,
} from 'src/components/icons';
import { useStudioId } from 'src/utils/hooks/useStudioId';
import {
  useCalendarSingleEvent,
  useDraggableSections,
  useUpdateCalendarEventAssignees,
} from '@legalsurf/hooks';
import { LS_PERMISSIONS_MAP } from '@legalsurf/common';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';

import {
  CalendarEventCardDateDescriptor,
  getEventTimeDescriptor,
} from 'src/components/v3/CalendarEventCard';
import GoogleCalendarIMG from 'src/assets/images/googlecalendar.png';

import TaskEventStatusChip from 'src/components/v3/TaskEventStatusChip';
import { usePermissions } from 'src/utils/hooks/usePermissions';
import { LinearProgressWithLabel } from 'src/components/v3/LinearProgressWithLabel';
import DetailSection from 'src/components/v2/FilecaseDetail/DetailSection';

import CalendarEventDetailsDialogSkeleton from './Skeleton';
import { CalendarEventDetailsActions } from './CalendarEventDetailsActions';
import CommentSection from './CommentSection';
import CheckListSection from './CheckListSection';
import BindingsSection from './BindingsSection';
import GoogleCalendarSection from './GoogleCalendarSection';
import { AssigneesPopover } from 'src/components/v3/AssigneesPopover';
import { Linkify } from 'src/components/Linkify';
import { ErrorBoundary } from '@sentry/react';

const rawSections = ['todos', 'bindings', 'comments'];

const SingleEventErrorBoundary = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <Alert color="error">
      <AlertTitle>Ha ocurrido un error fatal.</AlertTitle>
      Algo ha occurrido mientras cargabamos tu evento, estamos solucionandolo
      ahora mismo. Intente mas tarde.
    </Alert>
  </Box>
);

const useSections = ({ event }) => {
  const getTitle = useCallback(
    ({ type, title }) => {
      if (!event) {
        return title;
      }

      const casts = {
        todos: () => event?.todos?.length,
        comments: () => event?.comments?.length,
        bindings: () => {
          let count = 0;

          if (event?.filecase) {
            count += 1;
          }

          if (event?.entities?.length) {
            count += event.entities.length;
          }

          return count;
        },
      };

      const count = casts[type]();

      return count ? `${title} (${count})` : title;
    },
    [event],
  );

  const sectionData = useMemo(
    () => ({
      todos: {
        icon: <LSTodo color="primary" />,
        title: getTitle({ type: 'todos', title: 'Checklist' }),
      },
      comments: {
        icon: <LSMessages color="primary" />,
        title: getTitle({ type: 'comments', title: 'Comentarios' }),
      },
      bindings: {
        icon: <LSDirectory color="primary" />,
        title: getTitle({ type: 'bindings', title: 'Vinculaciones' }),
      },
    }),
    [getTitle],
  );

  return sectionData;
};

const getSections = (sectionId) => {
  switch (sectionId) {
    case 'todos':
      return CheckListSection;
    case 'comments':
      return CommentSection;
    case 'bindings':
      return BindingsSection;

    default:
      return null;
  }
};

export const CalendarEventDetailsDialogContent = ({
  eventId,
  calendarId,
  onClose,
}) => {
  const studioId = useStudioId();

  const { hasPermissions } = usePermissions([
    LS_PERMISSIONS_MAP.CALENDAR.UPDATE,
  ]);

  const { data: event, loading } = useCalendarSingleEvent({
    calendarId,
    eventId,
    studioId,
  });

  const sectionData = useSections({ eventId, calendarId, event });
  const [sections, handleDragEnd] = useDraggableSections(
    rawSections,
    'v1-event-detail',
  );

  const progressValue = useMemo(
    () =>
      event?.todos
        ? (event.todos.filter((todo) => todo.done).length /
            event.todos.length) *
          100
        : 0,
    [event?.todos],
  );

  const [updateCalendarEventAssignees] = useUpdateCalendarEventAssignees();

  const handleAssignedSubmit = async (newAssignees) => {
    await updateCalendarEventAssignees({
      variables: {
        id: event.id,
        calendarId: event.calendarId,
        assigned: newAssignees,
      },
    });
  };

  if (loading || !event) {
    return (
      <DialogContent sx={{ px: 4, pt: 3, width: 600 }}>
        <CalendarEventDetailsDialogSkeleton />
      </DialogContent>
    );
  }

  const timeDescriptor = getEventTimeDescriptor({
    allDay: event?.allDay,
    start: event?.start,
    end: event?.end,
  });

  return (
    <ErrorBoundary fallback={SingleEventErrorBoundary}>
      <DialogContent
        sx={{
          px: 4,
          pt: 3,
          // Overrides any inputs background, if this patter repeats, find a better way.
          overflow: 'hidden',
          fieldset: { backgroundColor: '#FFF !important' },
          minHeight: '100%',

          '& :before': {
            display: 'none',
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack alignItems="center" direction="row" gap={1}>
            <Box
              sx={{
                borderRadius: '50%',
                textAlign: 'center',
                width: 35,
                height: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',

                ...(event.type === 'alert' && {
                  backgroundColor: 'warning.200',
                }),

                ...(event.type === 'task' && {
                  backgroundColor: 'success.200',
                }),
              }}
            >
              {event.type === 'alert' ? (
                <Box>
                  <LSDangerSignIcon color="warning" sx={{ fontSize: 17 }} />
                </Box>
              ) : (
                <LSPinIcon color="success" sx={{ fontSize: 17 }} />
              )}
            </Box>

            <Typography variant="h5">
              {event.type === 'task' ? 'TAREA' : 'EVENTO'}
            </Typography>
          </Stack>

          <IconButton onClick={onClose}>
            <LSXIcon />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 2 }} />

        <Stack gap={2}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            gap={2}
          >
            <Typography
              sx={(theme) => ({
                color: theme.palette.computeContrast(event.calendar.color),
                backgroundColor: event.calendar.color,
                display: 'inline-block',
                borderRadius: 0.4,
                py: 1,
                px: 2,
                fontSize: 16,
              })}
            >
              {event.calendar.name}
            </Typography>

            <CalendarEventDetailsActions
              calendarId={calendarId}
              disabled={loading}
              event={event}
              eventId={eventId}
              filecase={event?.filecase}
              onClose={onClose}
            />
          </Stack>

          <Stack gap={1}>
            <Stack gap={1}>
              <Typography variant="h3">{event.title}</Typography>

              {event.description && <Linkify text={event.description} />}
            </Stack>

            <Stack direction="row" alignItems="center" gap={1}>
              {event.createdBy && (
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography
                    sx={{ opacity: 0.8 }}
                    fontWeight="500"
                    fontSize={12}
                  >
                    Creado por:
                  </Typography>

                  <Avatar
                    alt={event.createdBy.name}
                    src={event.createdBy.picture}
                    sx={{ width: 25, height: 25, fontSize: 12 }}
                  />
                </Stack>
              )}

              <Stack
                direction="row"
                alignItems="center"
                gap={event.assigned?.length ? 0 : 0.5}
                justifyContent="flex-start"
              >
                <Typography
                  sx={{ opacity: 0.8 }}
                  fontWeight="500"
                  whiteSpace="nowrap"
                  fontSize={12}
                >
                  Asignado a:
                </Typography>

                <AssigneesPopover
                  selectedAssignees={event.assigned}
                  touchable={hasPermissions}
                  onSubmit={handleAssignedSubmit}
                  sx={{
                    justifyContent: 'flex-start',
                    '.MuiAvatar-root': {
                      width: 25,
                      height: 25,
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack
            sx={{ backgroundColor: 'primary.100', borderRadius: 1, pr: 3 }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <CalendarEventCardDateDescriptor
                isAllDay={event.allDay}
                timeDescriptor={timeDescriptor}
              />

              {event.type === 'task' && (
                <TaskEventStatusChip
                  calendarId={event.calendarId}
                  eventId={event.id}
                  state={event.state}
                  touchable={hasPermissions}
                />
              )}
            </Stack>

            {event.todos.length > 0 && (
              <Box sx={{ px: 2, mb: 2 }}>
                <LinearProgressWithLabel
                  color={progressValue === 100 ? 'success' : 'primary'}
                  linearProgressSx={{
                    borderRadius: 3,
                    height: 10,
                    '& .MuiLinearProgress-bar': {
                      borderRadius: 3,
                    },
                  }}
                  value={progressValue}
                />
              </Box>
            )}
          </Stack>

          {event.googleCalendarData && (
            <Box>
              <Tooltip arrow title="Ir a Google Calendar">
                <Button
                  color="inherit"
                  // TODO: Fix this, htmlLink is not working
                  // href={event.googleCalendarData.htmlLink}
                  href="https://calendar.google.com/calendar"
                  rel="noreferrer"
                  startIcon={
                    <Box
                      component="img"
                      src={GoogleCalendarIMG}
                      sx={{ height: 24 }}
                    />
                  }
                  target="_blank"
                  variant="text"
                >
                  Google calendar
                </Button>
              </Tooltip>

              <GoogleCalendarSection event={event} />
            </Box>
          )}
        </Stack>

        <Box sx={{ mt: 2 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            {sections.map((sectionId, index) => {
              const Section = getSections(sectionId);
              const { title, icon } = sectionData[sectionId];

              return (
                <Droppable
                  direction="vertical"
                  droppableId="event-detail"
                  key={sectionId}
                >
                  {(dropProvided, snapshot) => (
                    <Stack
                      {...dropProvided.droppableProps}
                      direction="column"
                      ref={dropProvided.innerRef}
                      sx={{
                        ...(snapshot.isDraggingOver && {
                          backgroundColor: 'primary.100',
                        }),
                      }}
                    >
                      <DetailSection
                        draggableId={sectionId}
                        icon={icon}
                        index={index}
                        key={sectionId}
                        title={title}
                      >
                        <Section event={event} />
                      </DetailSection>
                      {dropProvided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              );
            })}
          </DragDropContext>
        </Box>
      </DialogContent>
    </ErrorBoundary>
  );
};

import React from 'react';
import { Formik } from 'formik';
import {
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';

import Form, {
  CustomFields,
  TextField,
} from 'src/components/ui-components/Form';
import { LoadingButton } from '@mui/lab';
import {
  AssigneesAutocomplete,
  StatusAutocomplete,
  TagsAutocomplete,
  SidesAutocomplete,
  CategoriesAutocomplete,
  StudioMembersAutocomplete,
} from 'src/autocompletes';

import FilecaseFormSchema from './schema';
import { useDefaultCanBeSeenBy } from 'src/components/v2/FilecaseDetail/SingleFilecaseHeader';
import { useFilecaseForm } from './useFilecaseForm';

const FilecaseForm = ({
  onClose,
  filecase = {},
  entities,
  defaultAssigned,
}) => {
  const { fixedCanBeSeenByIds } = useDefaultCanBeSeenBy();

  const { initialValues, handleSubmit } = useFilecaseForm({
    filecase,
    entities,
    onSubmit: onClose,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={FilecaseFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <>
          <DialogTitle>Expediente</DialogTitle>

          <DialogContent dividers>
            <Form>
              <TextField
                helperText="El trámite asociado tu proceso legal en este expediente"
                label="Número de expediente"
                name="externalId"
                placeholder="Ej. 17862/2021"
              />

              <TextField label="Titulo" name="title" placeholder="Titulo" />

              <TextField
                multiline
                label="Descripción"
                maxRows={4}
                name="description"
                placeholder="Descripción"
              />

              <StudioMembersAutocomplete
                fixedOptions={fixedCanBeSeenByIds}
                name="canBeSeenBy"
              />

              <StatusAutocomplete
                gridProps={{ xs: 12, md: 4 }}
                name="state"
                placeholder="Ej. Cerrado"
              />

              <CategoriesAutocomplete
                creatable
                gridProps={{ xs: 12, md: 4 }}
                name="category"
              />

              <SidesAutocomplete
                creatable
                gridProps={{ xs: 12, md: 4 }}
                name="side"
              />

              <TagsAutocomplete
                creatable
                multiple
                name="tags"
                placeholder="Ej. Zona sur"
              />

              {!!defaultAssigned && (
                <AssigneesAutocomplete
                  multiple
                  name="assigned"
                  placeholder="Ej. Nicolas"
                />
              )}

              {filecase?.id && (
                <CustomFields modelId={filecase.id} modelType="Filecase" />
              )}
            </Form>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!!isSubmitting}
              sx={{ mr: 'auto' }}
              onClick={onClose}
            >
              Cancelar
            </Button>

            <LoadingButton
              disabled={isSubmitting}
              loading={isSubmitting}
              sx={{ px: 4 }}
              type="button"
              variant="contained"
              onClick={handleSubmit}
            >
              {filecase?.id ? 'Editar expediente' : 'Crear expediente'}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Formik>
  );
};

export default FilecaseForm;
